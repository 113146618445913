/* global document */
import React, { Suspense, useEffect, lazy } from 'react';
import ReactDOM from 'react-dom';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import 'video-react/dist/video-react.css';

import Routes from './routes';
import { history } from './store';

import ReactPixel from 'react-facebook-pixel';
import './style/main.less';

const App = lazy(() => import('./App'));

// TODO: remove from here
window.fbAsyncInit = () => {
  FB.init({
    //eslint-disable-line
    appId: '292762307731826',
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v2.11',
  });
};

(function (d, s, id) {
  //eslint-disable-line
  let js;
  let fjs = d.getElementsByTagName(s)[0]; //eslint-disable-line
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id; //eslint-disable-line
  js.src = 'https://connect.facebook.net/en_US/sdk.js';
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));

const main = document.getElementById('user-area');

if (main) {

  const Main = () => {
    useEffect(() => {
      ReactPixel.init('1954678831462302');
      ReactPixel.pageView();
    }, []);

    return (
      <Suspense fallback={null}>
        <App>
          <Routes history={history} />
        </App>
      </Suspense>
    );
  };

  ReactDOM.render(<Main />, main);
}
