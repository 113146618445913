import axios from 'axios';
import _padStart from 'lodash/padStart';

export const getUserTasks = triiderUser => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/users/${triiderUser.user.id}/tasks?offset=0&limit=100`;

  axios
    .get(url, options)
    .then((response) => {
      if (response.data.success) resolve(response.data.tasks);
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

export const getTaskQuotes = (triiderUser, taskId) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/task/${taskId}/quote`;

  axios
    .get(url, options)
    .then((response) => {
      if (response.data.success) resolve(response.data.quotes);
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const getTaskById = (triiderUser, taskId) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/task/${taskId}`;

  axios
    .get(url, options)
    .then((response) => {
      if (response.data.success) resolve(response.data.task);
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const getUserCreditCards = triiderUser => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/users/${triiderUser.user.id}/cards`;

  axios
    .get(url, options)
    .then((response) => {
      if (response.data.success) {
        const cl = response.data.cards.map((c) => {
            c.validThru = `${_padStart(c.month, 2, '0')}/${c.year}`; // eslint-disable-line
          return c;
        });
        resolve(cl);
      }
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const getQuoteById = (triiderUser, quoteId) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/quote/${quoteId}`;

  axios
    .get(url, options)
    .then((response) => {
      if (response.data.success) resolve(response.data.quote);
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const getCouponById = (triiderUser, couponId) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/coupons/${couponId}`;

  axios
    .get(url, options)
    .then((response) => {
      if (response.data.success) resolve(response.data.coupon);
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

export const setQuoteRead = (triiderUser, quotes) => {
  const idList = quotes
    .filter(quote => quote.is_new)
    .map(quote => quote.id)
    .join();

  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/quote/${idList}/set-old`;
  return idList ? axios.patch(url, {}, options) : null;
};

export const getBussinesProfile = (triiderUser, proId) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/business/profile/${proId}`;

  axios
    .get(url, options)
    .then((response) => {
      if (response.data.success) resolve(response.data.profile);
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const redeemCoupon = (triiderUser, code) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/coupons/redeem`;

  axios
    .post(url, { code }, options)
    .then((response) => {
      if (response.data.success) resolve(response.data.coupon);
      reject(response.data.error);
    })
    .catch(error => reject(error.response.data.error));
});

// REFACTORING: DONE
export const setTaskCoupon = (triiderUser, idTask, idCoupon) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/task/${idTask}`;

  axios
    .patch(url, { coupon_id: idCoupon }, options)
    .then((response) => {
      if (response.data.success) resolve();
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

export const getTaskUserByUpdate = (triiderUser, user_id) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/task/${user_id}/getTaskUserByUpdate`;

  axios
    .get(url, options)
    .then((response) => {
      if (response.data.success) resolve(response.data.task);
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

export const listTasksByUser = (triiderUser, user_id) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/task/${user_id}/list-tasks`;

  axios
    .get(url, options)
    .then((response) => {
      if (response.data.success) resolve(response.data.tasks);
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const getTaskInstallments = (triiderUser, amount) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/util/installments`;

  axios
    .post(url, { amount }, options)
    .then((response) => {
      if (response.data.success) resolve(response.data.installments);
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const setTaskCheckoutInstallments = (triiderUser, taskId, installments) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/task/${taskId}/set-installment`;

  axios
    .patch(url, { installments }, options)
    .then((response) => {
      if (response.data.success) resolve();
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const setTaskCheckoutPayment = (triiderUser, taskId, card) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/task/${taskId}/set-payment`;

  axios
    .put(url, { payment: { card_id: card.id, gateway: card.gateway } }, options)
    .then((response) => {
      if (response.data.success) resolve();
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

export const setUserPhone = triiderUser => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/user/${triiderUser.user.id}`;

  const phone = triiderUser.user.phone.replace(/\s|-|\(|\)/g, '');

  axios
    .put(url, { user: { phone } }, options)
    .then((response) => {
      if (response.data.success) resolve();
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

export const setUserEmail = (triiderUser, newEmail) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/user/${triiderUser.user.id}`;

  axios
    .put(url, { user: { email: newEmail } }, options)
    .then((response) => {
      if (response.data.success) resolve();
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const authorizeTaskQuote = (triiderUser, quote) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/tasks/${quote.task_id}/hirings`;

  axios
    .post(url, { quote_id: quote.id }, options)
    .then((response) => {
      if (response.data.success) resolve();
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const quoteSetSelected = (triiderUser, quote) => new Promise((resolve, reject) => {
  const options = { headers: { 'api-token': triiderUser.token } };
  const url = `${process.env.API_URL}/quote/${quote.id}/set-selected`;

  axios
    .patch(url, {}, options)
    .then((response) => {
      if (response.data.success) resolve();
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const notifyTaskClose = (triiderUser, quote) => {
  const body = {
    user_id: triiderUser.user.id,
    quote_id: quote.id,
    chat_id: quote.chat_id,
    send_message: false,
    deal_message: 'Negócio fechado, podemos combinar o atendimento do serviço aqui pelo chat.',
    nodeal_message: 'Negócio fechado com outro prestador.',
  };

  return new Promise((resolve, reject) => {
    const options = { headers: { 'api-token': triiderUser.token } };
    const url = `${process.env.API_URL}/task/${quote.task_id}/close/notify`;

    axios
      .patch(url, body, options)
      .then((response) => {
        if (response.data.success) resolve();
        reject(response.data.error);
      })
      .catch(error => reject(error));
  });
};

export const sendMessage = (text, chatId, triiderUser) => new Promise((resolve, reject) => {
  const url = `${process.env.API_URL}/chat/${chatId}/message`;
  const options = { headers: { 'api-token': triiderUser.token } };
  const message = {
    text,
    user_id: triiderUser.user.id,
    chat_id: chatId,
  };

  axios.post(url, { message }, options).then((response) => {
    if (response.data.success) resolve(response.data.messages);
    reject(response.data.error);
  });
});

export const getArchiveReasons = (triiderUser, task) => {
  const condition = task.selected_quote_id && task.selected_quote_user_id
    ? 'cancel_task_after_business'
    : 'cancel_task_before_business';
  const url = `${process.env.API_URL}/feedback?enabled=true&type=${condition}`;
  const options = { headers: { 'api-token': triiderUser.token } };

  return new Promise((resolve, reject) => {
    axios
      .get(url, options)
      .then((response) => {
        resolve(response.data.list);
      })
      .catch(error => reject(error));
  });
};

// REFACTORING: DONE
const sendReason = (triiderUser, task, feedback) => {
  const url = `${process.env.API_URL}/task/${task.id}/problem`;
  const options = { headers: { 'api-token': triiderUser.token } };
  const taskProblem = {
    task_id: task.id,
    user_id: task.user_id,
    feedback_id: feedback.reasonId,
    comments: feedback.comment,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(url, { taskProblem }, options)
      .then((response) => {
        if (response.data.success) resolve();
      })
      .catch(error => reject(error));
  });
};

export const archiveTask = (triiderUser, task, feedback) => {
  const url = `${process.env.API_URL}/tasks/${task.id}/archive`;
  const options = { headers: { 'api-token': triiderUser.token } };

  const taskProblem = {
    feedback_id: feedback.reasonId,
    comments: feedback.comment,
    task_id: task.id,
    user_id: task.user_id,
  };

  return new Promise((resolve, reject) => {
    axios
      .put(url, { task_problem: taskProblem }, options)
      .then((res) => {
        if (res.data.success) {
          resolve();
        }
      })
      .catch(error => reject(error));
  });
};

// REFACTORING: DONE
export const getPaymentProblemReasons = (triiderUser) => {
  const url = `${process.env.API_URL}/feedback?enabled=true&type=client_payment_problem`;
  const options = { headers: { 'api-token': triiderUser.token } };

  return new Promise((resolve, reject) => {
    axios
      .get(url, options)
      .then((response) => {
        resolve(response.data.list);
      })
      .catch(error => reject(error));
  });
};

export const reportAProblem = (triiderUser, task, feedback) => sendReason(triiderUser, task, feedback);

export const getPeriodOptions = () => {
  const url = `${process.env.API_URL}/period`;

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data.success) resolve(response.data.periods);
        reject(response.data.error);
      })
      .catch(() => reject('Problemas as opções de tempo'));
  });
};

export const getUser = (triiderUser) => {
  const url = `${process.env.API_URL}/user/${triiderUser.user.id}`;
  const options = { headers: { 'api-token': triiderUser.token } };

  return new Promise((resolve, reject) => {
    axios
      .get(url, options)
      .then((response) => {
        if (response.data.success) resolve(response.data);
        reject(response.data.error);
      })
      .catch(() => reject('Problemas ao buscar mensagens'));
  });
};
