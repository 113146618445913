import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isAuth } from 'reducers/userArea';
import { Creators as UiCreators } from 'store/ducks/ui';

const ProtectedRoute = ({
  component: Component, _isAuth, pathname: redirect, ...rest
}) => {
  const pathname = redirect || '/entrar';

  return (
    <Route
      {...rest}
      render={props => (_isAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect
          push
          to={{
            pathname,
            state: { from: props.location },
          }}
        />
      ))
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  pathname: PropTypes.string,
  _isAuth: PropTypes.func,
  _routeRedirect: PropTypes.func,
  location: PropTypes.instanceOf(Object),
};

export default connect(
  state => ({
    location: state.router.location,
    pathname: state.ui.routeRedirect,
    _isAuth: () => isAuth(state),
  }),
  { _routeRedirect: UiCreators.routeRedirect },
)(ProtectedRoute);
