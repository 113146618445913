import React, { Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { subYears, getYear } from 'date-fns';

import DatePicker from 'components/DatePicker';
import Button from 'components/Button';

import iconEye from 'static/img/icons/eye.svg';
import iconEyeBlock from 'static/img/icons/eye-block.svg';
import iconDanger from 'static/img/icons/danger.svg';

import {
  Input, Label, InputStatus, InputWrapper, IconRight,
} from './styles';

class InputText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      hover: false,
      inputType: 'text',
      isPassword: false,

      dateStart: subYears(new Date(), 18),
      minYear: getYear(subYears(new Date(), 18)),
      maxYear: getYear(subYears(new Date(), 120)),
    };

    this.input = createRef();
    this.setHover = this.setHover.bind(this);
    this.setFocus = this.setFocus.bind(this);
    this.setBlur = this.setBlur.bind(this);
    this.setIconPassword = this.setIconPassword.bind(this);
  }

  componentDidMount() {
    const { type } = this.props;
    this.setState({ inputType: type, isPassword: type === 'password' });
  }

  setHover() {
    const { hover } = this.state;
    this.setState({ hover: !hover });
  }

  setFocus(e) {
    const { onFocus } = this.props;
    this.setState({ focus: true });
    if (onFocus) onFocus(e);
    this.ajustScroll(e);
  }

  setBlur(e) {
    const { onBlur } = this.props;
    this.setState({ focus: false });
    if (onBlur) onBlur(e);
  }

  setIconPassword() {
    const { inputType } = this.state;
    const newType = inputType === 'text' ? 'password' : 'text';
    this.setState({ inputType: newType });
  }

  ajustScroll = (event) => {
    const t = event.target;
    const { scrollView } = this.props;

    if (scrollView) {
      setTimeout(() => {
        t.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }, 300);
    }
  };

  render() {
    const {
      value,
      placeholder,
      disabled,
      type,
      error,
      mask = false,
      scrollView = true,
      iconLeft = null,
      iconRight = null,
      onClick,
      onBlur,
      onChange,
      isDate,
      isFormField,
      phoneValidation,
      isPasswordRegister,
      isPhone,
      isCpf,
      marginTop,
      placeholderAlign,
      ...props
    } = this.props;

    const {
      focus, hover, inputType, isPassword, dateStart, minYear, maxYear,
    } = this.state;

    const hasValue = !!value;
    const hasError = !!error && !focus;

    const phone = isPhone
      ? ['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
      : mask;

    const cpf = isCpf
      ? [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]
      : mask;

    const imgLeft = (hasError && iconDanger) || iconLeft;
    const imgRight = (isPassword && (inputType === 'text' ? iconEye : iconEyeBlock)) || iconRight;

    const message = (!focus && error)
      || (isPasswordRegister && hasValue
        && (value.length < 6 ? (
          <Fragment>
            Faltam
            {' '}
            <b>{`${6 - value.length} caractere${6 - value.length > 1 ? 's' : ''}`}</b>
          </Fragment>
        ) : (
          <Fragment>
            Senha
            {' '}
            <b>válida</b>
          </Fragment>
        )));

    return (
      <InputWrapper marginTop={marginTop} isFormField={isFormField} placeholderAlign={placeholderAlign}>
        {!!placeholder && (
          <Label focus={focus} hasError={hasError} disabled={disabled}>
            {placeholder}
          </Label>
        )}
        <Input
          disabled={disabled}
          hasError={hasError}
          hasValue={hasValue}
          focus={focus}
          hover={hover}
          onMouseEnter={this.setHover}
          onMouseLeave={this.setHover}
          isDate={isDate}
        >
          {isDate ? (
            <DatePicker
              onChange={onChange}
              dateStart={dateStart}
              minYear={minYear}
              maxYear={maxYear}
              value={value}
            />
          ) : (
            <Fragment>
              {!!imgLeft && (
              <span>
                <img src={imgLeft} alt="Icone" />
              </span>
              )}
              <MaskedInput
                ref={this.input}
                mask={phone || cpf}
                guide={false}
                type={inputType}
                value={value}
                disabled={disabled}
                onFocus={this.setFocus}
                onBlur={this.setBlur}
                onChange={onChange}
                {...props}
              />
              {phoneValidation
                ? (
                  <Button
                    size="small"
                    onClick={() => { this.props._push('/validar-telefone'); }}
                    style={{ minWidth: '120px', padding: '2px', height: 'auto' }}
                  >
                    Alterar número
                  </Button>
                )
                : !!imgRight && (
                  <IconRight isClick={isPassword} onClick={isPassword ? this.setIconPassword : null}>
                    <img src={imgRight} alt="Icone" />
                  </IconRight>
                )}
            </Fragment>
          )}
        </Input>

        {((hasError && !focus) || isPasswordRegister) && (
          <InputStatus
            success={isPasswordRegister && !(!!value && value.length < 6)}
            hasError={hasError}
          >
            {message}
          </InputStatus>
        )}
      </InputWrapper>
    );
  }
}

InputText.propTypes = {
  disabled: PropTypes.bool,
  isFormField: PropTypes.bool,
  isPasswordRegister: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  placeholderAlign: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.instanceOf(Array),
  scrollView: PropTypes.bool,
  isDate: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  marginTop: PropTypes.string,
  isPhone: PropTypes.bool,
  isCpf: PropTypes.bool,
  props: PropTypes.instanceOf(Array),
  _push: PropTypes.func,
};

const mapDispatchToProps = {
  _push: push,
};

export default connect(
  null,
  mapDispatchToProps,
)(InputText);
