import styled from 'styled-components';
import { device } from 'style/';

export const Img = styled.img`
  width: auto;
  max-height: 120px;
  flex: 1;

  @media ${device.fromTablet} {
    width: 150px;
    max-height: 165px;
  }
`;
