import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

export const initializeGA = () => ReactGA.initialize(process.env.GA_TOKEN);

export const trackView = (details, question) => {
  const prefix = process.env.NODE_ENV === 'production' ? '' : `[${process.env.NODE_ENV}] `;

  const page = `${prefix}Form ${details.text} version ${details.version}`;
  const dimension1 = `${question.field_order} - ${question.name}`;

  // if (view !== null && view !== undefined) {
  //   ReactGA.set({ page: view });
  //   ReactGA.pageview(`[${process.env.NODE_ENV}] ${view}`);
  //   console.log('GA pageview for ', view); // eslint-disable-line
  // }

  // ga('set', 'dimension5', 'custom data');

  ReactGA.ga('send', 'pageview', { page, dimension1 });
};

export const simpleTrack = (view) => {
  const prefix = process.env.NODE_ENV === 'production' ? '' : `[${process.env.NODE_ENV}] `;
  const page = `${prefix}Page - ${view}`;

  ReactGA.ga('send', 'pageview', { page });
};

export const emailTracking = (template, label) => {
  const prefix = process.env.NODE_ENV === 'production' ? '[email] ' : `[${process.env.NODE_ENV}-email] `;
  const page = `${prefix}${label}`;

  ReactGA.ga('send', 'pageview', { page, dimension2: template });
};

/* *** IMPORTANT *** NEW TRACKING */
/* TODO: Mudar o nome da função (não é somente para o pedidos v2) */
export const sendPedidosV2ToPixel = async (event, data) => {
  try {
    const options = {
      autoConfig: true,
      debug: false,
    };
    const pixelID = 'EAAFkmJvS0lUBAO5IrdN4hvRDTeJopUxsC5dMda70R4PBYsRO7vFktfdq3oZACdbtVDESqWBHbfaheVG7ZA5NscicihXm7VVdHiZBrx2Tri1NNS4DwkbqPFIiKLG0vQMu8aZCLN3SUMsKnppUrDKeV3t1p7ZCbuSZB7QRwot6hmqjVeULOKcLZBZA15pz0t9F7EwZD'
    await ReactPixel.init(pixelID, null, options);

    ReactPixel.track(event, data);
    console.log(`[FBPIXEL] - event send: ${event} - data: ${JSON.stringify(data)}`);
  } catch (error) {
    console.log('Error on send to pixel: ', error)
  }
};
/* *** IMPORTANT *** NEW TRACKING */

/* *** IMPORTANT *** NEW TRACKING */
export const gaSendEvent = (category, action, label) => {
  process.env.NODE_ENV !== 'production'
  && console.log('[TRACKING GA EVENT] - ', { category, action, label });

  ReactGA.event({ category, action, label });
  if (category.includes('page_')) {
    sendPedidosV2ToPixel(category, { action, label })
  }
};
/* *** IMPORTANT *** NEW TRACKING */

export const trackingForEmail = (search) => {
  const expression = /(.*)template=(.*)&label=(.*)/;

  if (expression.test(search)) {
    const result = expression.exec(search);
    const template = result[2];
    const label = result[3];

    emailTracking(template, label);
  }
};
