import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import { replace } from 'connected-react-router';

import { colors } from '@triider-web/uikit';
import Title from 'components/Title';
import Button from 'components/Button';

import imgSMS from 'static/img/icons/sms.svg';
import imgWhatsapp from 'static/img/mgm/whatsapp.svg';

import { validateUserFields } from 'helpers/userValidations';
import { validatePin, sendValidation } from 'reducers/userArea';
import { toPhone } from 'helpers/regex';

import StyledLink from 'components/StyledLink';
import InputText from 'components/InputText';
import StepWrapper from '../StepWrapper';
import { HeaderWrapper, ContentWrapper, FooterWrapper, CenterContent } from './styles';

class ConfirmPin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pin: '',
      errors: {},
    };

    this.setFields = this.setFields.bind(this);
    this.validateField = this.validateField.bind(this);
    this.sendPin = this.sendPin.bind(this);
    this.method = new URLSearchParams(location.search).get('method');
  }

  setFields({ target }) {
    const { name, value } = target;

    this.setState({ [name]: value });
  }

  validateField({ target }) {
    const { name, value } = target;

    const message = validateUserFields(name, value);

    const { errors: oldErrors } = this.state;

    const errors = {
      ...oldErrors,
      [name]: message,
    };

    this.setState({ errors });
  }


  sendPin(event, goHome) {
    event.preventDefault();

    const { _replace } = this.props;

    if (goHome) {
      _replace('/');
      return;
    }

    const { pin, errors: oldErrors } = this.state;

    const validation = { pin: validateUserFields('pin', pin) };

    const errors = Object.assign(oldErrors, validation);

    if (!Object.values(errors).some(e => !!e)) {
      const { _validatePin } = this.props;
      _validatePin(pin);
    } else {
      this.setState({ errors });
    }
  }


  render() {
    const { isLoading, phone, _sendValidation } = this.props;
    const { pin, errors } = this.state;

    const buttonDisabled = !pin || Object.values(errors).some(e => !!e);

    return (
      <Fragment>
        <StepWrapper
          noBorder
          onSubmit={this.sendPin}
          backgroundContent={colors.transparent}
          backgroundSection={colors.transparent}
        >
          <ContentWrapper>
            <HeaderWrapper>
              {
                this.method === 'whatsapp'
                  ? <img src={imgWhatsapp} alt="Icone" />
                  : <img src={imgSMS} alt="Icone" />
              }

              <Title>
                Insira o código de validação
              </Title>

              <p>
                {`Enviamos um código de validação por ${this.method === 'whatsapp' ? 'WhatsApp' : 'SMS'} para o número  ${phone ? toPhone(phone) : ''}`}
              </p>
            </HeaderWrapper>

            <CenterContent>
              <InputText
                placeholder="Insira o código aqui:"
                placeholderAlign="center"
                type="text"
                name="pin"
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                value={pin}
                error={errors.pin}
                scrollView={false}
                onChange={this.setFields}
                onBlur={this.validateField}
                autoComplete="off"
              />
              <StyledLink active onClick={() => _sendValidation(true, this.method)}>
                Reenviar código
              </StyledLink>
            </CenterContent>

            <div />
          </ContentWrapper>

          <FooterWrapper>
            <Button isLoading={isLoading} disabled={buttonDisabled} type="submit" size="block">
              Próximo
            </Button>
            <p onClick={(e) => this.sendPin(e, true)}>
              Não quero fazer isso agora
            </p>
          </FooterWrapper>
        </StepWrapper>
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  isLoading: state.ui.loaders.includes('GLOBAL'),
  phone: state.userArea.get('userArea').toJS().triiderUser.user.phone,
});

const mapDispatchToProps = {
  _validatePin: validatePin,
  _sendValidation: sendValidation,
  _replace: replace,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmPin);