import styled, { css } from 'styled-components';
import { device, colors, fonts } from 'style';

export const H1 = styled.h1`
  font-family: ${fonts.primary};
  color: ${colors.greyDark};
  font-weight: ${props => (props.perfil ? 'normal' : 500)};
  font-size: ${props => props.size || '1.4rem'};
  line-height: 2rem;
  margin: 0;
  padding: 0;
  display: ${props => props.coupon ? 'block' : 'flex'};
  text-align: center;
  margin-right: 0;
  margin-left: 0;
  justify-content: center;
  margin-top: ${props => props.marginTop};

  ${({ centered }) => centered && css`text-align: center;`}

  @media ${device.fromTablet} {
    margin-right: ${props => (props.coupon ? '-50px' : '0')};
    display: ${props => (props.coupon ? 'flex' : 'block')};
    margin-left: ${props => (props.coupon ? '82px' : '0px')};
    justify-content: ${props => (props.coupon ? 'space-between' : '')};
  }
`;

export const H2 = styled.h2`
  font-family: ${fonts.primary};
  color: ${colors.greyDark};
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 3px 0;
  padding: 0;

  ${({ centered }) => centered && css`text-align: center;`}

  ${props => props.onlyDesktop
    && css`
      display: none;

      @media ${device.fromTablet} {
        display: block;
      }
    `}
`;
