const INITIAL_STATE = {
  credits: 0,
  firstUseCredits: 0,
  settings: {},
  overview: {},
  userCreditsList: [],
  userInvitation: {},
  error: null,
};

/**
 * Types
 */
export const Types = {
  GET_SETTINGS_REQUEST: 'rewards/GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'rewards/GET_SETTINGS_SUCCESS',
  GET_USER_CREDITS_REQUEST: 'rewards/GET_USER_CREDITS_REQUEST',
  GET_USER_CREDITS_SUCCESS: 'rewards/GET_USER_CREDITS_SUCCESS',
  GET_REWARDS_OVERVIEW_REQUEST: 'rewards/GET_REWARDS_OVERVIEW_REQUEST',
  GET_REWARDS_OVERVIEW_SUCCESS: 'rewards/GET_REWARDS_OVERVIEW_SUCCESS',
  GET_LIST_USER_CREDITS_REQUEST: 'rewards/GET_LIST_USER_CREDITS_REQUEST',
  GET_LIST_USER_CREDITS_SUCCESS: 'rewards/GET_LIST_USER_CREDITS_SUCCESS',
  CHECK_REWARDS_REQUEST: 'rewards/CHECK_REWARDS_REQUEST',
  CHECK_REWARDS_SUCCESS: 'rewards/CHECK_REWARDS_SUCCESS',
  REWARDS_ERROR: 'rewards/REWARDS_ERROR',
};

/**
 * Reducer
 */
export default function task(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_SETTINGS_SUCCESS: {
      return { ...state, settings: action.payload };
    }
    case Types.GET_USER_CREDITS_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case Types.GET_REWARDS_OVERVIEW_SUCCESS: {
      return { ...state, overview: action.payload };
    }
    case Types.GET_LIST_USER_CREDITS_SUCCESS: {
      return { ...state, userCreditsList: action.payload };
    }
    case Types.CHECK_REWARDS_SUCCESS: {
      return { ...state, userInvitation: action.payload };
    }
    case Types.REWARDS_ERROR: {
      return { ...state, error: action.payload };
    }
    default:
      return state;
  }
}

/**
 * Actions Creators
 */
export const Creators = {
  getSettingsRequest: payload => ({
    type: Types.GET_SETTINGS_REQUEST,
    payload,
  }),
  getSettingsSuccess: payload => ({
    type: Types.GET_SETTINGS_SUCCESS,
    payload,
  }),
  getUserCreditsRequest: payload => ({
    type: Types.GET_USER_CREDITS_REQUEST,
    payload,
  }),
  getUserCreditsSuccess: payload => ({
    type: Types.GET_USER_CREDITS_SUCCESS,
    payload,
  }),
  getRewardsOverviewRequest: payload => ({
    type: Types.GET_REWARDS_OVERVIEW_REQUEST,
    payload,
  }),
  getRewardsOverviewSuccess: payload => ({
    type: Types.GET_REWARDS_OVERVIEW_SUCCESS,
    payload,
  }),
  getListUserCreditsRequest: payload => ({
    type: Types.GET_LIST_USER_CREDITS_REQUEST,
    payload,
  }),
  getListUserCreditsSuccess: payload => ({
    type: Types.GET_LIST_USER_CREDITS_SUCCESS,
    payload,
  }),
  checkRewardsRequest: payload => ({
    type: Types.CHECK_REWARDS_REQUEST,
    payload,
  }),
  checkRewardsSuccess: payload => ({
    type: Types.CHECK_REWARDS_SUCCESS,
    payload,
  }),
  rewardsError: payload => ({
    type: Types.REWARDS_ERROR,
    payload,
  }),
};
