import React, { useState, Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router/immutable';
import PropTypes from 'prop-types';

import { ProtectedRoute, NoAuthRoute } from 'containers';
import {
  RegisterStep1, RegisterStep2, RegisterStep3, RegisterStep4,
} from 'views/SiteArea/RegisterSteps';

import SwitchWithSlide from 'containers/SwitchWithSlide';

import routes from './config/routes';

const Login = lazy(() => import('views/SiteArea/Login'));
const ForgotPassword = lazy(() => import('views/SiteArea/ForgotPassword'));
const CreateNewPassword = lazy(() => import('views/SiteArea/CreateNewPassword'));
const ValidationEmail = lazy(() => import('views/SiteArea/ValidationEmail'));
const ConfirmEmail = lazy(() => import('views/SiteArea/ConfirmEmail'));
const Register = lazy(() => import('views/SiteArea/Register'));
const PartnerValidator = lazy(() => import('views/SiteArea/PartnerValidator'));
const TaskValidator = lazy(() => import('views/SiteArea/TaskValidator'));

const ApplicationWrapper = lazy(() => import('components/ApplicationWrapper'));

const TaskList = lazy(() => import('components/UserArea/TaskList'));
const ProProfile = lazy(() => import('components/UserArea/ProProfile'));
const TaskQuotes = lazy(() => import('components/UserArea/TaskQuotes'));

const TaskDetails = lazy(() => import('components/UserArea/TaskDetails'));
const Rating = lazy(() => import('components/UserArea/Rating'));
const RatingSuccess = lazy(() => import('components/UserArea/Rating/Success'));
const Unsubscribe = lazy(() => import('components/UserArea/Unsubscribe'));
const Help = lazy(() => import('components/UserArea/Help'));
const Site = lazy(() => import('components/UserArea/Site'));
const WaitingQuotes = lazy(() => import('components/UserArea/WaitingQuotes'));

const PaymentMethods = lazy(() => import('views/UserArea/PaymentMethods'));
const Coupons = lazy(() => import('views/UserArea/Coupons'));
const ChangePassword = lazy(() => import('views/UserArea/ChangePassword'));
const EditUser = lazy(() => import('views/UserArea/EditUser'));
const IncludeCreditCard = lazy(() => import('views/UserArea/IncludeCreditCard'));
const TaskSuccess = lazy(() => import('views/UserArea/TaskSuccess'));
const ChatTask = lazy(() => import('views/UserArea/ChatTask'));
const Hiring = lazy(() => import('views/UserArea/Hiring'));
const Payment = lazy(() => import('views/UserArea/Payment'));
const MemberGetMember = lazy(() => import('views/UserArea/MemberGetMember'));
const MGMValidator = lazy(() => import('views/SiteArea/MGMValidator'));
const ServiceForm = lazy(() => import('views/SiteArea/ServiceForm'));
const References = lazy(() => import('views/SiteArea/References'));
const ReferencesSuccess = lazy(() => import('views/SiteArea/References/ReferenceSuccess'));
const Routes = (props) => {
  const [animate] = useState(false);

  const SwitchComponent = animate ? SwitchWithSlide : Switch;
  const { history } = props;

  return (
    <ConnectedRouter history={history}>
      <ApplicationWrapper>
        <Suspense fallback={null}>
          <SwitchComponent>
            <Route exact path="/pedir-orcamento" component={ServiceForm} />
            <NoAuthRoute path="/entrar" component={Login} />
            <Route exact path="/recuperar" component={ForgotPassword} />
            <Route path="/recuperar/:email/:token/" component={CreateNewPassword} />
            <Route path="/validacao-email/:email/:token/" component={ValidationEmail} />
            <Route path="/confirmacao" component={ConfirmEmail} />
            <NoAuthRoute exact path={routes.register()} component={Register} />
            <NoAuthRoute exact path="/cadastro/conta" component={RegisterStep1} />

            <ProtectedRoute exact path="/cadastro/perfil" component={RegisterStep2} />

            <ProtectedRoute
              exact
              path="/cadastro/confirmacao"
              component={() => <RegisterStep3 viewType="REGISTER" />}
            />
            <ProtectedRoute
              exact
              path="/finalizar-pedido"
              component={() => <RegisterStep3 viewType="TASK" />}
            />
            <ProtectedRoute
              exact
              path="/validar-telefone"
              component={() => <RegisterStep3 viewType="VALIDATION" />}
            />
            <ProtectedRoute
              exact
              path="/validar-telefone-pin"
              component={() => <RegisterStep4 />}
            />

            <Route path="/partner/:partner" component={PartnerValidator} />
            <Route path="/finalizar" component={TaskValidator} />
            <ProtectedRoute path="/indique-e-ganhe" component={() => <MemberGetMember />} />
            <ProtectedRoute
              path="/meus-creditos"
              component={() => <MemberGetMember isMenuCredits />}
            />
            <Route path={routes.mgmValidator()} component={MGMValidator} />

            <ProtectedRoute path="/meios-de-pagamento" component={PaymentMethods} />
            <ProtectedRoute path="/incluir-meio-de-pagamento" component={IncludeCreditCard} />
            <ProtectedRoute path="/pedido-realizado" component={TaskSuccess} />
            <ProtectedRoute path="/" component={TaskList} exact />
            <Route path="/profissionais/:id" component={ProProfile} exact />
            <ProtectedRoute path="/solicitacoes/:id/orcamentos" component={TaskQuotes} />
            <ProtectedRoute path="/solicitacoes/:id/chat/:idQuote" component={ChatTask} />
            <ProtectedRoute path="/solicitacoes/:id/fechar/:idQuote" component={Hiring} />
            <ProtectedRoute path="/solicitacoes/:id/pagar/:idQuote" component={Payment} />
            <Route path="/solicitacoes/:id" component={TaskDetails} />

             <Route path="/avaliar/sucesso/:rating" component={RatingSuccess} exact />
             <Route path="/avaliar/:id" component={Rating} />
            <Route path="/email_unsubscribe" component={Unsubscribe} />
            <Route path="/ajuda" component={Help} />
            <Route path="/site" component={Site} />
            <Route path="/aguardando-orcamentos" component={WaitingQuotes} />
            <ProtectedRoute exact path="/cupons" component={Coupons} />
            <ProtectedRoute exact path="/alterar-senha" component={ChangePassword} />
            <ProtectedRoute exact path="/editar-perfil" component={EditUser} />
            <Route exact path="/profissional/referencias/sucesso" component={ReferencesSuccess} />
            <Route exact path="/profissional/referencias/:token" component={References}/>
          </SwitchComponent>
        </Suspense>
      </ApplicationWrapper>
    </ConnectedRouter>
  );
};

Routes.propTypes = {
  history: PropTypes.instanceOf(Object),
};

export default Routes;
