import {
  call, put, all, select,
} from 'redux-saga/effects';

import {
  apiGetQuoteById,
  apiGetTaskById,
  apiGetTaskInstallments,
  apiSetTaskInstallments,
  apiGetCouponById,
  apiRedeemCoupon,
  apiSetTaskCoupon,
  apiSetTaskCheckoutPayment,
  apiAuthorizeTaskQuote,
  apiSetQuoteSelected,
  apiNotifyTaskClose,
  apiGetBusinessTask,
  apiSetTaskPaid,
  apiSetTaskCredits,
  apiGetTasksByUser,
} from 'services/api/task';
import {
  apiGetUserCreditCards,
  apiSaveCreditCard,
  apiGetCardById,
  updateUser,
  apiReportProblem,
  apiGetProblemReasons,
} from 'services/api/user';
import { getUserCoupons } from 'services/api/profile';

import * as UserAreaCreators from 'reducers/userArea';
import { getTriiderUser } from 'reducers/userArea';
import { errMsg as couponErrorMsg } from 'reducers/userArea/helpers/messages';

import { getErrorMessage } from 'utils/filters';

import { initializeGA, gaSendEvent } from 'helpers/analytics';

import { Creators as TaskCreators } from '../ducks/task';
import { Creators as UiCreators, growlTypes } from '../ducks/ui';

import { getUserCredits } from './rewards';

const fileName = 'sagas/task';

export function* getTasks({ payload: userId }) {
  try {
    yield put(UiCreators.showLoader('TASKS'));

    const { tasks } = yield call(apiGetTasksByUser, userId);

    yield all([
      put(TaskCreators.getTasksByUserSuccess(tasks)),
      put(UiCreators.hideLoader('TASKS')),
    ]);
  } catch (error) {
    yield put(UiCreators.hideLoader('TASKS'));
  }
}

export function* setTaskCredits({
  payload: {
    task_id, quote_id, credits, callback,
  },
}) {
  try {
    yield put(UiCreators.showLoader('HIRING'));
    yield call(apiSetTaskCredits, task_id, credits);

    if (callback) {
      callback();
      yield put(TaskCreators.getHiringDataRequest({ task_id, quote_id, isReload: true }));
    }
  } catch (error) {
    const message = couponErrorMsg(error.response.data.error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/setTaskCredits`,
        }),
      ),
      put(UiCreators.hideLoader('HIRING')),
    ]);
  }
}

export function* getHiringData({
  payload: {
    task_id, quote_id, isReload, hideLoader,
  },
}) {
  try {
    if (!hideLoader) {
      yield put(UiCreators.showLoader('HIRING'));
    }

    const { user } = yield select(getTriiderUser);

    const { task } = yield call(apiGetTaskById, task_id);
    const { quote } = yield call(apiGetQuoteById, quote_id);
    const { coupons } = yield call(getUserCoupons, user);
    yield call(getUserCredits);

    const creditCards = [];
    if (!isReload) {
      const { creditCards: c } = yield call(apiGetUserCreditCards, user.id);
      creditCards.push(...c);
    }

    const amount = task.coupon_id
      ? quote.new_amount_with_discount || quote.amount_with_discount
      : quote.new_amount || quote.amount;
    const { installments } = yield call(apiGetTaskInstallments, amount);

    // TODO: Extract this logic
    const { coupon = {} } = task.coupon_id ? yield call(apiGetCouponById, task.coupon_id) : {};

    yield all([
      put(
        TaskCreators.getHiringDataSuccess({
          task: { ...task, success: false },
          quote,
          installments,
          coupon,
          coupons,
          ...(!isReload && { creditCards }),
        }),
      ),
      !hideLoader && put(UiCreators.hideLoader('HIRING')),
    ]);
  } catch (error) {
    console.log(error);
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/getHiringData`,
        }),
      ),
      !hideLoader && put(UiCreators.hideLoader('HIRING')),
    ]);
  }
}

export function* getTaskById({
  payload: {
    task_id, hideLoader,
  },
}) {
  try {
    if (!hideLoader) {
      yield put(UiCreators.showLoader('TASK_BY_ID'));
    }

    const { task } = yield call(apiGetTaskById, task_id);

    yield all([
      put(
        TaskCreators.getTaskByIdSuccess(task),
      ),
      !hideLoader && put(UiCreators.hideLoader('TASK_BY_ID')),
    ]);
  } catch (error) {
    console.log(error);
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/getTaskById`,
        }),
      ),
      !hideLoader && put(UiCreators.hideLoader('TASK_BY_ID')),
    ]);
  }
}

export function* getPaymentData({ payload: { task_id, quote_id } }) {
  try {
    yield put(UiCreators.showLoader('HIRING'));

    yield call(getHiringData, { payload: { task_id, quote_id, hideLoader: true } });

    const { task, creditCards: cards } = yield select(state => state.task.hiring);

    const { businessTask } = yield call(apiGetBusinessTask, task);
    const { customerCard: card } = yield call(apiGetCardById, task.customer_card_id);

    const creditCards = cards.map(c => (c.id === card.id ? { ...c, selected: true } : { ...c, selected: false }));

    yield all([
      put(
        TaskCreators.getHiringDataSuccess({
          businessTask,
          creditCards,
        }),
      ),
      put(UiCreators.hideLoader('HIRING')),
    ]);
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/getPaymentData`,
        }),
      ),
      put(UiCreators.hideLoader('HIRING')),
    ]);
  }
}

export function* setInstallments({ payload: { installments, task_id } }) {
  try {
    yield call(apiSetTaskInstallments, task_id, installments);

    yield put(TaskCreators.setInstallmentsSuccess(installments));
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/setInstallments`,
        }),
      ),
    ]);
  }
}

export function* setTaskCoupon({
  payload: {
    coupon: c, task_id, quote_id, callback,
  },
}) {
  try {
    yield put(UiCreators.showLoader('HIRING'));
    const coupon = c;
    const { coupon: couponRedeemed } = yield call(apiRedeemCoupon, coupon.code);
    Object.assign(coupon, couponRedeemed);
    yield call(apiSetTaskCoupon, task_id, coupon.id);

    if (callback) callback();
    yield put(TaskCreators.getHiringDataRequest({ task_id, quote_id, isReload: true }));
  } catch (error) {
    const message = couponErrorMsg(error.response.data.error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/setTaskCoupon`,
        }),
      ),
      put(UiCreators.hideLoader('HIRING')),
    ]);
  }
}

export function* saveCreditCard({ payload: { card, callback } }) {
  try {
    yield put(UiCreators.showLoader('HIRING'));

    const { user } = yield select(getTriiderUser);

    const { card: c } = yield call(apiSaveCreditCard, user.id, card);
    // const { creditCards } = yield call(apiGetUserCreditCards, user.id);

    yield all([
      put(TaskCreators.saveCreditCardSuccess({ ...c, selected: true })),
      put(UiCreators.hideLoader('HIRING')),
    ]);
    if (callback) callback(c);
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/saveCreditCard`,
        }),
      ),
      put(UiCreators.hideLoader('HIRING')),
    ]);
  }
}

export function* updateUserFields({ payload: { callback } }) {
  try {
    yield put(UiCreators.showLoader('HIRING'));

    const triiderUser = yield select(getTriiderUser);
    const { user } = triiderUser;

    const { id, birthday, gender } = user;

    const fields = { birthday, gender, asked_info_at: new Date() };

    const data = yield call(updateUser, fields, id);
    if (data.success) {
      Object.assign(user, fields);
    }

    const newUser = {
      ...triiderUser,
      user,
    };
    yield put(UserAreaCreators.fetchTriiderUser(newUser));
    if (callback) callback();
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/updateUserFields`,
        }),
      ),
      put(UiCreators.hideLoader('HIRING')),
    ]);
  }
}

export function* finishHiring({ payload: { card } }) {
  try {
    yield put(UiCreators.showLoader('HIRING'));

    const { user } = yield select(getTriiderUser);
    const { quote, task } = yield select(state => state.task.hiring);

    // if (!task.from_tenant) {
    yield call(apiSetTaskCheckoutPayment, quote.task_id, card);
    // }
    yield call(apiAuthorizeTaskQuote, quote);
    yield call(apiSetQuoteSelected, quote);
    yield call(apiNotifyTaskClose, user, quote);

    try {
      /* *** IMPORTANT *** NEW TRACKING */
      yield call(initializeGA);
      yield call(gaSendEvent, task.service_text, 'contratar', task.source);
      /* *** IMPORTANT *** NEW TRACKING */
    } catch (error) {
      console.log('[GA] :>> ', error);
    }

    yield all([
      put(TaskCreators.setQuote({ ...quote, success: true })),
      put(UiCreators.hideLoader('HIRING')),
    ]);
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/finishHiring`,
        }),
      ),
      put(UiCreators.hideLoader('HIRING')),
    ]);
  }
}

export function* getPaymentProblems() {
  try {
    yield put(UiCreators.showLoader('PAYMENT-PROBLEMS'));

    const { list: reportProblems } = yield call(apiGetProblemReasons);

    yield all([
      put(TaskCreators.getPaymentProblemsSuccess(reportProblems)),
      put(UiCreators.hideLoader('PAYMENT-PROBLEMS')),
    ]);
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/getPaymentProblems`,
        }),
      ),
      put(UiCreators.hideLoader('PAYMENT-PROBLEMS')),
    ]);
  }
}

export function* reportProblem({ payload: { task, feedback, callback } }) {
  try {
    yield put(UiCreators.showLoader('PAYMENT-PROBLEMS'));

    yield call(apiReportProblem, task, feedback);

    yield all([
      put(UiCreators.hideLoader('PAYMENT-PROBLEMS')),
      put(UiCreators.growl('Seu problema foi reportado!', growlTypes.GROWL_SUCCESS)),
    ]);
    if (callback) {
      callback();
    }
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/reportProblem`,
        }),
      ),
      put(UiCreators.hideLoader('PAYMENT-PROBLEMS')),
    ]);
  }
}

export function* setTaskPaid({ payload: { task, card, callback } }) {
  try {
    yield put(UiCreators.showLoader('HIRING'));

    if (card.id !== task.customer_card_id) {
      yield call(apiSetTaskCheckoutPayment, task.id, card);
    }

    yield call(apiSetTaskPaid, task);

    try {
      /* *** IMPORTANT *** NEW TRACKING */
      yield call(initializeGA);
      yield call(gaSendEvent, task.service_text, 'autorizar_pagamento', task.source);
      /* *** IMPORTANT *** NEW TRACKING */
    } catch (error) {
      console.log('[GA] :>> ', error);
    }

    yield all([put(UiCreators.hideLoader('HIRING'))]);
    if (callback) {
      callback();
    }
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/setTaskPaid`,
        }),
      ),
      put(UiCreators.hideLoader('HIRING')),
    ]);
  }
}
