export default {
  rappi: {
    home: '/rappi',
    magnifyingGlass: require('static/img/icons/lupa.png'),
  },
  guarida: {
    home: '/p/guarida',
    magnifyingGlass: require('static/img/icons/lupa-blue.svg'),
  },
};
