import styled, { css, keyframes } from 'styled-components';
import { colors } from 'style';

const rotate = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
`;

const sizes = {
  small: css`
    width: 30px;
    height: 30px;
    border: 3px solid ${props => colors[props.color]};
  `,
  larger: css`
    width: 70px;
    height: 70px;
    border: 6px solid ${props => colors[props.color]};
  `,
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100px;
  height: 100px; */

  animation: ${rotate} 2s linear infinite;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;

    ${props => sizes[props.size]}

    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => colors[props.color]} transparent transparent transparent;
  }

  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
