import React from 'react';
import PropTypes from 'prop-types';
import RCTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import './override.less';

import { Content, TooltipStyled } from './styles';
import { Icon } from '../styles';

class Tooltip extends React.Component {
  state = {
    visible: false,
  };

  onVisibleChange = (visible) => {
    this.setState({
      visible,
    });
  };

  render() {
    const { visible } = this.state;
    const { icon, children, content } = this.props;

    return (
      <TooltipStyled>
        <RCTooltip
          overlayClassName="tr-tooltip"
          overlay={children}
          visible={visible}
          animation="zoom"
          trigger="click"
          onVisibleChange={this.onVisibleChange}
        >
          <Content onClick={e => e.preventDefault()}>
            {content}
            {!!icon && (
              <Icon>
                <img src={icon} alt="Mais informações" />
              </Icon>
            )}
          </Content>
        </RCTooltip>
      </TooltipStyled>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Tooltip;
