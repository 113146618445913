import {
  call, put, all, select,
} from 'redux-saga/effects';

import { getFingerprint } from 'utils/fingerprint';

import {
  apiGetRewardsSettings,
  apiGetUserCredits,
  apiGetRewardsOverview,
  apiListUserCredits,
  apiCheckRewards,
} from 'services/api/rewards';

import { getErrorMessage } from 'utils/filters';

import { Creators as RewardsCreators } from '../ducks/rewards';
import { Creators as UiCreators, growlTypes } from '../ducks/ui';

import { isAuth, getTriiderUser, updateTriiderUser } from '../../reducers/userArea';

const fileName = 'sagas/rewards';

export function* getRewardsSettings({ payload }) {
  try {
    yield put(UiCreators.showLoader('REWARDS-SETTINGS'));

    const { settings } = yield call(apiGetRewardsSettings);

    yield put(RewardsCreators.getSettingsSuccess(settings));
    yield put(UiCreators.hideLoader('REWARDS-SETTINGS'));
  } catch (error) {
    const message = getErrorMessage(error);
    console.log('rewards > settings >> ', message);
    yield all([put(UiCreators.hideLoader('REWARDS-SETTINGS'))]);
  }
}

export function* getUserCredits() {
  try {
    yield put(UiCreators.showLoader('REWARDS-CREDITS'));

    const {
      user: { id },
    } = yield select(getTriiderUser);

    const { credits, firstUseCredits } = yield call(apiGetUserCredits, id);

    yield put(RewardsCreators.getUserCreditsSuccess({ credits, firstUseCredits }));
    yield put(UiCreators.hideLoader('REWARDS-CREDITS'));
  } catch (error) {
    const message = getErrorMessage(error);
    console.log(error);
    yield all([
      // put(
      //   UiCreators.growl(message, growlTypes.GROWL_ERROR, {
      //     error,
      //     method: `${fileName}/getUserCredits`,
      //   }),
      // ),
      put(UiCreators.hideLoader('REWARDS-CREDITS')),
    ]);
  }
}

export function* getRewardsOverview({ payload }) {
  try {
    yield put(UiCreators.showLoader('REWARDS'));

    const {
      user: { id },
    } = yield select(getTriiderUser);

    const { overview } = yield call(apiGetRewardsOverview, id);

    yield put(RewardsCreators.getRewardsOverviewSuccess(overview));
    yield put(UiCreators.hideLoader('REWARDS'));
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/getRewardsOverview`,
        }),
      ),
      put(UiCreators.hideLoader('REWARDS')),
    ]);
  }
}

export function* getListUserCredits({ payload }) {
  try {
    yield put(UiCreators.showLoader('REWARDS'));

    const {
      user: { id },
    } = yield select(getTriiderUser);

    const { data } = yield call(apiListUserCredits, id);

    yield put(RewardsCreators.getListUserCreditsSuccess(data));
    yield put(UiCreators.hideLoader('REWARDS'));
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/getListUserCredits`,
        }),
      ),
      put(UiCreators.hideLoader('REWARDS')),
    ]);
  }
}

export function* checkRewards({ payload: { code: mgm_code } }) {
  try {
    yield put(UiCreators.showLoader('CHECK-REWARDS'));

    const df = yield getFingerprint();

    const data = yield call(apiCheckRewards, mgm_code, df);

    yield put(RewardsCreators.checkRewardsSuccess({ ...data, mgm_code }));
    yield put(UiCreators.hideLoader('CHECK-REWARDS'));
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(RewardsCreators.rewardsError(message)),
      put(UiCreators.hideLoader('CHECK-REWARDS')),
    ]);
  }
}
