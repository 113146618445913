import sequence from 'utils/sequence';

/**
 * Types
 */

const INITIAL_STATE = {
  growls: [],
  error: {},
  errorPhoneUpdate: {},
  loaders: [],
  loadingVisible: false,
  modalVisible: false,
  routeRedirect: null,
  route: null,
  headerZIndex: 10,
};

export const Types = {
  GROWL_ADDED: 'ui/GROWL_ADDED',
  GROWL_REMOVED: 'ui/GROWL_REMOVED',
  SHOW_LOADER: 'ui/SHOW_LOADER',
  HIDE_LOADER: 'ui/HIDE_LOADER',
  SHOW_MODAL: 'ui/SHOW_MODAL',
  HIDE_MODAL: 'ui/HIDE_MODAL',
  ROUTE_REDIRECT: 'ui/ROUTE_REDIRECT',
  HEADER_MOVE_TO_BACK: 'ui/HEADER_MOVE_TO_BACK',
  HEADER_MOVE_TO_FRONT: 'ui/HEADER_MOVE_TO_FRONT',
};

/**
 * Helpers
 */
const hideAllGrowls = growls => growls.map(g => ({ ...g, hidden: true }));
const hideGrowl = (target, growls) => growls.map(g => (g === target ? { ...g, hidden: true } : g));
export const getGrowls = state => state.ui.growls;

/**
 * Reducer
 */
export default function Ui(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GROWL_ADDED:
      return {
        ...state,
        growls: [...hideAllGrowls(state.growls), action.payload.message],
        error: action.payload.error,
      };
    case Types.GROWL_REMOVED:
      return {
        ...state,
        growls: hideGrowl(action.payload, state.growls),
      };
    case Types.SHOW_LOADER: {
      const loader = action.payload || 'GLOBAL';

      const loaders = state.loaders.includes(loader)
        ? state.loaders
        : [...state.loaders, loader];
      return {
        ...state,
        loaders,
      };
    }
    case Types.HIDE_LOADER: {
      const loader = action.payload || 'GLOBAL';
      return {
        ...state,
        loaders: state.loaders.filter(l => l !== loader),
      };
    }
    case Types.SHOW_MODAL:
      return { ...state, modalVisible: true, errorPhoneUpdate: action.payload };
    case Types.HIDE_MODAL:
      return { ...state, modalVisible: false };
    case Types.ROUTE_REDIRECT:
      return { ...state, routeRedirect: action.payload };
    case Types.LOCATION_CHANGE: {
      const { pathname } = action.payload.location;
      const { routeRedirect } = state;
      return {
        ...state,
        route: pathname,
        growl: {},
        modalVisible: false,
        loadingVisible: false,
        routeRedirect: routeRedirect === pathname ? routeRedirect : null,
      };
    }
    case Types.HEADER_MOVE_TO_BACK:
      return { ...state, headerZIndex: 0 };
    case Types.HEADER_MOVE_TO_FRONT:
      return { ...state, headerZIndex: 10 };

    default:
      return state;
  }
}

/**
 * Constants
 */
export const growlTypes = {
  GROWL_INFO: 'GROWL_INFO',
  GROWL_ERROR: 'GROWL_ERROR',
  GROWL_SUCCESS: 'GROWL_SUCCESS',
};

const idSeq = sequence();

/**
 * Actions Creators
 */
export const Creators = {
  growl: (message, type = growlTypes.GROWL_INFO, error) => {
    const payload = {
      message: {
        type,
        message,
        id: idSeq.next(),
      },
      error,
    };
    return {
      type: Types.GROWL_ADDED,
      payload,
    };
  },
  removeGrowl: payload => ({
    type: Types.GROWL_REMOVED,
    payload,
  }),
  showLoader: payload => ({ type: Types.SHOW_LOADER, payload }),
  hideLoader: payload => ({ type: Types.HIDE_LOADER, payload }),
  showModal: payload => ({ type: Types.SHOW_MODAL, payload }),
  hideModal: () => ({ type: Types.HIDE_MODAL }),
  routeRedirect: payload => ({ type: Types.ROUTE_REDIRECT, payload }),
  headerMoveToBack: payload => ({ type: Types.HEADER_MOVE_TO_BACK, payload }),
  headerMoveToFront: payload => ({ type: Types.HEADER_MOVE_TO_FRONT, payload }),
};
