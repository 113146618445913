import styled, { css } from 'styled-components';
import { colors } from '@triider-web/uikit';
import { device } from 'style';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  width: 100%;
  max-height: 100px;
  flex: 1;
  width: 100%;
  max-height: max-content;
  margin-top: 33px;
  margin-bottom: 0.5rem;

  && p {
    color: ${colors.greyMedium};
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

  }

  & h1 {
    margin: 7px 0;
  }

  @media ${device.fromTablet} {
    align-items: center;
    max-height: ${props => (props.isModal ? 'max-content' : 175)}px;
    margin-top: 20px;

    & > :first-child {
      display: flex;
    }
  }

  @media ${device.untilMobileL} {
    && > p {
    font-size:14px;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  max-width: 400px;
  padding: 0 1rem;
  margin-bottom: 20px;

  @media ${device.fromTablet} {
    padding: 0;
  }

  & > p {
    font-size: 11px;
    max-width: 300px;
    width: 100%;
    margin-top: 10px;

    @media ${device.fromTablet} {
      font-size: 14px;
      text-align: center;
      max-width: inherit;
    }
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: max-content;

  && p {
    color: ${colors.primary};
    margin: 20px 0 0 0;
    text-decoration: underline;
    cursor: pointer;
  }

  ${props => props.isModal
    && css`
      & a:first-child {
        margin-bottom: 1.5rem;
      }
    `}

  @media ${device.fromTablet} {
    justify-content: center;
    max-height: 150px;

      ${props => props.isModal
    && css`
          flex-direction: column-reverse;
          & a:first-child {
            margin-top: 1.5rem;
          }
        `}
  }
`;

export const Radio = styled.input`
  margin: 10px;
  color: red;
`;

export const PhoneNumber = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-bottom: 30px; */

  && :nth-child(2) {
    color: ${colors.primary};
    text-decoration: underline;
    cursor: pointer;
    p{
      margin: 0;
    }
  }
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
  max-width:300px;

  && :first-child {
    margin-bottom: 30px;
  }
  && :nth-child(2) {
    margin-bottom: 20px;    
  }
`;

export const ContentButtonModal = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 30px;

  && :first-child {
    margin-right: 20px;
  }
`;

export const ContainerConditional = styled.div`
  width: 100%;
  display: ${props => (props.displayContainer ? 'none' : 'block')};
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  padding: 15px 25px;

  .p-title{
    font-size: 14px;
  }
  .p-option{
    @media (max-width: 320px) {
      font-size: 14px;
    }
  }
`;

export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;  
  width: 100%;

  .question {
    margin: 25px 0 15px 0;
    font-size:14px;
  }

  label{
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto';
    cursor: pointer;
  } 
  input[type=radio], input[type=checkbox] {   
   display:none; 
  }
  input[type=radio] + span:before, input[type=checkbox] + span:before { 
    border: 1px solid #5D5C5C;
    content: "";
    display: inline-block;    
    font: 16px/1em sans-serif;    
    height: 18px;    
    margin: 2px 10px 0 0;    
    padding:0;    
    vertical-align: top;    
    width: 18px;    
    border-radius:50%;
  }

  input[type="radio"]:checked + span:before, .exemplo2 input[type="checkbox"]:checked + span:before {    
    border: 5px solid ${colors.primary};    
    color: #FFF;    
    content: "";    
    text-align: center;
  }

  input[type=radio]:checked + span:after, input[type="checkbox"]:checked + span:after {    
    font-weight: bold;
  }
`;