import styled, { css } from 'styled-components';
import { colors, flex, device } from 'style';

export const ContainerStyle = styled.div`
  ${flex.columnGrow}
  width: 100%;
  max-width: ${({ containerWidthDesktop, isFullMobile }) => containerWidthDesktop || (isFullMobile ? 500 : null) || 873}px;

  ${props => props.isFullMobile
    && css`
      background: ${({ background }) => colors[background] || colors.white};

      @media ${device.fromTablet} {
        background: transparent;
        min-height: ${({ containerHeightDesktop }) => `${containerHeightDesktop}px` || 'max-content'};
        max-height: ${({ containerHeightDesktop }) => containerHeightDesktop || 700}px;
      }
    `}
`;

const flexContainerSizes = {
  small: '',
  medium: '475px',
};

export const FlexContainerStyled = styled.div`
  display: flex;
  width: inherit;
  height: inherit;
  max-height: ${props => flexContainerSizes[props.size] || 'inherit'};

  ${props => flex[props.axis][props.alignmentMobile]}

  @media ${device.fromTablet} {
    ${props => flex[props.axis][props.alignment]}
    min-height: inherit;
  }

  ${props => !!props.padding
    && css`
      padding: ${props.padding};
    `}

    ${props => !!props.margin
      && css`
        margin: ${props.margin};
      `}
`;
