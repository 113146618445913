import styled, { css } from 'styled-components';
import { colors, device } from 'style';

// Outros tamanhos podem ser declarados aqui
const sizes = {
  minimum: css`
    height: 35px;
    border-radius: 3px;
    padding: 20px;
    font-weight: 500;
    font-size: 14px;
  `,
  small: css`
    height: 35px;
    min-width: 110px;
    border-radius: 3px;
    padding: 0 10px;
    font-weight: 500;
    font-size: 13px;
  `,
  medium: css`
    height: 50px;
    width: 250px;
    border-radius: 3px;
  `,
  larger: css`
    height: 52px;
    width: 100%;
    min-width: 270px;
    max-width: 300px;
    border-radius: 3px;
  `,
  block: css`
    width: 100%;
    height: 56px;

    @media ${device.fromTablet} {
      max-width: 360px;
      border-radius: 3px;
    }
  `,
  fullBlock: css`
  width: 100%;
  height: 56px;
  `,
  perfil: css`
  height: 40px;
  width: 100%;
  border-radius: 3px;
  `,
};

export const ButtonStyled = styled.button`
  background: ${props => (props.disabled && !props.isLoading && colors.greyLighter) || props.inHeader && 'transparent' || colors[props.background]};
  color: ${props => (props.disabled && !props.isLoading && colors.greyMedium) || colors[props.color]};
  border: 1px solid
    ${props => (props.disabled && !props.isLoading && colors.greyLighter)
      || colors[props.border]
      || colors[props.background]};
  cursor: ${props => (props.disabled && 'not-allowed') || 'pointer'};

  &:focus {
    outline: none !important;
  }
  
  @media ${device.untilTablet} {
    border:  ${props => (props.inHeader && 'none')};
    background:  ${props => (props.inHeader && 'transparent')};
  }

  font-family: 'Quicksand';
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: ${props => props.marginTop};

  ${props => props.centered
    && css`
      margin: 0 auto;
    `}

  ${props => sizes[props.size]};

  &:hover {
    opacity: 0.8;
  }
`;

export const IconLeft = styled.img`
  margin-right: 1rem;
`;

export const IconRight = styled.img`
  margin-left: 1rem;
`;
