import { css } from 'styled-components';

export const sizes = {
  deviceHeight: window.innerHeight,
  deviceWidth: window.innerWidth,
  header: 70,
  headerLaptop: 65,
  title: '14px'
};

export const fullMobile = css`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  margin-top: ${sizes.header};
`;
