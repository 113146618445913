import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';

import { updateTriiderUser, getTriiderUser } from 'reducers/userArea';
import { validateUserFields, genderOptions } from 'helpers/userValidations';

import InputText from 'components/InputText';
import Button from 'components/Button';
import Title, { Subtitle } from 'components/Title';
import Dots from 'components/Dots';
import Popover from 'components/Popover';
import Select from 'components/Select';

import iconQuestion from 'static/img/icons/question.svg';
import StepWrapper from '../StepWrapper';

import { HeaderWrapper, ContentWrapper, FooterWrapper } from './styles';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverVisible: false,
      user: {
        birthday: '',
        gender: '',
      },
      errors: {},
    };

    this.setFields = this.setFields.bind(this);
    this.validateField = this.validateField.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.togglePopover = this.togglePopover.bind(this);
  }

  componentDidMount() {
    const { getUser } = this.props;
    const { birthday, gender } = getUser();
    // const birthday=null, gender='';

    this.setState({
      user: {
        birthday,
        gender: gender || '',
      },
      showBirthday: !birthday,
      showGender: !gender,
    });
  }

  setFields({ target }) {
    const { name, value } = target;
    const { user } = this.state;

    this.setState({ user: { ...user, [name]: value } });
  }

  validateField({ target }) {
    const { name, value } = target;

    const message = validateUserFields(name, value);

    const { errors: oldErrors } = this.state;

    const errors = {
      ...oldErrors,
      [name]: message,
    };

    this.setState({ errors });
  }

  nextStep(event) {
    event.preventDefault();

    const { user, errors: oldErrors } = this.state;

    const validation = {};
    Object.entries(user).forEach(([key, value]) => {
      Object.assign(validation, { [key]: validateUserFields(key, value) });
    });

    const errors = Object.assign(oldErrors, validation);

    if (!Object.values(errors).some(e => !!e)) {
      const {
        _updateTriiderUser, _push,
      } = this.props;

      Object.assign(user, { asked_info_at: new Date() });
      _updateTriiderUser(user, () => _push('/cadastro/confirmacao'));
    } else {
      this.setState({ errors });
    }
  }

  togglePopover() {
    const { popoverVisible } = this.state;

    this.setState({ popoverVisible: !popoverVisible });
  }

  render() {
    const { isLoading } = this.props;
    const {
      user, errors, popoverVisible, showBirthday, showGender,
    } = this.state;
    const { birthday, gender } = user;
    const skip = !birthday && !gender;
    const buttonDisabled = Object.values(errors).some(e => !!e);
    const dots = [{ active: false }, { active: true }, { active: false }];

    return (
      <StepWrapper onSubmit={this.nextStep}>
        <ContentWrapper>
          <HeaderWrapper>
            <Dots dots={dots} />
            <Title>Preencha o seu perfil</Title>
            <Subtitle>
              {' '}
              <Popover
                onlyMobile
                width={270}
                height={160}
                right={-40}
                onClick={this.togglePopover}
                active={popoverVisible}
                icon={iconQuestion}
                textButton="FECHAR"
              >
                Essas informações são privadas e só serão utilizadas internamente para entendermos
                quem são os clientes do Triider! :)
              </Popover>
            </Subtitle>
          </HeaderWrapper>

          {showBirthday && (
            <InputText
              placeholder="Quando você nasceu?"
              name="birthday"
              value={birthday}
              error={errors.birthday}
              onChange={this.setFields}
              isDate
            />
          )}

          {showGender && (
            <Select
              placeholder="Qual é o seu gênero?"
              name="gender"
              value={gender}
              onChange={this.setFields}
              options={genderOptions}
              perfil
            />
          )}
        </ContentWrapper>

        <FooterWrapper>
          <Button isLoading={isLoading} disabled={buttonDisabled} type="submit" size="block">
            {skip ? 'Pular' : 'Próximo'}
          </Button>
        </FooterWrapper>
      </StepWrapper>
    );
  }
}

Profile.propTypes = {
  isLoading: PropTypes.bool,
  getUser: PropTypes.instanceOf(Object),
  _updateTriiderUser: PropTypes.func,
  _push: PropTypes.func,
};

const mapStateToProps = state => ({
  isLoading: state.ui.loaders.includes('GLOBAL'),
  getUser: () => getTriiderUser(state).user,
});

const mapDispatchToProps = {
  _updateTriiderUser: updateTriiderUser,
  _push: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
