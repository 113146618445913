import styled, { css } from 'styled-components';
import { device } from 'style';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  flex: 1;
  width: 100%;
  max-height: max-content;
  margin-top: 30px;

  ${({ isSocialMedia }) => isSocialMedia
    && css`
      margin-bottom: 1rem;

      & > img {
        margin: 1rem auto;

        @media ${device.fromTablet} {
          display: none;
        }
      }
    `}

  & > :first-child {
    display: none;
  }

  @media ${device.fromTablet} {
    align-items: center;
    justify-content: space-around;
    max-height: ${({ isSocialMedia }) => (isSocialMedia ? '200px' : '100px')};
    margin-top: 20px;

    & > :first-child {
      display: flex;
    }

    & h2 {
      text-align: center;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ isSocialMedia }) => (isSocialMedia ? 'flex-start' : 'space-around')};
  align-items: ${({ isSocialMedia }) => (isSocialMedia ? 'center' : 'flex-start')};
  flex: 1;
  width: 100%;
  max-width: ${({ isSocialMedia }) => (isSocialMedia ? '420px' : '400px')};
  padding: 0 1rem;

  & > * {
    margin-bottom: 10px;
  }

  @media ${device.fromTablet} {
    padding: 0;
  }

  & > p {
    font-size: 11px;
    max-width: 300px;
    width: 100%;
    margin-top: 10px;

    @media ${device.fromTablet} {
      font-size: 14px;
      text-align: center;
      max-width: inherit;
    }
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-height: 200px;

  @media ${device.fromTablet} {
    justify-content: center;
  }
`;
