import React from 'react';
import PropTypes from 'prop-types';

import { ContentStyle } from './styles';

const Content = ({ children, ...props }) => (
  <ContentStyle {...props}>
    {children}
  </ContentStyle>
);

Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isFullMobile: PropTypes.bool,
  hasHeader: PropTypes.bool,
};

export default Content;
