import logger from 'redux-logger';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import immutableTransform from 'redux-persist-transform-immutable';

import reducers from './ducks';
import sagas from './sagas';

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  storage,
  whitelist: ['userArea'], // Temporário até a refatoração do Redux
};

const enhancedCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory({ basename: process.env.BASE });

const persistedReducer = persistReducer(persistConfig, reducers(history));

const middlewares = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}

// const sagaMonitor = process.env.NODE_ENV === 'development' ?
// console.tron.createSagaMonitor() : null;

const sagaMiddleware = createSagaMiddleware(/* { sagaMonitor } */);

middlewares.push(sagaMiddleware);

const store = createStore(persistedReducer, enhancedCompose(applyMiddleware(...middlewares)));

const persistor = persistStore(store);

if (module.hot) {
  module.hot.accept('./ducks', () => {
    // This fetch the new state of the above reducers.
    const nextRootReducer = require('./ducks');
    store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
  });
}

sagaMiddleware.run(sagas);

export default store;
export { persistor, history };
