import React from 'react';
import PropTypes from 'prop-types';

import { Container, Dot } from './styles';

const Dots = ({ dots }) => (
  <Container count={dots.length}>
    {dots.map(({ active, ...rest }, index) => (
      <Dot key={`dot-${index}`} active={active} {...rest} />
    ))}
  </Container>
);

Dots.propTypes = {
  dots: PropTypes.instanceOf(Object),
};

export default Dots;
