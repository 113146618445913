import React, { Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { colors } from '@triider-web/uikit';

import { Creators as UiCreators } from 'store/ducks/ui';

import { Creators as ServiceFormCreators } from 'store/ducks/serviceForm';
import {
  updateTriiderUserPhone,
  sendValidation,
  needValidatePin,
  isAuth,
  getTriiderUser,
} from 'reducers/userArea';
import { validateUserFields } from 'helpers/userValidations';
import { onlyNumbers, toPhone } from 'helpers/regex';

import InputText from 'components/InputText';
import Button from 'components/Button';
import Title from 'components/Title';
import Dots from 'components/Dots';
import iconPhone from 'static/img/icons/phone-orange.svg';
import iconAlert from 'static/img/icons/alert.svg';
import StepWrapper from '../StepWrapper';
import Modal from '../../../../containers/Modal';

import {
  HeaderWrapper,
  ContentWrapper,
  FooterWrapper,
  CenterContent,
  Radio,
  ContentModal,
  ContentButtonModal,
  PhoneNumber,
  ContainerConditional
} from './styles';

class ConfirmPhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      errors: {},
      withPin: true,
      fieldNumber: !this.props.phone,
      methodValidation: 'whatsapp',
    };

    this.inputRef = createRef();
    this.setFields = this.setFields.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handlePhoneInput = this.handlePhoneInput.bind(this);
  }

  async componentDidMount() {
    const { phone = '', _needValidatePin } = this.props;

    const withPin = await _needValidatePin();

    await this.setState({ phone: phone ? toPhone(phone) : phone, withPin });
  }

  setFields({ target }) {
    const { name, value } = target;

    this.setState({ [name]: value });
  }

  validateField({ target }) {
    const { name, value } = target;

    const message = validateUserFields(name, value);

    const { errors: oldErrors } = this.state;

    const errors = {
      ...oldErrors,
      [name]: message,
    };

    this.setState({ errors });
    this.setState({ fieldNumber: !!message })
  }

  handleValidation(event, goHome) {
    event.preventDefault();

    const { _replace } = this.props;

    if (goHome) {
      _replace('/');
      return;
    }

    const { phone: phoneMasked, errors: oldErrors } = this.state;

    const validation = { phone: validateUserFields('phone', phoneMasked) };

    const errors = Object.assign(oldErrors, validation);

    if (!Object.values(errors).some(e => !!e)) {
      const {
        _updateTriiderUserPhone,
        _sendValidation,
        viewType,
        _processTask,
      } = this.props;

      const hasTaskFinished = localStorage.getItem('hasTaskFinished');

      const { withPin } = this.state;

      const callback = withPin
        ? () => _sendValidation(false, this.state.methodValidation)
        : viewType === 'TASK'
          ? _processTask
          : () => _replace(viewType === 'REGISTER' && hasTaskFinished ? '/pedido-realizado' : '/');

      _updateTriiderUserPhone({ phone: onlyNumbers(phoneMasked) }, callback, !withPin && viewType === 'TASK');
    } else {
      this.setState({ errors });
    }
  }

  methodValidationChanged(method) {
    this.setState({ methodValidation: method });
  }

  handlePhoneInput() {
    this.setState({ fieldNumber: true });
    this.props.hideModal();

    const e = new Event('focus');
    this.inputRef.current.setFocus(e);
  }

  render() {
    const { isLoading, viewType, errorPhoneUpdate } = this.props;
    const { phone, errors, withPin } = this.state;
    const buttonDisabled = !phone || Object.values(errors).some(e => !!e);
    const dots = [{ active: false }, { active: false }, { active: true }];

    return (
      <Fragment>
        <StepWrapper
          noBorder
          onSubmit={this.handleValidation}
          backgroundContent={colors.transparent}
          backgroundSection={colors.transparent}
        >
          <ContentWrapper>
            <HeaderWrapper>
              {/* <img src={iconPhone} alt="Icone" /> */}
              {viewType === 'REGISTER' && <Dots dots={dots} />}
              <Title>
                {viewType === 'REGISTER' || viewType === 'TASK'
                  ? 'Só falta o seu telefone!'
                  : 'Confirme o seu telefone'}
              </Title>

              <p>
                Essa informação é restrita à nossa Central de Ajuda -
                não informamos o seu número para os profissionais.
              </p>
            </HeaderWrapper>

            <ContainerConditional displayContainer={!this.state.fieldNumber}>
              <InputText
                placeholder="Insira o seu número de celular:"
                placeholderAlign="center"
                ref={this.inputRef}
                type="text"
                name="phone"
                mask={[
                  '(',
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                value={phone}
                error={errors.phone}
                scrollView={false}
                onChange={this.setFields}
                onBlur={this.validateField}
                autoComplete="off"
              />
            </ContainerConditional>

            <ContainerConditional displayContainer={this.state.fieldNumber}>
              <p className='p-title'>Confira seu número de celular</p>
              <PhoneNumber>
                <div className='p-option'><b>{phone ? toPhone(phone) : ''}</b></div>
                <div onClick={() => this.handlePhoneInput()}><p className='p-option'>Trocar de número</p></div>
              </PhoneNumber>
            </ContainerConditional>

            <CenterContent>
              <p className="question">
                Como você gostaria de receber notificações?
              </p>
              <div>
                <label>
                  <Radio type="radio"
                    value="whatsapp"
                    checked={this.state.methodValidation === "whatsapp"}
                    onChange={() => this.methodValidationChanged("whatsapp")}
                    name="method"
                  /> <span>WhatsApp</span>
                </label>
                <br />
                <label>
                  <Radio type="radio"
                    value="sms"
                    checked={this.state.methodValidation === "sms"}
                    onChange={() => this.methodValidationChanged("sms")}
                    name="method"
                  /> <span>SMS</span>
                </label>
              </div>
            </CenterContent>
          </ContentWrapper>

          <FooterWrapper>
            <Button
              isLoading={isLoading}
              disabled={buttonDisabled}
              type="submit"
              size="block"
            >
              {withPin ? 'Próxima' : 'Confirmar telefone'}
            </Button>
            <p onClick={(e) => this.handleValidation(e, true)}>
              Não quero fazer isso agora
              </p>
          </FooterWrapper>
        </StepWrapper>

        <Modal isCentered>
          <ContentModal>
            <img src={iconAlert} alt="Icone" />
            {errorPhoneUpdate}
          </ContentModal>
          <ContentButtonModal>
            <Button size='small' outlined onClick={this.props.hideModal}>Fechar</Button>
            <Button size='small' onClick={() => this.handlePhoneInput()}>Trocar Número</Button>
          </ContentButtonModal>
        </Modal>
      </Fragment >
    );
  }
}

ConfirmPhone.propTypes = {
  isLoading: PropTypes.bool,
  errorPhoneUpdate: PropTypes.string,
  viewType: PropTypes.string,
  phone: PropTypes.string,
  hideModal: PropTypes.func,
  _sendValidation: PropTypes.func,
  _processTask: PropTypes.func,
  _updateTriiderUser: PropTypes.func,
  _needValidatePin: PropTypes.func,
  _replace: PropTypes.func,
};

const mapStateToProps = state => ({
  errorPhoneUpdate: state.ui.errorPhoneUpdate,
  isLoading: state.ui.loaders.includes('GLOBAL'),
  phone: state.userArea.get('userArea').toJS().triiderUser.user.phone,
  triiderUser: isAuth(state) ? getTriiderUser(state) : null,
  _needValidatePin: needValidatePin,
});

const mapDispatchToProps = {
  _sendValidation: sendValidation,
  _updateTriiderUserPhone: updateTriiderUserPhone,
  _processTask: ServiceFormCreators.processTask,
  _replace: replace,
  hideModal: UiCreators.hideModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmPhone);
