import styled, { css } from 'styled-components';
import { colors } from 'style';

const selectDefaultHeight = 45;
const selectSmalltHeight = 33;

const sizes = {
  default: {
    select: css`
      min-height: ${selectDefaultHeight}px;
      min-width: 200px;
      /* max-width: 400px; */
    `,
    after: css`
      bottom: ${selectDefaultHeight / 2}px;
      padding: 0 7px;
    `,
  },
  small: {
    select: css`
      min-height: ${selectSmalltHeight}px;
      min-width: 70px;
      max-width: 120px;
    `,
    after: css`
      bottom: ${selectSmalltHeight / 2}px;
      padding: 0 5px;
    `,
  },
};

export const SelectStyled = styled.label`
  position: relative;
  display: ${props => props.perfil ? 'grid' : 'inline-block'};
  margin: 0;
  margin-top: ${props => props.perfil ? '20px' : null};
  font-family: inherit;
  min-height: min-content;
  width: ${props => props.perfil ? '100%' : null};

  ${props => props.isFormField
    && css`
      width: 100%;
    `}

  & select {
    display: inline-block;
    padding: 5px 5px 5px 10px;
    font: ${ props => props.perfil ? 'normal' : 'inherit' };
    outline: none; /* remove focus ring from Webkit */
    line-height: 1.2;
    background: ${colors.white};
    color: ${colors.greyDark};
    appearance: none;
    border-radius: 2px;

    ${props => (props.isFormField
    ? css`
            min-height: ${selectDefaultHeight}px;
            max-width: none !important;
            width: 100%;
          `
    : sizes[props.size].select)}

    margin: 5px 0;
    width: 100%;

    border: 1px solid
      ${props => (props.hasError && colors.danger)
        || (props.focus && colors.primary)
        || (props.hasValue && colors.greyLight)
        || colors.greyLight};
  }

  &:after {
    content: '▼';
    position: absolute;
    right: 0;
    line-height: 10px;
    font-size: 16px;
    pointer-events: none;
    border-radius: 0 6px 6px 0;
    color: ${colors.primary};

    ${props => sizes[props.size].after}
  }
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  max-width: 90%;

  color: ${props => (props.hasError && colors.danger) || (props.focus && colors.primary) || colors.greyDark};
`;

export const InputStatus = styled.span`
  font-size: 14px;
  color: ${props => (props.hasError && colors.danger) || (props.success && colors.success) || colors.greyDark};
`;
