import { colors } from './base/colors';
import { device } from './base/device';
import { fonts } from './base/fonts';
import { flex } from './base/flex';
import { slides } from './base/slides';
import { sizes } from './base/sizes';
import { fields } from './base/fields'

export {
  colors, 
  device, 
  fonts, 
  flex, 
  slides, 
  sizes, 
  fields
};
