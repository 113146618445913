/* global FB */

import axios from 'axios';
import _get from 'lodash/get';

export const loginWithFacebook = token => new Promise((resolve, reject) => {
  const url = `${process.env.API_URL}/login/facebook`;
  const body = { accessToken: token };

  axios
    .post(url, body)
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const userExists = email => new Promise((resolve, reject) => {
  const url = `${process.env.API_URL}/user/exists/${email}`;

  axios
    .get(url)
    .then((response) => {
      const { exists } = response.data;
      return response.data.success ? resolve(exists) : reject();
    })
    .catch(error => reject(error));
});

export const resetPassword = email => axios.post(`${process.env.API_URL}/users/password-reset`, { email });
export const checkTokenPassword = (email, token) => axios.get(`${process.env.API_URL}/users/password-reset/${token}/${email}`);
export const updatePassword = (email, token, password) => axios.put(`${process.env.API_URL}/users/password-reset`, { email, token, password });

export const doLogin = user => new Promise((resolve, reject) => {
  const url = `${process.env.API_URL}/login`;

  axios
    .post(url, user)
    .then(response => resolve(response.data))
    .catch(error => reject(error));
});

export const openFacebook = (callback) => {
  FB.login(
    (response) => {
      if (response.authResponse) {
        callback(null, response.authResponse);
      } else {
        callback('Usuario cancelou');
      }
    },
    { scope: 'public_profile,email' },
  );
};

export const parseFacebookUser = (user, token) => ({
  name: user.name,
  email: user.email,
  facebook_id: user.id,
  gender: user.gender,
  profile_picture: _get(user, 'picture.data.url'),
  accessToken: token,
  isSocialLogin: true,
});

export const openFB = scope => new Promise((resolve, reject) => {
  FB.login((response) => {
    const auth = response.authResponse;

    return auth ? resolve(auth) : reject('Usuario cancelou');
  }, scope);
});
