import React from 'react';
import PropTypes from 'prop-types';

import { FlexContainer } from 'components/Container';

import LayoutCard from 'containers/LayoutCard';

import { Form } from './styles';

class StepWrapper extends React.Component {
  componentDidMount() {
    if (document.getElementsByClassName('bottom-fixed')[0]) document.getElementsByClassName('bottom-fixed')[0].style.display = 'none';
    if (document.getElementsByClassName('footer')[0]) document.getElementsByClassName('footer')[0].style.display = 'none';
  }

  render() {
    const { onSubmit, children, isModal, noBorder=false, backgroundContent, backgroundSection  } = this.props;
    return (
      <LayoutCard isModal={isModal} isFullMobile noBorder={noBorder} backgroundContent={backgroundContent} backgroundSection={backgroundSection}>
        <Form onSubmit={onSubmit}>{children}</Form>
      </LayoutCard>
    );
  }
}

StepWrapper.propTypes = {
  noBorder: PropTypes.bool,
  onSubmit: PropTypes.func,
  isModal: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default StepWrapper;
