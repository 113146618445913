const INITIAL_STATE = {    
  rating: 0,
  comment: '',
  isAnonymous: false,
  labels: {}
};

/**
 * Types
 */
export const Types = {
  GET_RATING_LABEL_REQUEST: 'rating/GET_RATING_LABEL_REQUEST',
  GET_RATING_LABEL_SUCCESS: 'rating/GET_RATING_LABEL_SUCCESS',
  SET_RATING_REQUEST: 'rating/SET_RATING_REQUEST',
  SET_RATING_SUCCESS: 'rating/SET_RATING_SUCCESS',
};

/**
 * Reducer
 */
export default function rating(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_RATING_LABEL_SUCCESS: {      
      const newRating = action.payload.rating;
      const newLabels = {
        ...state.labels,
        [newRating]: action.payload.labels
      }
      return { ...state, labels: newLabels };
    }
    case Types.SET_RATING_SUCCESS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

/**
 * Actions Creators
 */
export const Creators = {
  getRatingLabelRequest: payload => ({
    type: Types.GET_RATING_LABEL_REQUEST,
    payload,
  }),
  getRatingLabelSuccess: payload => ({
    type: Types.GET_RATING_LABEL_SUCCESS,
    payload,
  }),
  setRatingRequest: payload => ({
    type: Types.SET_RATING_REQUEST,
    payload,
  }),
  setRatingSuccess: payload => ({
    type: Types.SET_RATING_SUCCESS,
    payload,
  }),
};
