import api from './index';

export const apiSendTask = async (task) => {
  try {
    const { data } = await api.post('/tasks', { task });
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiGetProProfile = async (proId) => {
  try {
    const { data } = await api.get(`/business/profile/${proId}`);

    if (!data.success) {
      throw new Error();
    }
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiGetQuoteById = async (quoteId) => {
  try {
    const { data } = await api.get(`/quote/${quoteId}`);
    if (!data.success) {
      throw new Error();
    }
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiGetTaskById = async (taskId) => {
  try {
    const { data } = await api.get(`/task/${taskId}`);
    if (!data.success) {
      throw new Error();
    }
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiGetCouponById = async (couponId) => {
  const { data } = await api.get(`/coupons/${couponId}`);
  if (!data.success) {
    throw new Error();
  }
  return data;
};

export const apiGetTaskInstallments = async (amount) => {
  const { data } = await api.post('/util/installments', { amount });
  if (!data.success) {
    throw new Error();
  }
  return data;
};

export const apiSetTaskInstallments = async (taskId, installments) => {
  const { data } = await api.patch(`/task/${taskId}/set-installment`, { installments });
  if (!data.success) {
    throw new Error();
  }
  return true;
};

export const apiRedeemCoupon = async (code) => {
  const { data } = await api.post('/coupons/redeem', { code });
  if (!data.success) {
    const error = errMsg(data.error);
    throw new Error(error);
  }
  return data;
};

export const apiSetTaskCredits = async (task_id, amount) => {
  const { data } = await api.post(`/tasks/${task_id}/credits`, { amount });
  if (!data.success) {
    throw new Error();
  }
  return true;
};

export const apiSetTaskCoupon = async (task_id, coupon_id) => {
  const { data } = await api.patch(`/task/${task_id}`, { coupon_id });
  if (!data.success) {
    throw new Error();
  }
  return true;
};

export const apiAuthorizeTaskQuote = async (quote) => {
  const { data } = await api.post(`/tasks/${quote.task_id}/hirings`, { quote_id: quote.id });
  if (!data.success) {
    throw new Error(data.error);
  }
  return true;
};

export const apiSetQuoteSelected = async (quote) => {
  const { data } = await api.patch(`/quote/${quote.id}/set-selected`, {});
  if (!data.success) {
    throw new Error(data.error);
  }
  return true;
};

export const apiNotifyTaskClose = async (user, quote) => {
  const body = {
    user_id: user.id,
    quote_id: quote.id,
    chat_id: quote.chat_id,
    send_message: false,
    deal_message: 'Negócio fechado, podemos combinar o atendimento do serviço aqui pelo chat.',
    nodeal_message: 'Negócio fechado com outro prestador.',
  };

  const { data } = await api.patch(`/task/${quote.task_id}/close/notify`, body);
  if (!data.success) {
    throw new Error(data.error);
  }
  return true;
};

export const apiSetTaskCheckoutPayment = async (taskId, card) => {
  const { data } = await api.put(`/task/${taskId}/set-payment`, {
    payment: { card_id: card.id, gateway: card.gateway },
  });
  if (!data.success) {
    throw new Error(data.error);
  }
  return true;
};

export const apiGetBusinessTask = async (task) => {
  const { data } = await api.get(`/businessTask/${task.selected_quote_user_id}/${task.id}`);
  if (!data.success) {
    throw new Error(data.error);
  }
  return data;
};

export const apiGetBusinessTaskPaid = async (businessTask) => {
  Object.assign(businessTask, { is_paid: true, paid_date: new Date() });

  const { data } = await api.put(`/businessTask/${businessTask.id}`, { businessTask });
  if (!data.success) {
    throw new Error(data.error);
  }
  return true;
};

export const apiSetTaskPaid = async (task) => {
  const { data } = await api.post(`/tasks/${task.id}/payments`, {});
  if (!data.success) {
    throw new Error(data.error);
  }
  return data;
};

export const apiNotifyTaskPayment = async (task) => {
  const { data } = await api.post(`/business-task/notify-payment/${task.id}`, {});
  if (!data.success) {
    throw new Error(data.error);
  }
  return data;
};

export const apiGetTasksByUser = async (user_id) => {
  const { data } = await api.get(`/task/${user_id}/list-tasks`);
  if (!data.success) {
    throw new Error(data.error);
  }
  return data;
};
