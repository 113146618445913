/* eslint-disable max-len */

import ptBR from 'date-fns/locale/pt-BR';
import { format, formatDistanceStrict } from 'date-fns';

export default (date, formatStyle) => format(date, formatStyle, {
  locale: ptBR,
});

export const formatDistanceWithLocale = (date, formatStyle) => formatDistanceStrict(date, formatStyle, {
  locale: ptBR,
});
