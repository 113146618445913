import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

/* OLD REDUCERS */
import userArea from '../../reducers/userArea';
import unsubscribe from '../../reducers/unsubscribe';
import auth from '../../reducers/auth';
/* OLD REDUCERS END */

import ui from './ui';
import serviceForm from './serviceForm';
import chat from './chat';
import task from './task';
import rewards from './rewards';
import settings from './settings';
import coupons from './coupons';
import rating from './rating';

const reducer = history => combineReducers({
  router: connectRouter(history),
  ui,
  serviceForm,
  chat,
  task,
  rewards,
  settings,
  coupons,
  rating,

  /* OLD REDUCERS */
  userArea,
  unsubscribe,
  auth,
  /* OLD REDUCERS END */
});

export default reducer;
