import routes from 'config/routes';

export const getErrorMessage = ({ response }) => {
  let message = 'Ops! Ocorreu um problema no sistema';
  console.log(response);
  if (response) {
    const {
      data: { error },
    } = response;
    if (error) {
      message = error;
    }
  }
  return message;
};

export function replaceMGMParams(value, settings, code) {
  if (value && settings) {
    const {
      amount_given, amount_received, amount_given_text, amount_received_text,
    } = settings;
    const url = `${process.env.SITE_PATH}/u${routes.mgmValidator(code)}`;
    return value
      .replace(/\n/g, '\r\n')
      .replace(/:url/g, `${url}`)
      .replace(/:amount_given_text/g, amount_given_text)
      .replace(/:amount_received_text/g, amount_received_text)
      .replace(/:amount_given/g, amount_given)
      .replace(/:amount_received/g, amount_received);
  }
  return '';
}
