import {
  call, put, all, select,
} from 'redux-saga/effects';

import { apiValidateCouponByCode, apiRedeemCouponToUser } from 'services/api/coupons';

import { getErrorMessage } from 'utils/filters';
import { errMsg as couponErrorMsg } from '../../reducers/userArea/helpers/messages';

/* Actions */
import { creatorsCupomAddCupom, getCoupouns } from '../../reducers/userArea';
import { Creators as CouponsCreators } from '../ducks/coupons';
import { Creators as UiCreators, growlTypes } from '../ducks/ui';

export function* validateCoupons({ payload }) {
  try {
    const { coupon_id } = yield call(apiValidateCouponByCode, payload);

    yield put(CouponsCreators.validateCouponsSuccess(coupon_id));
  } catch (error) {
    const errorFilted = getErrorMessage(error);
    const message = couponErrorMsg(errorFilted);
    yield all([
      put(UiCreators.growl(message, growlTypes.GROWL_ERROR)),
      put(CouponsCreators.validateCouponsError(message)),
    ]);
  }
}

export function* redeemCouponToUser({ payload }) {
  try {
    const oldCoupouns = yield select(getCoupouns);

    const { coupon } = yield call(apiRedeemCouponToUser, payload.idCoupon, payload.userId);
    yield all([
      put(CouponsCreators.redeemCouponsSuccess(coupon)),
    ]);
    const check = oldCoupouns.some(_coupon => _coupon.id === coupon.id);
    if (!check) {
      yield all([
        put(UiCreators.growl('Cupom cadastrado com sucesso!', growlTypes.GROWL_SUCCESS)),
        put(creatorsCupomAddCupom(oldCoupouns, coupon)),
      ]);
    } else {
      yield put(UiCreators.growl('Cupom já resgatado!', growlTypes.GROWL_SUCCESS));
    }
  } catch (error) {
    const errorFilted = getErrorMessage(error);
    const message = couponErrorMsg(errorFilted);
    yield all([
      put(UiCreators.growl(message, growlTypes.GROWL_ERROR)),
      put(CouponsCreators.validateCouponsError(message)),
    ]);
  }
}
