import React from 'react';
import PropTypes from 'prop-types';

import Section from '../../components/Section';
import Container, { ContainerHeader, ContainerContent } from '../../components/Container';

class LayoutCard extends React.Component {
  render() {
    const {
      children,
      title,
      subtitle,
      contentRight,
      isFullMobile,
      headerCentered,
      headerDesktop,
      isModal,
      containerHeightDesktop,
      containerWidthDesktop,
      noBorder,
      backgroundContainter,
      backgroundContent,
      backgroundSection,
    } = this.props;
    const hasHeader = !!title || !!subtitle || !!contentRight;

    return (
      <Section isModal={isModal} centered isFullMobile={isFullMobile} background={backgroundSection}>
        <Container
          isFullMobile={isFullMobile}
          containerHeightDesktop={containerHeightDesktop}
          containerWidthDesktop={containerWidthDesktop}
          background={backgroundContainter}
        >
          {hasHeader && (
            <ContainerHeader
              title={title}
              subtitle={subtitle}
              centered={headerCentered}
              contentRight={contentRight}
              headerDesktop={headerDesktop}
            />
          )}
          <ContainerContent
            hasHeader={hasHeader && !headerDesktop}
            isFullMobile={isFullMobile}
            containerHeightDesktop={containerHeightDesktop}
            containerWidthDesktop={containerWidthDesktop}
            noBorder={noBorder}
            background={backgroundContent}
          >
            {children}
          </ContainerContent>
        </Container>
      </Section>
    );
  }
}

LayoutCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  contentRight: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isFullMobile: PropTypes.bool,
  isModal: PropTypes.bool,
  headerCentered: PropTypes.bool,
  headerDesktop: PropTypes.bool,
};

export default LayoutCard;
