import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';

import { ButtonStyled, IconLeft, IconRight } from './styles';

const Button = ({
  children,
  size = 'medium',
  background = 'primary',
  color = 'white',
  marginTop,
  disabled,
  outlined,
  inHeader,
  border,
  iconLeft,
  iconRight,
  iconDisabled,
  isLoading,
  ...props
}) => {
  const textColor = outlined ? background : color;
  return (
    <ButtonStyled
      inHeader={inHeader}
      disabled={disabled || isLoading}
      size={size}
      background={outlined ? color : background}
      color={textColor}
      border={outlined ? background : border}
      isLoading={isLoading}
      marginTop={marginTop}
      {...props}
    >
      {isLoading ? (
        <Spinner size="small" color={textColor} />
      ) : (
        <Fragment>
          {!!iconLeft && <IconLeft src={iconLeft} alt="Icone" />}
          {children}
          {!!iconRight && <IconRight src={disabled ? iconDisabled : iconRight} alt="Icone" />}
        </Fragment>
      )}
    </ButtonStyled>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  size: PropTypes.string,
  background: PropTypes.string,
  border: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  outlined: PropTypes.bool,
  isLoading: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconDisabled: PropTypes.string,
  props: PropTypes.instanceOf(Array),
};

export default Button;
