import styled, { css } from 'styled-components';
import { device } from 'style';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  max-height: 100px;
  flex: 1;
  width: 100%;
  max-height: max-content;
  margin-top: 30px;

  & h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before {
      content: 'Suas informações estão seguras!';

      @media ${device.fromTablet} {
        content: 'Essas informações são privadas e só serão utilizadas internamente para entendermos quem são os clientes do Triider! :)';
        font-size: 16px;
      }
    }

    & > :first-child {
      margin-left: 10px;
    }
  }

  & > :first-child {
    display: none;
  }

  @media ${device.fromTablet} {
    align-items: center;
    justify-content: space-between;
    max-height: 150px;
    margin-top: 20px;

    & > :first-child {
      display: flex;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  max-width: 400px;
  padding: 0 1rem;
  max-height: 400px;

  @media ${device.fromTablet} {
    padding: 0;
  }

  & > p {
    font-size: 11px;
    max-width: 300px;
    width: 100%;
    margin-top: 10px;

    @media ${device.fromTablet} {
      font-size: 14px;
      text-align: center;
      max-width: inherit;
    }
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @media ${device.fromTablet} {
    justify-content: center;
  }
`;
