import { call, put, all } from 'redux-saga/effects';

import { getParameter } from 'services/api/helpers';

import { getErrorMessage } from 'utils/filters';

import { Creators as SettingsCreators } from '../ducks/settings';
import { Creators as UiCreators, growlTypes } from '../ducks/ui';

const fileName = 'sagas/settings';

export function* getBanners({ payload }) {
  try {
    yield put(UiCreators.showLoader('GET-BANNERS'));

    const { parameter } = yield call(getParameter, 'BANNER-COVID-19');
    const banners = {
      bannerCovid19: JSON.parse(parameter.value),
    };

    yield put(SettingsCreators.getBannersSuccess(banners));
    yield put(UiCreators.hideLoader('GET-BANNERS'));
  } catch (error) {
    const message = getErrorMessage(error);
    console.log('settings > getBanners >> ', message);
    yield all([put(UiCreators.hideLoader('GET-BANNERS'))]);
  }
}
