import styled from 'styled-components';
import { colors } from 'style';

export const ButtonClose = styled.button`
  padding: 0.5rem 1rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background: none;
  border: none;
  color: ${colors.greyDark};

  & span {
    font-size: 1.5rem;
    font-weight: 700;
    text-shadow: 0 1px 0 ${colors.white};
  }
`;
