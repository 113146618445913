import { createAction } from 'redux-actions';
import { push, replace } from 'connected-react-router/immutable';

import {
  userExists,
  doLogin,
  openFB,
  loginWithFacebook,
  parseFacebookUser,
  resetPassword,
  updatePassword,
  checkTokenPassword,
} from 'helpers/auth';

import { initializeGA, gaSendEvent } from 'helpers/analytics';

import { changePassword, updateUserPhoto, authPartner, createUser, apiValidateEmail } from 'services/api/auth';

import { identifyUser } from 'helpers/pipz';
import { Creators as UiCreators, growlTypes } from 'store/ducks/ui';
import { Creators as ServiceFormCreators } from 'store/ducks/serviceForm';
import { fetchTriiderUser, getTriiderUser, updateTriiderUser, setUserFields, isAuth } from '../userArea';

export const SET_TOKEN_VALID = createAction('AUTH/SET_TOKEN_VALID');
export const SET_EMAIL_TOKEN_VALID = createAction('AUTH/SET_EMAIL_TOKEN_VALID');
export const SET_EMAIL = createAction('AUTH/SET_EMAIL');
export const SET_NEW_PASSWORD = createAction('AUTH/SET_NEW_PASSWORD');
export const SET_USER_SOCIAL_MEDIA = createAction('AUTH/SET_USER_SOCIAL_MEDIA');
export const RESET_INITIAL_STATE = createAction('AUTH/RESET_INITIAL_STATE');
export const SET_CHANGE_PASSWORD = createAction('AUTH/SET_CHANGE_PASSWORD');
export const SET_USER_EDIT = createAction('AUTH/SET_USER_EDIT');
export const SET_USER_PHOTO = createAction('AUTH/SET_USER_PHOTO');
export const AUTH_PARTNER = createAction('AUTH/AUTH_PARTNER');

const resetAuthReducer = () => (dispatch) => {
  dispatch(RESET_INITIAL_STATE());
};

const triiderLogin = (email, password) => (dispatch) => {
  dispatch(UiCreators.showLoader());

  userExists(email)
    .then(() => {
      doLogin({ email, password })
        .then(async (data) => {
          if (data.success) {
            if (JSON.parse(localStorage.getItem('userInvitation'))) {
              initializeGA();
              gaSendEvent('cadastro_indicação', 'login');
              localStorage.removeItem('userInvitation');
            }
            await dispatch(fetchTriiderUser(data));
            dispatch(ServiceFormCreators.processTask({ triiderUser: data }));
            identifyUser(data); // PIPZ
          } else {
            dispatch(UiCreators.growl('Usuário e/ou senha inválidos.', growlTypes.GROWL_ERROR));
          }
          dispatch(UiCreators.hideLoader());
        })
        .catch((error) => {
          dispatch(UiCreators.growl('Usuário e/ou senha inválidos.', growlTypes.GROWL_ERROR));
          dispatch(UiCreators.hideLoader());
        });
    })
    .catch(() => {
      dispatch(UiCreators.growl('E-mail não cadastrado', growlTypes.GROWL_ERROR));
      dispatch(UiCreators.hideLoader());
    });
};

export const partnerLogin = (partner, token) => async (dispatch) => {
  try {
    const data = await authPartner(partner, token);
    const { company_info } = data;
    if (data.success) {
      await dispatch(fetchTriiderUser(data));
    }
    window.location.href = `${process.env.SITE_PATH}/p/${company_info.source}`;
  } catch (error) {
    console.log('partnerl login error >> ', error);
    window.location.href = `${process.env.SITE_PATH}/p/${partner}`;
  }
};

const triiderLogout = () => (dispatch) => {
  dispatch(fetchTriiderUser(null));
  // clearUser();
};

const FB_SCOPE = { scope: 'public_profile, email' };

// const createFacebookUser = user => (dispatch) => {
//   dispatch(UiCreators.showLoader());

//   createUser({ user })
//     .then(data => dispatch(fetchTriiderUser(data)))
//     .catch(() => {
//       dispatch(UiCreators.growl('Erro ao criar usuário', growlTypes.GROWL_ERROR));
//       dispatch(UiCreators.hideLoader());
//     });
// };

const facebookLogin = () => (dispatch) => {
  dispatch(UiCreators.showLoader());

  openFB(FB_SCOPE)
    .then((response) => {
      loginWithFacebook(response.accessToken).then(async (data) => {
        if (data.success && data.token) {
          await dispatch(fetchTriiderUser(data));
          identifyUser(data); // PIPZ
          dispatch(ServiceFormCreators.processTask({ triiderUser: data }));
          dispatch(UiCreators.hideLoader());
        } else if (data.success && !data.token) {
          dispatch(SET_USER_SOCIAL_MEDIA(parseFacebookUser(data.user)));
          dispatch(UiCreators.hideLoader());
          dispatch(push('/cadastro/conta'));
        } else {
          dispatch(UiCreators.growl('Ocorreu um erro ao criar usuário', growlTypes.GROWL_ERROR));
          dispatch(UiCreators.hideLoader());
        }
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch(UiCreators.growl('Ocorreu um erro ao criar usuário', growlTypes.GROWL_ERROR));
      dispatch(UiCreators.hideLoader());
    });
};

const clearForgotPassword = () => (dispatch) => {
  dispatch(SET_EMAIL(''));
  dispatch(SET_TOKEN_VALID(false));
  dispatch(SET_NEW_PASSWORD(false));
};

const forgotPasswordSendEmail = (email, resend = false) => (dispatch) => {
  if (!resend) dispatch(clearForgotPassword());
  dispatch(UiCreators.showLoader());
  resetPassword(email)
    .then(({ data: response }) => {
      if (response.success) {
        if (resend) {
          dispatch(UiCreators.growl('E-mail reenviado!', growlTypes.GROWL_SUCCESS));
          dispatch(UiCreators.hideLoader());
        } else {
          dispatch(SET_NEW_PASSWORD(false));
          dispatch(SET_EMAIL(email));
          dispatch(push('/confirmacao'));
          dispatch(UiCreators.hideLoader());
        }
      } else {
        // ERRO?
        dispatch(SET_EMAIL(''));
        dispatch(
          UiCreators.growl('Ocorreu um erro ao enviar a nova senha', growlTypes.GROWL_SUCCESS),
        );
        dispatch(UiCreators.hideLoader());
      }
    })
    .catch((error) => {
      console.log(error);

      dispatch(SET_EMAIL(''));

      dispatch(UiCreators.growl('Ocorreu um erro ao enviar a nova senha', growlTypes.GROWL_ERROR));
      dispatch(UiCreators.hideLoader());
    });
};

const forgotPasswordCheckToken = (email, token) => (dispatch) => {
  dispatch(clearForgotPassword());
  dispatch(UiCreators.showLoader());

  checkTokenPassword(email, token)
    .then(({ data: response }) => {
      if (response.success) {
        dispatch(SET_TOKEN_VALID(true));
      } else {
        dispatch(SET_TOKEN_VALID(false));
      }
      dispatch(UiCreators.hideLoader());
    })
    .catch((error) => {
      console.log(error);

      dispatch(SET_TOKEN_VALID(false));
      dispatch(UiCreators.hideLoader());
    });
};

const updateUserData = (photo, userState) => async (dispatch, getState) => {

  dispatch(UiCreators.showLoader());
  const user = getTriiderUser(getState()).user;
  const newUser = Object.assign({...userState, updated_image: false}, { user });
  delete newUser.address;

  try {
    if (photo) {
      const response = await updateUserPhoto(photo);
      if (response.success) {
        const profile_picture = response?.imageUrl;
        newUser.updated_image = true;
        Object.assign(newUser, { profile_picture });  
        dispatch(SET_USER_PHOTO(profile_picture));
      }
      else {
        dispatch(UiCreators.hideLoader());
        dispatch(UiCreators.growl('Falha ao enviar foto, tente novamente', growlTypes.GROWL_ERROR));        
        return;
      }
    }

    dispatch(updateTriiderUser(newUser, () => {dispatch(UiCreators.growl('Dados Alterados com Sucesso!', growlTypes.GROWL_SUCCESS));}));
  }
  catch { 
    dispatch(UiCreators.hideLoader());
    dispatch(UiCreators.growl('Ocorreu um erro, tente novamente', growlTypes.GROWL_ERROR));
  }
}

const changeUserPassword = (password, newPassword) => (dispatch) => {
  dispatch(UiCreators.showLoader());

  changePassword(password, newPassword)
    .then((response) => {
      if (response.success) {
        dispatch(SET_CHANGE_PASSWORD(true));
        dispatch(UiCreators.growl('Senha alterada com Sucesso!', growlTypes.GROWL_SUCCESS));
        setTimeout(() => {
          dispatch(push('/'));
        }, 2000);
      }
      dispatch(UiCreators.hideLoader());
    })
    .catch((response) => {
      if (!response.success) {
        dispatch(SET_CHANGE_PASSWORD(true));
        dispatch(UiCreators.growl('O campo "Senha Atual" está incorreto.', growlTypes.GROWL_ERROR));
      } else {
        dispatch(UiCreators.growl('Ocorreu um erro, tente novamente', growlTypes.GROWL_ERROR));
      }
      dispatch(UiCreators.hideLoader());
    });
};

const forgotPasswordSendPassword = (email, token, password) => (dispatch) => {
  dispatch(UiCreators.showLoader());

  updatePassword(email, token, password)
    .then(({ data: response }) => {
      if (response.success) {
        dispatch(SET_NEW_PASSWORD(true));
        dispatch(triiderLogin(email, password));
        dispatch(push('/confirmacao'));
      } else {
        dispatch(SET_NEW_PASSWORD(false));
        dispatch(UiCreators.growl('Ocorreu um erro ao criar a nova senha', growlTypes.GROWL_ERROR));
        dispatch(UiCreators.hideLoader());
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(SET_NEW_PASSWORD(false));
      dispatch(UiCreators.growl('Ocorreu um erro ao criar a nova senha', growlTypes.GROWL_ERROR));
      dispatch(UiCreators.hideLoader());
    });
};

const validateEmail = (email, token) => (dispatch, getState) => {
  dispatch(UiCreators.showLoader('GLOBAL'));

  apiValidateEmail(email, token)
    .then((response) => {
      if (response.success && response.valid) {
        dispatch(SET_EMAIL_TOKEN_VALID(true));
        if (isAuth(getState())) {
          dispatch(setUserFields({ flg_email_validated: true }));
        }
        dispatch(UiCreators.hideLoader('GLOBAL'));
      } else {
        dispatch(SET_EMAIL_TOKEN_VALID(false));
        dispatch(setUserFields({ flg_email_validated: false }));
        // dispatch(UiCreators.growl('Ocorreu um erro ao validar o e-mail', growlTypes.GROWL_ERROR));
        dispatch(UiCreators.hideLoader('GLOBAL'));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(SET_EMAIL_TOKEN_VALID(false));
      dispatch(setUserFields({ flg_email_validated: false }));
      // dispatch(UiCreators.growl('Ocorreu um erro ao validar o e-mail', growlTypes.GROWL_ERROR));
      dispatch(UiCreators.hideLoader('GLOBAL'));
    });
};

const triiderRegisterAccount = userInfo => (dispatch) => {
  dispatch(UiCreators.showLoader());

  const prev_city = JSON.parse(localStorage.getItem('prev_city')) || {};

  const {
    state_id, state_name, state_initials, name: city_name, id: city_id,
  } = prev_city;

  Object.assign(userInfo, {
    state_id,
    state_name,
    state_initials,
    city_name,
    city_id,
  });

  userExists(userInfo.email)
    .then((exist) => {
      if (exist) {
        dispatch(UiCreators.hideLoader());
        dispatch(UiCreators.growl('E-mail já registrado!', growlTypes.GROWL_ERROR));
      } else {
        createUser({ user: userInfo })
          .then(async (data) => {
            if (data.success) {
              dispatch(UiCreators.hideLoader());
              dispatch(UiCreators.routeRedirect('/cadastro/perfil'));
              dispatch(SET_USER_SOCIAL_MEDIA({}));
              identifyUser(data); // PIPZ
              await dispatch(fetchTriiderUser(data));
              dispatch(ServiceFormCreators.processTask({ triiderUser: data, isRegister: true }));
            } else {
              dispatch(UiCreators.hideLoader());
              dispatch(UiCreators.growl('Ops! Ocorreu um erro', growlTypes.GROWL_ERROR));
            }
          })
          .catch((err) => {
            console.log(err);
            dispatch(UiCreators.hideLoader());
            dispatch(UiCreators.growl('Ops! Ocorreu um erro', growlTypes.GROWL_ERROR));
          });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(UiCreators.hideLoader());
      dispatch(UiCreators.growl('Ops! Ocorreu um erro', growlTypes.GROWL_ERROR));
    });
};

export {
  triiderLogin,
  triiderLogout,
  facebookLogin,
  forgotPasswordSendEmail,
  forgotPasswordCheckToken,
  updateUserData,
  changeUserPassword,
  forgotPasswordSendPassword,
  clearForgotPassword,
  triiderRegisterAccount,
  resetAuthReducer,
  validateEmail,
};
