import api from './index';

export const apiSaveLead = async (lead) => {
  try {
    const { data } = await api.post('/form-lead', lead);
    if (!data.success) {
      throw new Error('Ops! Ocorreu um erro no sistema.');
    }
    return data;
  } catch (error) {
    throw error;
  }
};
