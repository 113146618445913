const INITIAL_STATE = {
  serviceId: null,
  tagId: null,
  source: 'web',
  open: null,
  close: null,
  onChange: null,
  onFinish: null,
  answers: null,
  isOpen: false,
  finished: false,
  analytics: {},
  states: [],
};

/**
 * Types
 */
export const Types = {
  CHANGE_FORM: 'serviceForm/CHANGE',
  FINISH_FORM_REQUEST: 'serviceForm/FINISH_FORM_REQUEST',
  FINISH_FORM_SUCCESS: 'serviceForm/FINISH_FORM_SUCCESS',
  OPEN_FORM: 'serviceForm/OPEN',
  CLOSE_FORM: 'serviceForm/CLOSE',
  PROCESS_TASK: 'serviceForm/PROCESS_TASK',
  GET_UFS: 'address/getUfs',
  SAVE_LEAD: 'serviceForm/SAVE_LEAD',
};

const clearSessionAndDispatchEvent = () => {
  sessionStorage.removeItem('service-form-params');
  window.dispatchEvent(new Event('new-service-form'));
};

/**
 * Reducer
 */
export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CHANGE_FORM:
      return { ...state, answers: action.payload };
    case Types.FINISH_FORM_SUCCESS:
      clearSessionAndDispatchEvent();
      return INITIAL_STATE;
    case Types.OPEN_FORM:
      return { ...INITIAL_STATE, ...action.payload };
    case Types.CLOSE_FORM:
      return INITIAL_STATE;
    case Types.GET_UFS:
      return { ...INITIAL_STATE, ...action.payload };
    default:
      return state;
  }
}

/**
 * Actions Creators
 */
export const Creators = {
  changeForm: payload => ({
    type: Types.CHANGE_FORM,
    payload,
  }),

  finishFormRequest: payload => ({
    type: Types.FINISH_FORM_REQUEST,
    payload,
  }),

  finishFormSuccess: () => ({
    type: Types.FINISH_FORM_SUCCESS,
  }),

  processTask: payload => ({
    type: Types.PROCESS_TASK,
    payload,
  }),

  openForm: payload => ({
    type: Types.OPEN_FORM,
    payload,
  }),

  closeForm: () => ({
    type: Types.CLOSE_FORM,
  }),

  getUds: payload => ({
    type: Types.GET_UFS,
    payload,
  }),
  saveLead: payload => ({
    type: Types.SAVE_LEAD,
    payload,
  }),
};
