import _padStart from 'lodash/padStart';
import api from './index';

export const updateUser = (fields, userId) => new Promise((resolve, reject) => {
  api
    .put(`/user/${userId}`, { user: fields })
    .then(({ data }) => resolve(data))
    .catch(({ response }) => reject(response));
});

export const updatePhone = (fields, userId) => new Promise((resolve, reject) => {
  api
    .put(`/phone-validation/${userId}`, { phone: { number: fields.phone } })
    .then(({ data }) => resolve(data))
    .catch(({ response }) => reject(response));
});

export const sendPinValidation = (userId, method) => new Promise((resolve, reject) => {
  api
    .post('/phone-validation', { user_id: userId, send_method: method })
    .then(({ data }) => resolve(data))
    .catch(({ response }) => reject(response));
});

export const pinValidation = (userId, pin) => new Promise((resolve, reject) => {
  api
    .patch('/phone-validation', { user_id: userId, pin })
    .then(({ data }) => resolve(data))
    .catch(({ response }) => reject(response));
});

export const apiFavoriteUser = async (user_id, business_user_id) => {
  try {
    const { data } = await api.post(`/user/${user_id}/favorite/${business_user_id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
export const apiDisfavorUser = async (user_id, business_user_id) => {
  try {
    const { data } = await api.delete(`/user/${user_id}/favorite/${business_user_id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
export const apiGetUser = async (user_id) => {
  try {
    const { data } = await api.get(`/user/${user_id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiGetUserFavorite = async (user_id, business_user_id) => {
  try {
    const { data } = await api.get(`/user/${user_id}/favorite-satus/${business_user_id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiGetUserCreditCards = async (user_id) => {
  const { data } = await api.get(`users/${user_id}/cards`);

  if (!data.success) {
    throw new Error();
  }
  const creditCards = data.cards.map(c => ({
    ...c,
    validThru: `${_padStart(c.month, 2, '0')}/${c.year}`,
  }));

  return { creditCards };
};

export const apiSaveCreditCard = async (user_id, cardToSave) => {
  const splittedDate = cardToSave.validThru.split('/');
  Object.assign(cardToSave, {
    month: splittedDate[0],
    year: `${new Date()
      .getFullYear()
      .toString()
      .substr(0, 2)}${splittedDate[1]}`,
  });

  const { data } = await api.post(`/users/${user_id}/cards`, cardToSave);

  if (!data.success) {
    throw new Error();
  }
  const { card } = data;
  Object.assign(card, {
    validThru: `${_padStart(card.month, 2, '0')}/${card.year}`,
  });
  return { card };
};

export const apiGetCardById = async (cardId) => {
  try {
    const { data } = await api.get(`/user-customer/customer-card?deleted=false&id=${cardId}`);
    if (!data.success) {
      // throw new Error(data.error);
      return { customerCard: {} };
    }
    return data;
  } catch (error) {
    return { customerCard: {} };
  }
};

export const apiReportProblem = async (task, feedback) => {
  const taskProblem = {
    task_id: task.id,
    user_id: task.user_id,
    feedback_id: feedback.reasonId,
    comments: feedback.comment,
  };

  const { data } = await api.post(`/task/${task.id}/problem`, { taskProblem });
  if (!data.success) {
    throw new Error(data.error);
  }
  return true;
};

export const apiGetProblemReasons = async () => {
  const { data } = await api.get('/feedback?enabled=true&type=client_payment_problem');
  if (!data.success) {
    throw new Error(data.error);
  }
  return data;
};

export const apiRegisterSession = async (userSession) => {
  const { data } = await api.post(`/users/${userSession.user_id}/sessions`, { ...userSession });

  if (!data.success) {
    throw new Error(data.error);
  }

  return data;
};
