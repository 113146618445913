import React from 'react';
import PropTypes from 'prop-types';

import { H1, H2 } from './styles';

const Title = ({ children, ...props }) => <H1 {...props}>{children}</H1>;

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  coupon: PropTypes.bool,
  size: PropTypes.string,
  marginTop: PropTypes.string,
  perfil: PropTypes.bool,
};

export default Title;

export const Subtitle = ({ children, ...props }) => <H2 {...props}>{children}</H2>;

Subtitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onlyDesktop: PropTypes.bool,
};
