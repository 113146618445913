import styled, { css } from 'styled-components';
import { colors, device } from 'style';

export const Input = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin: 5px 0;

  min-height: 45px;
  width: 100%;
  padding: ${props => (props.isDate ? 0 : '0.5rem')};
  color: ${colors.greyDark};
  border: 1px solid
    ${props => (props.hasError && colors.danger)
      || (props.focus && colors.primary)
      || (props.hasValue && colors.greyLight)
      || colors.greyLight};
  border-radius: 2px;

  background: ${props => (props.disabled ? colors.greyLighter : colors.white)};

  @media ${device.fromTablet} {
    &:hover {
      background: ${props => (props.focus ? colors.white : colors.greyLighter)};
      border: 1px solid ${props => (props.focus ? colors.primary : colors.greyLight)};
    }
  }

  & input {
    width: 100%;
    margin: 0 0.5rem;
    border: none;
    background: none;
    font-size: 14px;
    line-height: 24px;
    color: ${colors.greyDark};
  }

  @media ${device.fromTablet} {
    & input:-webkit-autofill:hover {
      border: none;
      transition: background-color 5000s ease-in-out 0s !important;
      -webkit-text-fill-color: ${colors.greyDark};
      -webkit-box-shadow: 0 0 0 50px ${props => (props.focus ? colors.white : colors.greyLighter)}
        inset;
    }
  }

  & input:-webkit-autofill,
  & input:-webkit-autofill:focus,
  & input:-webkit-autofill:active {
    border: none;
    transition: background-color 5000s ease-in-out 0s !important;
    -webkit-text-fill-color: ${colors.greyDark};
    -webkit-box-shadow: 0 0 0 50px
      ${props => (props.disabled && colors.greyLighter) || (props.focus && colors.white) || colors.white}
      inset;

    @media ${device.fromTablet} {
      -webkit-box-shadow: 0 0 0 50px
        ${props => (props.disabled && colors.greyLighter)
          || (props.focus && colors.white)
          || (props.hover && colors.greyLighter)
          || colors.white}
        inset;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.placeholderAlign ? props.placeholderAlign : 'flex-start')};
  justify-content: space-between;
  margin-top: ${props => props.marginTop};
  width: 100%;

  ${props => !props.isFormField && css`
    max-width: none;
    min-width: 200px;
  `}
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  max-width: 90%;
  
  color: ${props => (props.hasError && colors.danger)
    || (props.disabled && props.greyLight)
    || (props.focus && colors.greyDark)
    || colors.greyDark};
`;

export const InputStatus = styled.span`
  font-size: 14px;
  color: ${props => (props.hasError && colors.danger) || (props.success && colors.success) || colors.greyDark};
`;

export const IconRight = styled.a`
  ${props => props.isClick
    && css`
      cursor: pointer;
    `}
`;
