import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import { FormGroup, Label, Input } from 'reactstrap';

import { parseCardBrandToClass } from '../../helpers/paymentValidations';

import './creditCardForm.less';

export default class CreditCardForm extends Component {
  constructor(props) {
    super(props);
    this.changeCardInfo = this.changeCardInfo.bind(this);
  }

  changeCardInfo({ target }) {
    const { name, value } = target;

    this.props.handleChangeCard(name, value);
  }

  render() {
    const { validation, card } = this.props;

    return (
      <div className="row">
        <div className="col-12">
          <FormGroup
            className={`ua-cc-input ${validation.display_number ? 'invalid-form-control' : ''}`}
          >
            <Label for="cardnumber">Número do cartão</Label>
            <MaskedInput
              className="form-control"
              type="tel"
              id="cardnumber"
              name="display_number"
              value={card.display_number}
              guide={false}
              mask={[
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                ' ',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                ' ',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                ' ',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
              ]}
              onChange={this.changeCardInfo}
            />
            <span className={`brand-${card.brand ? parseCardBrandToClass(card.brand) : ''}`} />
          </FormGroup>
          {validation.display_number && <span>{validation.display_number}</span>}
        </div>

        <div className="col-12">
          <FormGroup className={validation.holder_name ? 'invalid-form-control' : ''}>
            <Label for="nameoncard">Nome (como escrito no cartão)</Label>
            <Input
              type="text"
              id="nameoncard"
              name="holder_name"
              onChange={this.changeCardInfo}
              value={card.holder_name}
            />
          </FormGroup>
          {validation.holder_name && <span>{validation.holder_name}</span>}
        </div>

        <div className="col-6">
          <FormGroup className={validation.validThru ? 'invalid-form-control' : ''}>
            <Label for="validthru">Data de vencimento</Label>

            <MaskedInput
              className="form-control"
              placeholder="MM/AA"
              guide={false}
              type="tel"
              id="validthru"
              name="validThru"
              value={card.validThru}
              onChange={this.changeCardInfo}
              mask={[/[0-1]/, /[0-9]/, '/', /[1-9]/, /[0-9]/]}
            />
          </FormGroup>
          {validation.validThru && <span>{validation.validThru}</span>}
        </div>
        <div className="col-6">
          <FormGroup className={validation.cvv ? 'invalid-form-control' : ''}>
            <Label for="cardcvv">CVV</Label>

            <MaskedInput
              className="form-control"
              guide={false}
              type="tel"
              id="cardcvv"
              name="cvv"
              onChange={this.changeCardInfo}
              value={card.cvv}
              mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
            />
          </FormGroup>
          {validation.cvv && <span>{validation.cvv}</span>}
        </div>

        <div className="col-12">
          <FormGroup className={validation.cpf ? 'invalid-form-control' : ''}>
            <Label for="ownercpf">CPF (do dono do cartão)</Label>
            <MaskedInput
              type="tel"
              id="ownercpf"
              name="cpf"
              className="form-control"
              guide={false}
              mask={[
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '.',
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                '-',
                /[0-9]/,
                /[0-9]/,
              ]}
              value={card.cpf}
              onChange={this.changeCardInfo}
            />
          </FormGroup>
          {validation.cpf && <span>{validation.cpf}</span>}
        </div>
      </div>
    );
  }
}

CreditCardForm.propTypes = {
  card: PropTypes.object,
  validation: PropTypes.object,
  handleChangeCard: PropTypes.func,
};
