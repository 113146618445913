import { css } from 'styled-components';

export const flex = {
  columnGrow: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
  row: {},
  column: {
    hCenterBetween: css`
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    `,
    hCenterAround: css`
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    `,
  },
};
