/* **** CPF **** */

import { isValidPhone } from '@brazilian-utils/validators';

export const validateCpf = (cpfIn) => {
  if (!cpfIn) {
    return false;
  }
  const cpf = cpfIn.replace(/[^\d]+/g, '');

  if (cpf === '') {
    return false;
  }

  if (
    cpf.length !== 11
    || cpf === '00000000000'
    || cpf === '11111111111'
    || cpf === '22222222222'
    || cpf === '33333333333'
    || cpf === '44444444444'
    || cpf === '55555555555'
    || cpf === '66666666666'
    || cpf === '77777777777'
    || cpf === '88888888888'
    || cpf === '99999999999'
  ) {
    return false;
  }

  let add = 0;
  for (let i = 0; i < 9; i += 1) {
    add += parseInt(cpf.charAt(i), 10) * (10 - i);
  }

  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(9), 10)) {
    return false;
  }

  add = 0;
  for (let i = 0; i < 10; i += 1) {
    add += parseInt(cpf.charAt(i), 10) * (11 - i);
  }

  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(cpf.charAt(10), 10)) {
    return false;
  }

  return true;
};

const validateEmail = (email) => {
  const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return EMAIL_REGEX.test(email);
};

const validatePassword = (password) => {
  if (password.length < 6) {
    return 'A senha deve conter no mínimo 6 caracteres';
  }
  return '';
};

export const validateFullName = (name) => {
  if (name.trim().split(' ').length < 2) {
    return 'Digite nome e sobrenome';
  }

  return '';
};

export const validateUserFields = (name, value) => {
  let message = '';

  switch (name) {
    case 'email':
      if (!value) message = 'Campo obrigatório';
      if (!validateEmail(value)) message = 'E-mail inválido';
      break;
    case 'password':
      if (!value) message = 'Campo obrigatório';
      message = validatePassword(value);
      break;
    case 'newPassword':
      if (!value) message = 'Campo obrigatório';
      message = validatePassword(value);
      break;
    case 'name':
      if (!value) message = 'Campo obrigatório';
      message = validateFullName(value);
      break;
    case 'birthday':
      message = '';
      break;
    case 'phone':
      if (!value) message = 'Campo obrigatório';
      if (!isValidPhone(value)) message = 'Telefone inválido';
      break;
    case 'pin':
      if (!value) message = 'Campo obrigatório';
      else {
        const { length } = value;
        if (length < 6) message = `Faltam ${6 - length} caracteres`;
      }
      break;
    default:
      message = '';
      break;
  }
  return message;
};

export const genderOptions = [
  { value: '', label: '' },
  { value: 'male', label: 'Masculino' },
  { value: 'female', label: 'Feminino' },
  { value: 'other', label: 'Outro gênero' },
];
