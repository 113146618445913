import styled, { css } from 'styled-components';
import { colors, device } from '../../style';

export const SectionStyled = styled.section`
  background: ${props => props.background};
  display: flex;
  flex: 1;
  position: relative;

  ${props => !props.isFullMobile
    && css`
      padding: ${({ paddingMobile }) => (paddingMobile || '1rem')};

      @media ${device.fromTablet} {
        padding: 6rem 1.5rem;
      }
    `}

  @media ${device.fromTablet} {
    ${props => !props.isModal
      && css`
        padding: 1.5rem 0;
      `}
    justify-content: center;
  }

  ${props => props.centered
    && css`
      flex-direction: column;
      align-items: center;
    `}
`;
