/* **** Credit Card **** */

/* **** Validate Form Card **** */

import { validateCpf, validateFullName } from './userValidations';

export const parseCardBrandToClass = (brand) => {
  const brandLower = brand ? brand.toLowerCase() : '';

  if (brandLower === 'visa') {
    return 'visa';
  }
  if (
    brandLower === 'american express'
    || brandLower === 'american_express'
    || brandLower === 'amex'
  ) {
    return 'amex';
  }
  if (brandLower === 'jcb') {
    return 'jcb';
  }
  if (brandLower === "diner's club" || brandLower === 'diners_club') {
    return 'diners';
  }
  if (brandLower === 'master' || brandLower === 'mastercard') {
    return 'master';
  }
  if (brandLower === 'elo') {
    return 'elo';
  }

  return brandLower;
};

const cards = {
  elo: {
    cvv_pattern: /^[0-9]{3}$/,
    brand: /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67[0-7][0-9]|9000)|50(9[0-9][0-9][0-9])|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|05([7-9])|06([0-9])|07([0-9])|08([0-9])|4([0-3][0-9]|8[5-9]|9[0-9])|5([0-9][0-9]|3[0-8])|9([0-6][0-9]|7[0-8])|7([0-2][0-9])|541|700|720|727|901)|65165([2-9])|6516([6-7][0-9])|65500([0-9])|6550([0-5][0-9])|655021|65505([6-7])|6516([8-9][0-9])|65170([0-4]))/,
  },
  visa: {
    cvv_pattern: /^[0-9]{3}$/,
    brand: /^4/,
  },
  mastercard: {
    cvv_pattern: /^[0-9]{3}$/,
    brand: /^(5[1-5]|677189|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)/,
  },
  amex: {
    cvv_pattern: /^[0-9]{4}$/,
    brand: /^(34|37)/,
  },
  diners: {
    cvv_pattern: /^[0-9]{3}$/,
    brand: /^(30|36|38|39)/,
  },
  jcb: {
    cvv_pattern: /^[0-9]{3}$/,
    brand: /^(2131|1800|35)/,
  },
};

export const validateCardNumber = (e) => {
  if (e.length == 0 || /[^0-9-\s]+/.test(e)) return !1;
  let t = 0;
  var n = 0;
  let a = !1;
  e = e.replace(/\D/g, '');
  for (let r = e.length - 1; r >= 0; r--) {
    const i = e.charAt(r);
    var n = parseInt(i, 10);
    a && (n *= 2) > 9 && (n -= 9), (t += n), (a = !a);
  }

  return t % 10 == 0 || (e.length >= 14 && e.length <= 16);
};

export const validateExpDate = date => (
  (date = getMonthYearByFullExpiration(date)),
  date && date.length == 2 ? validateExpiration(date[0], date[1]) : !1
);

export const getMonthYearByFullExpiration = e => (e ? ((e = e.toString().split('/')), e && e.length == 2 ? [e[0], e[1]] : !1) : !1);

export const validateExpiration = (e, t) => {
  const today = new Date();
  if ((today, isNaN(parseInt(e)) || isNaN(parseInt(t)))) return !1;
  if (void 0 == e) return !1;
  if (e > 12 || e < 1) return !1;
  if (void 0 == t) return !1;
  if (
    (t.length == 2
      && (t = today
        .getFullYear()
        .toString()
        .substr(0, 2) + t),
    t < today.getFullYear())
  ) return !1;
  if (t == today.getFullYear()) {
    if (e > 12 || e < today.getMonth() + 1) return !1;
  } else if (t > today.getFullYear() && (e > 12 || e < 1)) return !1;
  return !0;
};

export const getBrand = (number) => {
  let input = number || '';

  input = input.replace(/[^0-9]/g, '');
  for (const t in cards) {
    if (cards.hasOwnProperty(t)) {
      if (cards[t].brand.test(input)) {
        return keyOf(cards, cards[t]);
      }
    }
  }
  return '';
};

export const validateCVV = (e, t) => (t && cards[t] ? cards[t].cvv_pattern.test(e) : !1);

export const keyOf = (e, t) => {
  for (const n in e) if (hasOwnProperty.call(e, n) && e[n] === t) return n;
  return null;
};

export const validateCard = (name, value, brand, cardId) => {
  let message = '';

  if (!value) {
    message = 'Campo obrigatório.';
  } else {
    switch (name) {
      case 'display_number':
        if (!validateCardNumber(value)) {
          message = 'Número inválido.';
        }
        break;
      case 'holder_name':
        message = validateFullName(value);
        break;
      case 'cpf':
        if (!validateCpf(value) && !cardId) {
          message = 'CPF Inválido';
        }
        break;
      case 'validThru':
        if (value.length < 5 || !validateExpDate(value)) {
          message = 'Data inválida';
        }
        break;
      case 'cvv':
        if (!validateCVV(value, brand)) {
          message = 'Código inválido';
        }
        break;
      default:
        message = '';
        break;
    }
  }

  return message;
};

export const isCardFormValid = (card) => {
  const validation = {};

  Object.entries(card).forEach(([key, value]) => {
    const message = validateCard(key, value, card.brand, card.id);

    if (message) {
      Object.assign(validation, { [key]: message });
    }
  });

  return validation;
};
