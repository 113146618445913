import api from './index';


export const apiGetChatsList = async (task_id) => {
  try {
    const {
      data: { success, chats },
    } = await api.get(`/chat/task/${task_id}/client`);

    if (!success) {
      throw new Error();
    }

    return chats;
  } catch (error) {
    throw error;
  }
};

export const apiGetMessages = async (chat_id) => {
  try {
    const { data } = await api.get(`/chat/${chat_id}/message`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiSendMessage = async (chat_id, message) => {
  try {
    const { data } = await api.post(`/chat/${chat_id}/message`, { message });
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiReadMessages = async (arrMessagesId) => {
  try {
    const { data } = await api.put('/chat/messages/isread', { arrMessagesId });
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiUploadFile = async (file, message) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('message', JSON.stringify({ message }));

  try {
    const { data } = await api.post(`/chat/${message.chat_id}/uploadFiles`, formData);

    return data;
  } catch (err) {
    throw err;
  }
};

export const apiRemoveMessage = async (chat_id, message_id) => {
  try {
    const { data } = await api.put(`/chat/${chat_id}/set-is-deleted`, message_id);
    return data;
  } catch (error) {
    throw error;
  }
};
