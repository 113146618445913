import axios from 'axios';

const api = axios.create({
  baseURL: process.env.API_URL,
});

export const google = axios.create({
  baseURL: 'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
});

export default api;
