import api from './index';

export const apiIncludeAddress = async (address) => {
  try {
    const { data } = await api.post('/address', { address });
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiIncludeAddressCompany = async (address, company_id) => {
  try {
    const { data } = await api.post(`companies/${company_id}/addresses`, { ...address, company_id });
    return data;
  } catch (error) {
    console.log(error);
    // apiEditAddressCompany(params, error.response)
    throw (error);
  }
};

export const apiEditAddressCompany = async (address, company_id) => {
  try {
    const { data } = await api.put(`companies/${company_id}/addresses/${address.id}`, { ...address, company_id });
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiEditAddress = async (address) => {
  try {
    const { data } = await api.put(`address/${address.id}`, { address });
    return data;
  } catch (err) {
    throw err;
  }
};

// export const apiFindAddressByCompanyAndUser = async (company_id, user_id) => {
//   try {
//     const { data } = await api.get(`companies/${company_id}/user-addresses/${user_id}`);
//     return data;
//   } catch (error) {
//     throw error;
//   }
// };

export const apiFindAddressByCompanyAndUser = async (user_id) => {
  try {
    const { data: { address } } = await api.get(`address/user/${user_id}`);
    console.log(address);
    return address;
  } catch (error) {
    throw error;
  }
};
