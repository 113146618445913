import React from 'react';
import PropTypes from 'prop-types';

import { SectionStyled } from './styles';

const Section = ({ children, ...props }) => (
  <SectionStyled {...props}>
    {children}
  </SectionStyled>
);

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  centered: PropTypes.bool,
  isModal: PropTypes.bool,
  isFullMobile: PropTypes.bool,
};

export default Section;
