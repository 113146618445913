export const identifyUser = (data) => {
  const { user } = data;
  console.log('PIPZ IDENTIFY USER');

  if (!window.pipz) {
    console.error('PIPZ not loaded');
  }

  window.pipz.identify(user.id, {
    remote_id: user.id,
    name: user.name,
    phone: user.phone,
    email: user.email,
    registered: true,
    source: user.source,
    birthdate: user.birthday,
    gender: user.gender,
    city_name: user.city_name,
    state: user.state_initials,
  });
};

export const customEvent = (event, properties) => {
  console.log(`[PIPZ TRACK] Sending event ${event} to Pipz with properties`, properties);

  if (!window.pipz) {
    console.error('PIPZ not loaded');
  }

  window.pipz.track(event, properties);
};
