import React from 'react';
import PropTypes from 'prop-types';

import {
  HeaderStyled,
  HeaderWrapper,
} from './styles';

const Header = ({
  title, subtitle, centered, contentRight, headerDesktop,
}) => (
  <HeaderStyled headerDesktop={headerDesktop} centered={centered}>
    <HeaderWrapper>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </HeaderWrapper>
    {contentRight}
  </HeaderStyled>
);

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  centered: PropTypes.bool,
  headerDesktop: PropTypes.bool,
  contentRight: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Header;
