import api from './index';

export const apiGetRatingLabels = async (ratingValue) => {
  try {
    const { data } = await api.get(`/user/rating/labels/${ratingValue}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiPatchRating = async (token, ratingValuesForm, taskId) => {
    const { data } = await api.patch(`/task/${taskId}/rate`, ratingValuesForm, token);
    if (!data.success) {
        throw new Error();
    }
    return true;
};