import styled, { css } from 'styled-components';
import { colors, device, flex } from 'style';

export const ContentStyle = styled.div`
  ${flex.columnGrow}
  background: ${({ background }) => colors[background] || colors.white};
  width: 100%;

  overflow-y: hidden;
  overflow-x: hidden;

  ${props => (!props.isFullMobile
    ? css`
          border: 1px solid ${colors.greyLight};
          border-radius: 3px;
          overflow-y: auto;
          max-height: 400px;

          @media ${device.fromTablet} {
            overflow-y: hidden;
            display: inline-table;
          }

          @media ${device.fromMobileM} {
            max-height: 550px;
          }

          @media ${device.fromMobileL} {
            max-height: 550px;
          }
        `
    : css`
          @media ${device.fromTablet} {
            ${({ noBorder }) => !noBorder
              && css`
                border: 1px solid ${colors.greyLight};
              `}
            border-radius: 3px;
            min-height: ${({ containerHeightDesktop }) => `${containerHeightDesktop}px` || 'max-content'};
            max-height: ${({ containerHeightDesktop }) => containerHeightDesktop || 650}px;

            flex: 1;
          }
        `)}
`;
