const INITIAL_STATE = {
  couponIdLink: 0,
  error: undefined,
};

/**
 * Types
 */
export const Types = {
  VALIDATE_COUPONS_REQUEST: 'coupons/VALIDATE_COUPONS_REQUEST',
  VALIDATE_COUPONS_SUCCESS: 'coupons/VALIDATE_COUPONS_SUCCESS',
  VALIDATE_COUPONS_ERROR: 'coupons/VALIDATE_COUPONS_ERROR',
  REDEEM_COUPONS_REQUEST: 'coupons/REDEEM_COUPONS_REQUEST',
  REDEEM_COUPONS_SUCCESS: 'coupons/REDEEM_COUPONS_SUCCESS',
};

/**
 * Reducer
 */
export default function task(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.VALIDATE_COUPONS_SUCCESS: {
      return { ...state, couponIdLink: action.payload, error: undefined };
    }
    case Types.VALIDATE_COUPONS_ERROR: {
      return { ...state, couponIdLink: 0, error: action.payload };
    }
    case Types.REDEEM_COUPONS_SUCCESS: {
      return { ...state, couponIdLink: 0, error: undefined };
    }
    default:
      return state;
  }
}

/**
 * Actions Creators
 */
export const Creators = {
  validateCouponsRequest: payload => ({
    type: Types.VALIDATE_COUPONS_REQUEST,
    payload,
  }),
  validateCouponsSuccess: payload => ({
    type: Types.VALIDATE_COUPONS_SUCCESS,
    payload,
  }),
  validateCouponsError: payload => ({
    type: Types.VALIDATE_COUPONS_ERROR,
    payload,
  }),
  redeemCouponsRequest: (idCoupon, userId) => ({
    type: Types.REDEEM_COUPONS_REQUEST,
    payload: {
      idCoupon, userId,
    },
  }),
  redeemCouponsSuccess: payload => ({
    type: Types.REDEEM_COUPONS_SUCCESS,
    payload,
  }),
};
