import { fromJS } from 'immutable';
import _find from 'lodash/find';
import { differenceInDays } from 'date-fns';

import {
  updatePhone, updateUser, sendPinValidation, pinValidation,
} from 'services/api/user';
import { getParameter } from 'services/api/helpers';

import { Creators as UiCreators, growlTypes } from 'store/ducks/ui';
import { Creators as ServiceFormCreators } from 'store/ducks/serviceForm';

import {
  getUserTasks,
  getTaskQuotes,
  getTaskById,
  setQuoteRead,
  getBussinesProfile,
  getQuoteById,
  getCouponById,
  getUserCreditCards,
  getTaskInstallments,
} from '../../helpers/integration';

import {
  getCardById,
  getBusinessTask,
  saveCreditCard,
  deleteCreditCard,
} from '../../helpers/paymentIntegration';

import { getUserCoupons, addUserCoupon } from '../../services/api/profile';

/* Helpers */

import { errMsg } from './helpers/messages';

/**
 * NEW REDUCERS (2019)
 */
import { replace, push } from 'connected-react-router/immutable'; //eslint-disable-line
import partners from 'config/partners'; //eslint-disable-line

const USER_AREA = 'userArea';
// Constants for the operations that can change the store.
const SET_ERROR = `${USER_AREA}/SET_ERROR`;
const SET_LOADING = `${USER_AREA}/SET_LOADING`;
const SET_PRO_RATING = `${USER_AREA}/SET_PRO_RATING`;
const SET_TRIIDER_USER = `${USER_AREA}/SET_TRIIDER_USER`;
const SET_CHAT_PROPERTY = `${USER_AREA}/SET_CHAT_PROPERTY`;
const SET_MESSAGE_MODAL = `${USER_AREA}/SET_MESSAGE_MODAL`;
const SET_LOGIN_PROPERTY = `${USER_AREA}/SET_LOGIN_PROPERTY`;
const SET_FORGOT_PROPERTY = `${USER_AREA}/SET_FORGOT_PROPERTY`;
const SET_USER_AREA_FIELD = `${USER_AREA}/SET_USER_AREA_FIELD`;
const SET_PAYMENT_PROPERTY = `${USER_AREA}/SET_PAYMENT_PROPERTY`;
const SET_USER_QUOTES_FIELD = `${USER_AREA}/SET_USER_QUOTES_FIELD`;
const SET_CHECKOUT_PROPERTY = `${USER_AREA}/SET_CHECKOUT_PROPERTY`;
const SET_MESSAGES_PROPERTY = `${USER_AREA}/SET_MESSAGES_PROPERTY`;
const SET_TRIIDER_USER_PHONE = `${USER_AREA}/SET_TRIIDER_USER_PHONE`;
const SET_TRIIDER_USER_PROPERTY = `${USER_AREA}/SET_TRIIDER_USER_PROPERTY`;
const SET_TRIIDER_USER_EMAIL = `${USER_AREA}/SET_TRIIDER_USER_EMAIL`;
const SET_TRIIDER_USER_NEW_EMAIL = `${USER_AREA}/SET_TRIIDER_USER_NEW_EMAIL`;
const SET_TRIIDER_USER_ORIGIN = `${USER_AREA}/SET_TRIIDER_USER_ORIGIN`;
const SET_PRO_PROFILE_PROPERTY = `${USER_AREA}/SET_PRO_PROFILE_PROPERTY`;
const SET_REGISTRATION_PROPERTY = `${USER_AREA}/SET_REGISTRATION_PROPERTY`;
const SET_USER_AREA_PROPERTY = `${USER_AREA}/SET_USER_AREA_PROPERTY`;
const SET_TASK_DETAILS_PROPERTY = `${USER_AREA}/SET_TASK_DETAILS_PROPERTY`;
const SET_CHECKOUT_SUB_PROPERTY = `${USER_AREA}/SET_CHECKOUT_SUB_PROPERTY`;
const SET_VALIDATION_CARD_PROPERTY = `${USER_AREA}/SET_VALIDATION_CARD_PROPERTY`;
const SET_CHECKOUT_CARD_PROPERTY = `${USER_AREA}/SET_CHECKOUT_CARD_PROPERTY`;
const SET_ARCHIVE_MODAL_PROPERTY = `${USER_AREA}/SET_ARCHIVE_MODAL_PROPERTY`;
const SET_REPORT_PROBLEM_MODAL_PROPERTY = `${USER_AREA}/SET_REPORT_PROBLEM_MODAL_PROPERTY`;
const SET_SUCCESS_REPORT_MODAL_PROPERTY = `${USER_AREA}/SET_SUCCESS_REPORT_MODAL_PROPERTY`;
const SET_BUSINESS_PROFILE_MODAL_PROPERTY = `${USER_AREA}/SET_BUSINESS_PROFILE_MODAL_PROPERTY`;
const SET_USER_PAYMENT_METHODS_PROPERTY = `${USER_AREA}/SET_USER_PAYMENT_METHODS_PROPERTY`;
const INCLUDE_USER_PAYMENT_METHODS_PROPERTY = `${USER_AREA}/INCLUDE_USER_PAYMENT_METHODS_PROPERTY`;
const REMOVE_USER_PAYMENT_METHODS_PROPERTY = `${USER_AREA}/REMOVE_USER_PAYMENT_METHODS_PROPERTY`;
const SET_DEFAULT = `${USER_AREA}/SET_DEFAULT`;
const SET_COUPONS = `${USER_AREA}/SET_COUPONS`;
const ADD_COUPON = `${USER_AREA}/ADD_COUPON`;
const SET_LOCATION = `${USER_AREA}/SET_LOCATION`;

const DEFAULT_STATE = fromJS({
  userArea: {
    pathToRedirect: '/',
    error: '',
    loading: false,
    soonBanner: false,
    coupomModal: false,
    messageModal: {
      isVisible: false,
      body: '',
    },
    login: {
      error: '',
      email: '',
      password: '',
      remember: false,
    },
    registration: {
      exists: false,
      error: '',
      name: '',
      email: '',
      password: '',
    },
    taskDetails: {
      error: false,
      loading: null,
      task: {},
      quote: {},
      archiveModal: {
        show: false,
        disabledButton: true,
        reasons: [],
        feedback: null,
        comment: '',
        hasInput: false,
        reasonId: null,
      },
    },
    tasks: [],
    taskQuotes: {
      coupon: {},
      task: {},
      quotes: [],
    },
    proProfile: {
      name: '',
      rating_list: [],
      services: [],
    },
    proRating: [],
    taskCheckout: {
      success: false,
      error: false,
      step: 1,
      quote: {},
      task: {},
      coupon: {},
      cardValidation: {},
      sentCard: false,
      card: {},
      cardList: [],
      installments: 1,
    },
    taskPay: {
      success: false,
      quote: {},
      task: {},
      coupon: {},
      card: {},
      businessTask: {},
      reportProblemModal: {
        show: false,
        disabledButton: true,
        reasons: [],
        feedback: null,
        comment: '',
        hasInput: false,
        reasonId: null,
      },
      successReportModal: {
        show: false,
      },
    },
    messages: {
      error: '',
      loading: false,
      chats: [],
    },
    chat: {
      error: '',
      loading: false,
      quote: {},
      task: {},
      messages: [],
      typingMessage: '',
      socket: null,
      businessProfile: {},
    },
    triiderUser: null,
    paymentMethods: [],
    coupons: [],
    location: [],
  },
});

/** It must interpret the action and return a new state. */
const reducer = (state = DEFAULT_STATE, action = {}) => {
  const fns = {
    [SET_ERROR]: () => state.setIn([USER_AREA, 'error'], fromJS(action.data)),

    [SET_DEFAULT]: () => state.set(USER_AREA, fromJS(fromJS(DEFAULT_STATE.get('userArea')))),

    [SET_MESSAGE_MODAL]: () => state.setIn([USER_AREA, 'messageModal'], fromJS(action.data)),

    [SET_PRO_RATING]: () => state.setIn([USER_AREA, 'proRating'], fromJS(action.data)),

    [SET_LOADING]: () => state.setIn([USER_AREA, 'loading'], fromJS(action.data)),

    [SET_TRIIDER_USER]: () => state.setIn([USER_AREA, 'triiderUser'], fromJS(action.data)),

    [SET_PRO_PROFILE_PROPERTY]: () => state.setIn([USER_AREA, 'proProfile', action.property], fromJS(action.data)),

    [SET_LOGIN_PROPERTY]: () => state.setIn([USER_AREA, 'login', action.property], fromJS(action.data)),

    [SET_USER_AREA_PROPERTY]: () => state.setIn([USER_AREA, action.property], fromJS(action.data)),

    [SET_REGISTRATION_PROPERTY]: () => state.setIn([USER_AREA, 'registration', action.property], fromJS(action.data)),

    [SET_CHECKOUT_PROPERTY]: () => state.setIn([USER_AREA, 'taskCheckout', action.property], fromJS(action.data)),

    [SET_PAYMENT_PROPERTY]: () => state.setIn([USER_AREA, 'taskPay', action.property], fromJS(action.data)),

    [SET_CHECKOUT_CARD_PROPERTY]: () => state.setIn([USER_AREA, 'taskCheckout', 'card', action.property], fromJS(action.data)),

    [SET_VALIDATION_CARD_PROPERTY]: () => state.setIn(
      [USER_AREA, 'taskCheckout', 'cardValidation', action.property],
      fromJS(action.data),
    ),

    [SET_CHECKOUT_SUB_PROPERTY]: () => state.setIn([USER_AREA, 'taskCheckout', action.parent, action.property], fromJS(action.data)),

    [SET_USER_AREA_FIELD]: () => state.setIn([USER_AREA, action.property], fromJS(action.data)),

    [SET_USER_QUOTES_FIELD]: () => state.setIn([USER_AREA, 'taskQuotes', action.property], fromJS(action.data)),

    [SET_TRIIDER_USER_PHONE]: () => state.setIn([USER_AREA, 'triiderUser', 'user', 'phone'], fromJS(action.data)),

    [SET_TRIIDER_USER_PROPERTY]: () => state.setIn([USER_AREA, 'triiderUser', 'user', action.property], fromJS(action.data)),

    [SET_TRIIDER_USER_EMAIL]: () => state.setIn([USER_AREA, 'triiderUser', 'user', 'email'], fromJS(action.data)),

    [SET_TRIIDER_USER_NEW_EMAIL]: () => state.setIn([USER_AREA, 'triiderUser', 'user', 'newEmail'], fromJS(action.data)),

    [SET_TRIIDER_USER_ORIGIN]: () => state.setIn([USER_AREA, 'triiderUser', 'user', 'origin_id'], fromJS(action.data)),

    [SET_CHAT_PROPERTY]: () => state.setIn([USER_AREA, 'chat', action.property], fromJS(action.data)),

    [SET_TASK_DETAILS_PROPERTY]: () => state.setIn([USER_AREA, 'taskDetails', action.property], fromJS(action.data)),

    [SET_MESSAGES_PROPERTY]: () => state.setIn([USER_AREA, 'messages', action.property], fromJS(action.data)),

    [SET_ARCHIVE_MODAL_PROPERTY]: () => state.setIn([USER_AREA, 'taskDetails', 'archiveModal', action.property], fromJS(action.data)),

    [SET_REPORT_PROBLEM_MODAL_PROPERTY]: () => state.setIn(
      [USER_AREA, 'taskPay', 'reportProblemModal', action.property],
      fromJS(action.data),
    ),

    [SET_SUCCESS_REPORT_MODAL_PROPERTY]: () => state.setIn(
      [USER_AREA, 'taskPay', 'successReportModal', action.property],
      fromJS(action.data),
    ),

    [SET_BUSINESS_PROFILE_MODAL_PROPERTY]: () => state.setIn([USER_AREA, 'proProfile', 'ratingModal', action.property], fromJS(action.data)),

    [SET_USER_PAYMENT_METHODS_PROPERTY]: () => state.setIn([USER_AREA, action.property], fromJS(action.data)),

    [INCLUDE_USER_PAYMENT_METHODS_PROPERTY]: () => state.setIn(
      [USER_AREA, action.property],
      fromJS([action.data, ...state.toJS().userArea.paymentMethods]),
    ),

    [REMOVE_USER_PAYMENT_METHODS_PROPERTY]: () => state.setIn(
      [USER_AREA, action.property],
      fromJS(state.toJS().userArea.paymentMethods.filter(p => p.id !== action.data)),
    ),

    [SET_COUPONS]: () => state.setIn([USER_AREA, action.property], fromJS(action.data)),

    [ADD_COUPON]: () => state.setIn([USER_AREA, action.property], fromJS(action.data)),

    [SET_LOCATION]: () => state.setIn([USER_AREA, action.property], fromJS(action.data)),
  };

  return action.type in fns ? fns[action.type]() : state;
};

/**
 * THUNK ACTION CREATORS
 */

const setDefault = () => ({ type: SET_DEFAULT });

export const fetchError = data => dispatch => dispatch({ type: SET_ERROR, data });

export const clearErrors = (data = '', property = 'error') => (dispatch) => {
  dispatch({ type: SET_LOGIN_PROPERTY, data, property });
  dispatch({ type: SET_USER_AREA_FIELD, data, property });
};

export const fetchLoading = data => dispatch => dispatch({ type: SET_LOADING, data });

export const setCoupons = data => dispatch => dispatch({ type: SET_COUPONS, data });

export const fetchProRating = data => dispatch => dispatch({ type: SET_PRO_RATING, data });

export const fetchForgotProperty = (property, data) => dispatch => dispatch({ type: SET_FORGOT_PROPERTY, data, property });

export const fetchMessageModal = data => dispatch => dispatch({ type: SET_MESSAGE_MODAL, data });

export const fetchLoginProperty = (property, data) => dispatch => dispatch({ type: SET_LOGIN_PROPERTY, data, property });

export const fetcRegistrationProperty = (property, data) => dispatch => dispatch({ type: SET_REGISTRATION_PROPERTY, data, property });

export const setUserAreaProperty = (property, data) => dispatch => dispatch({ type: SET_USER_AREA_PROPERTY, data, property });

export const fetchTaskPayProperty = (property, data) => dispatch => dispatch({ type: SET_PAYMENT_PROPERTY, data, property });

export const fetcTaskCheckoutProperty = (property, data) => dispatch => dispatch({ type: SET_CHECKOUT_PROPERTY, data, property });

export const fetchCheckoutCardValidationProperty = (property, data) => dispatch => dispatch({ type: SET_VALIDATION_CARD_PROPERTY, data, property });

export const fetcTaskCheckoutCardProperty = (property, data) => dispatch => dispatch({ type: SET_CHECKOUT_CARD_PROPERTY, data, property });

export const fetchTaskCheckoutSubProperty = (parent, property, data) => dispatch => dispatch({
  type: SET_CHECKOUT_SUB_PROPERTY,
  data,
  parent,
  property,
});

export const setUserPhone = data => dispatch => dispatch({ type: SET_TRIIDER_USER_PHONE, data });

export const setUserFields = data => (dispatch) => {
  Object.entries(data).forEach(([key, value]) => {
    dispatch({ type: SET_TRIIDER_USER_PROPERTY, property: key, data: value });
  });
};

export const setUserEmail = data => dispatch => dispatch({ type: SET_TRIIDER_USER_EMAIL, data });

export const setUserNewEmail = data => dispatch => dispatch({ type: SET_TRIIDER_USER_NEW_EMAIL, data });

export const fetchInitalTaskCheckout = () => dispatch => dispatch({
  type: SET_USER_AREA_FIELD,
  data: DEFAULT_STATE.toJS().userArea.taskCheckout,
  property: 'taskCheckout',
});

export const fetchInitalTaskPay = () => dispatch => dispatch({
  type: SET_USER_AREA_FIELD,
  data: DEFAULT_STATE.toJS().userArea.taskPay,
  property: 'taskPay',
});

export const fetchInitalProfilePage = () => (dispatch) => {
  dispatch({
    type: SET_USER_AREA_FIELD,
    data: DEFAULT_STATE.toJS().userArea.proProfile,
    property: 'proProfile',
  });

  dispatch({ type: SET_PRO_RATING, data: [] });
};

export const fetchMessagesProperty = (property, data) => (dispatch) => {
  // dispatch({ type: SET_LOADING, data: true });
  dispatch({ type: SET_MESSAGES_PROPERTY, data, property });
};

export const fetchChatProperty = (property, data) => dispatch => dispatch({ type: SET_CHAT_PROPERTY, data, property });

export const fetchTriiderUser = (data, skipCompany) => async (dispatch) => {
  const { company_info } = (await JSON.parse(localStorage.getItem('triiderUser'))) || {};
  if (!skipCompany && company_info && data && !data.company_info) {
    Object.assign(data, { company_info });
  }
  await dispatch({ type: SET_TRIIDER_USER, data });
  if (data) {
    await localStorage.setItem('triiderUser', JSON.stringify(data));
  } else {
    await dispatch(setDefault());
    await localStorage.removeItem('triiderUser');
    
    //envento customizado para sinalizar
    //as landings ou qlqer outro listener sobre o logout
    const event = new Event('userLogOut');
    document.dispatchEvent(event);
    
    // const isPartner = JSON.parse(localStorage.getItem('isPartner'));
    // const source = JSON.parse(localStorage.getItem('source'));
    // window.location.href = `${process.env.SITE_PATH}${isPartner ? partners[source].home : ''}`;
  }
};

export const fetchUserAreaField = (property, data) => dispatch => dispatch({ type: SET_USER_AREA_FIELD, data, property });

export const fetchUserTasks = triiderUser => (dispatch) => {
  dispatch({ type: SET_LOADING, data: true });

  getUserTasks(triiderUser)
    .then((tasks) => {
      dispatch({ type: SET_LOADING, data: false });
      dispatch({ type: SET_USER_AREA_FIELD, property: 'tasks', data: tasks });
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING, data: false });
      dispatch({ type: SET_ERROR, err });
    });
};

export const fetchProProfileProperty = (property, data) => dispatch => dispatch({ type: SET_PRO_PROFILE_PROPERTY, data, property });

export const fetchBusinessProfile = (triiderUser, idPro) => (dispatch) => {
  dispatch({ type: SET_LOADING, data: true });

  getBussinesProfile(triiderUser, idPro)
    .then((profile) => {
      dispatch({ type: SET_LOADING, data: false });
      dispatch({ type: SET_USER_AREA_FIELD, property: 'proProfile', data: profile });
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING, data: false });
      dispatch({ type: SET_ERROR, err });
    });
};

export const fetchTaskQuotes = (triiderUser, taskId) => (dispatch) => {
  dispatch({ type: SET_LOADING, data: true });

  getTaskQuotes(triiderUser, taskId)
    .then((quotes) => {
      setQuoteRead(triiderUser, quotes);
      dispatch({ type: SET_LOADING, data: false });
      dispatch({ type: SET_USER_QUOTES_FIELD, property: 'quotes', data: quotes });
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING, data: false });
      dispatch({ type: SET_ERROR, err });
    });
};

export const fetchQuoteTask = (triiderUser, taskId) => (dispatch, state) => {
  const _task = state()
    .userArea.get('userArea')
    .toJS().taskQuotes.task;
  // console.log(_task)
  getTaskById(triiderUser, taskId)
    .then((task) => {
      dispatch({ type: SET_USER_QUOTES_FIELD, property: 'task', data: task });

      if (task.coupon_id) {
        getCouponById(triiderUser, task.coupon_id)
          .then(coupon => dispatch({ type: SET_USER_QUOTES_FIELD, property: 'coupon', data: coupon }))
          .catch(err => dispatch({ type: SET_ERROR, err }));
      }
    })
    .catch(err => dispatch({ type: SET_ERROR, err }));
};

export const fetchTaskCheckoutCoupon = (triiderUser, couponId) => (dispatch) => {
  getCouponById(triiderUser, couponId)
    .then(coupon => dispatch({ type: SET_CHECKOUT_PROPERTY, property: 'coupon', data: coupon }))
    .catch(err => dispatch({ type: SET_ERROR, err }));
};

export const fetchTaskInstallments = (triiderUser, amount) => (dispatch) => {
  getTaskInstallments(triiderUser, amount)
    .then(inst => dispatch({ type: SET_CHECKOUT_PROPERTY, property: 'installments', data: inst }))
    .catch(err => dispatch({ type: SET_ERROR, err }));
};

export const fetchTaskChekoutData = (triiderUser, taskId, quoteId) => (dispatch) => {
  dispatch({ type: SET_LOADING, data: true });

  const task = getTaskById(triiderUser, taskId);
  const quote = getQuoteById(triiderUser, quoteId);
  const cards = getUserCreditCards(triiderUser);

  Promise.all([task, quote, cards])
    .then((data) => {
      dispatch({ type: SET_CHECKOUT_PROPERTY, property: 'task', data: data[0] });
      dispatch({ type: SET_CHECKOUT_PROPERTY, property: 'quote', data: data[1] });
      dispatch({ type: SET_CHECKOUT_PROPERTY, property: 'cardList', data: data[2] });
      dispatch({ type: SET_LOADING, data: false });

      if (data[0].coupon_id) {
        fetchTaskInstallments(
          triiderUser,
          data[1].new_amount_with_discount || data[1].amount_with_discount,
        )(dispatch); // eslint-disable-line
      } else {
        fetchTaskInstallments(triiderUser, data[1].new_amount || data[1].amount)(dispatch);
      }

      // TODO: Extract this logic
      if (data[0].coupon_id) {
        getCouponById(triiderUser, data[0].coupon_id)
          .then(coupon => dispatch({ type: SET_CHECKOUT_PROPERTY, property: 'coupon', data: coupon }))
          .catch(err => dispatch({ type: SET_ERROR, err }));
      } else {
        dispatch({ type: SET_CHECKOUT_PROPERTY, property: 'coupon', data: {} });
      }

      const card = _find(data[2], { id: data[0].customer_card_id });

      if (card) {
        dispatch({ type: SET_CHECKOUT_PROPERTY, property: 'card', data: card });
      }
    })
    .catch((err) => {
      dispatch({ type: SET_ERROR, err });
      dispatch({ type: SET_LOADING, data: false });
    });
};

export const fetchTaskPayData = (triiderUser, taskId, quoteId) => (dispatch) => {
  dispatch({ type: SET_LOADING, data: true });

  const task = getTaskById(triiderUser, taskId);
  const quote = getQuoteById(triiderUser, quoteId);

  Promise.all([task, quote])
    .then((data) => {
      dispatch({ type: SET_PAYMENT_PROPERTY, property: 'task', data: data[0] });
      dispatch({ type: SET_PAYMENT_PROPERTY, property: 'quote', data: data[1] });
      dispatch({ type: SET_LOADING, data: false });

      getBusinessTask(triiderUser, data[0])
        .then((businessTask) => {
          dispatch({ type: SET_PAYMENT_PROPERTY, property: 'businessTask', data: businessTask });
        })
        .catch(err => dispatch({ type: SET_ERROR, err }));

      getCardById(triiderUser, data[0].customer_card_id)
        .then((card) => {
          dispatch({ type: SET_PAYMENT_PROPERTY, property: 'card', data: card });
        })
        .catch(err => dispatch({ type: SET_ERROR, err }));

      if (data[0].coupon_id) {
        getCouponById(triiderUser, data[0].coupon_id)
          .then(coupon => dispatch({ type: SET_PAYMENT_PROPERTY, property: 'coupon', data: coupon }))
          .catch(err => dispatch({ type: SET_ERROR, err }));
      }
    })
    .catch((err) => {
      dispatch({ type: SET_ERROR, err });
      dispatch({ type: SET_LOADING, data: false });
    });
};

export const fetchBusinessProfileRatingModalProperty = (property, data) => dispatch => dispatch({ type: SET_BUSINESS_PROFILE_MODAL_PROPERTY, property, data });

export const fetchReportProblemModalProperty = (property, data) => dispatch => dispatch({ type: SET_REPORT_PROBLEM_MODAL_PROPERTY, property, data });

export const fetchSuccessReportModalProperty = (property, data) => dispatch => dispatch({ type: SET_SUCCESS_REPORT_MODAL_PROPERTY, property, data });

export const fetchArchiveModalProperty = (property, data) => dispatch => dispatch({ type: SET_ARCHIVE_MODAL_PROPERTY, property, data });

export const fetchTaskDetails = (triiderUser, taskId) => (dispatch) => {
   dispatch({ type: SET_LOADING, data: true });

  getTaskById(triiderUser, taskId)
    .then((task) => {
      dispatch({ type: SET_LOADING, data: false });
      dispatch({ type: SET_TASK_DETAILS_PROPERTY, property: 'task', data: task });

      if (task.selected_quote_id) {
        getQuoteById(triiderUser, task.selected_quote_id)
          .then(quote => dispatch({ type: SET_TASK_DETAILS_PROPERTY, property: 'quote', data: quote }))
          .catch(err => dispatch({ type: SET_ERROR, err }));
      } else {
        dispatch({ type: SET_TASK_DETAILS_PROPERTY, property: 'quote', data: {} });
      }
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING, data: false });
      dispatch({ type: SET_TASK_DETAILS_PROPERTY, property: 'error', data: err });
    });
};

export const fetchPaymentMethods = triiderUser => (dispatch) => {
  getUserCreditCards(triiderUser)
    .then((cards) => {
      dispatch(UiCreators.showLoader());
      dispatch({
        type: SET_USER_PAYMENT_METHODS_PROPERTY,
        property: 'paymentMethods',
        data: cards,
      });
      dispatch(UiCreators.hideLoader());
    })
    .catch((err) => {
      dispatch(UiCreators.hideLoader());
      dispatch({ type: SET_ERROR, err });
    });
};

export const getCoupons = () => (dispatch, getState) => {
  dispatch(UiCreators.showLoader());

  const triiderUser = getTriiderUser(getState());
  const { user } = triiderUser;

  getUserCoupons(user)
    .then(({ coupons }) => {
      dispatch({
        type: SET_COUPONS,
        property: 'coupons',
        data: coupons,
      });
      dispatch(UiCreators.hideLoader());
    })
    .catch((err) => {
      const {
        response: {
          data: { error },
        },
      } = err;
      const message = errMsg(error);
      dispatch(UiCreators.hideLoader());
      dispatch(UiCreators.growl(message, growlTypes.GROWL_ERROR));
    });
};

export const setLocation = location => (dispatch, getState) => {
  const {
    city_name, state_name, state_initials, popover,
  } = location;

  dispatch({
    type: SET_LOCATION,
    property: 'location',
    data: {
      city_name, state_name, state_initials, popover,
    },
  });
};

export const disposePopover = () => (dispatch) => {
  dispatch({
    type: SET_LOCATION,
    property: 'location',
    data: { popover: false },
  });
};

export const creatorsCupomAddCupom = (oldCoupouns, coupon) => ({
  type: ADD_COUPON,
  property: 'coupons',
  data: [coupon, ...oldCoupouns],
});

export const addCoupon = code => (dispatch, getState) => {
  dispatch(UiCreators.showLoader());

  const oldCoupouns = getState()
    .userArea.get('userArea')
    .toJS().coupons;

  addUserCoupon(code)
    .then(({ coupon }) => {
      const check = oldCoupouns.some(_coupon => _coupon.id === coupon.id);
      dispatch(UiCreators.hideLoader());
      if (!check) {
        dispatch(creatorsCupomAddCupom(oldCoupouns, coupon));
        dispatch(UiCreators.growl('Cupom adicionado', growlTypes.GROWL_SUCCESS));
      } else {
        dispatch(UiCreators.growl('Cupom já resgatado', growlTypes.GROWL_INFO));
      }
    })
    .catch((err) => {
      const {
        response: {
          data: { error },
        },
      } = err;
      console.log(error);
      const message = errMsg(error);
      dispatch(UiCreators.hideLoader());
      dispatch(UiCreators.growl(message, growlTypes.GROWL_ERROR));
    });
};

export const saveUserCreditCard = (triiderUser, oldCard) => (dispatch) => {
  dispatch(UiCreators.showLoader());

  saveCreditCard(triiderUser, oldCard)
    .then((card) => {
      dispatch({
        type: INCLUDE_USER_PAYMENT_METHODS_PROPERTY,
        property: 'paymentMethods',
        data: card,
      });
      dispatch(replace('/meios-de-pagamento'));
      dispatch(UiCreators.hideLoader());
      dispatch(UiCreators.growl('Cartão adicionado!', growlTypes.GROWL_SUCCESS));
    })
    .catch((err) => {
      dispatch(UiCreators.growl(err, growlTypes.GROWL_ERROR));
      dispatch(UiCreators.hideLoader());
    });
};

export const deleteUserCreditCard = (session, cardId) => (dispatch) => {
  dispatch(UiCreators.showLoader());

  deleteCreditCard(session, cardId)
    .then(() => {
      dispatch({
        type: REMOVE_USER_PAYMENT_METHODS_PROPERTY,
        property: 'paymentMethods',
        data: cardId,
      });
      dispatch(UiCreators.hideModal());
      dispatch(UiCreators.hideLoader());
      dispatch(UiCreators.growl('Cartão excluído!', growlTypes.GROWL_SUCCESS));
    })
    .catch((err) => {
      dispatch(UiCreators.hideModal());
      dispatch(UiCreators.growl(err, growlTypes.GROWL_ERROR));
      dispatch(UiCreators.hideLoader());
    });
};

export const updateTriiderUser = (fields, callBack, useUser = false) => (dispatch, getState) => {
  dispatch(UiCreators.showLoader());

  const triiderUser = getTriiderUser(getState());
  const { user } = triiderUser;
  let handleFields = {...fields};  
  if(!fields.updated_image) 
    delete handleFields.profile_picture;

  updateUser(handleFields, user.id)
    .then(async (data) => {
      if (data.success) {
        Object.assign(user, fields);

        const newUser = {
          ...triiderUser,
          user,
        };

        await dispatch(fetchTriiderUser(newUser));
        dispatch(UiCreators.hideLoader());
        if (callBack && useUser) callBack(newUser);
        else if (typeof callBack === 'function') callBack();
      } else dispatch(UiCreators.growl('Ocorreu um erro ao atualizar os dados', growlTypes.GROWL_ERROR));
    })
    .catch((err) => {
      console.log(err);
      dispatch(UiCreators.growl('Ocorreu um erro ao atualizar os dados ', growlTypes.GROWL_ERROR));
    });
};

export const updateTriiderUserPhone = (fields, callBack, useUser = false) => (dispatch, getState) => {
  dispatch(UiCreators.showLoader());

  const triiderUser = getTriiderUser(getState());
  const { user } = triiderUser;

  updatePhone(fields, user.id)
    .then(async (data) => {
      if (data.success) {
        Object.assign(user, fields);

        const newUser = {
          ...triiderUser,
          user,
        };

        await dispatch(fetchTriiderUser(newUser));
        dispatch(UiCreators.hideLoader());
        if (callBack && useUser) callBack(newUser);
        else if (typeof callBack === 'function') callBack();
      } else {
        dispatch(UiCreators.growl('Ocorreu um erro ao atualizar os dados', growlTypes.GROWL_ERROR));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(UiCreators.showModal(err.data.error));
      dispatch(UiCreators.hideLoader());
    });
};


export const sendValidation = (resend, method) => (dispatch, getState) => {
  dispatch(UiCreators.showLoader());

  const triiderUser = getTriiderUser(getState());
  const { user } = triiderUser;

  sendPinValidation(user.id, method)
    .then((data) => {
      if (data.success) {
        dispatch(UiCreators.hideLoader());
        dispatch(UiCreators.routeRedirect(''));
        // dispatch(replace('/validar-telefone-pin/whats'));
        window.location.href = `${process.env.SITE_PATH}${process.env.BASE}/validar-telefone-pin?method=${method}`;
        if (resend) dispatch(UiCreators.growl('Código reenviado!', growlTypes.GROWL_SUCCESS));
      } else {
        dispatch(UiCreators.growl('Ocorreu um erro ao enviar o SMS', growlTypes.GROWL_ERROR));
      }
    })
    .catch(err => dispatch(UiCreators.growl('Ocorreu um erro ao enviar o SMS', growlTypes.GROWL_ERROR)));
};

export const validatePin = pin => (dispatch, getState) => {
  dispatch(UiCreators.showLoader());

  const state = getState();

  const triiderUser = getTriiderUser(state);
  const { user } = triiderUser;

  const hasTaskFinished = localStorage.getItem('hasTaskFinished');
  const hasTaskInProgress = localStorage.getItem('hasTaskInProgress');

  pinValidation(user.id, pin)
    .then(async (data) => {
      if (data.success) {
        Object.assign(user, { flg_phone_validated: true });
        const newUser = {
          ...triiderUser,
          user,
        };
        await dispatch(fetchTriiderUser(newUser));

        dispatch(UiCreators.hideLoader());
        dispatch(UiCreators.hideModal());

        if (hasTaskInProgress) {
          dispatch(ServiceFormCreators.processTask({ triiderUser: newUser }));
        } else if (hasTaskFinished) {
          dispatch(replace('/pedido-realizado'));
        } else {
          dispatch(replace('/'));
        }
      } else {
        dispatch(UiCreators.growl('Ocorreu um erro ao validar o código', growlTypes.GROWL_ERROR));
      }
    })
    .catch(err => dispatch(UiCreators.growl('Ocorreu um erro ao validar o código', growlTypes.GROWL_ERROR)));

  dispatch(UiCreators.hideLoader());
};

// User Helpers
const isUserAuth = triiderUser => !!(triiderUser && triiderUser.token && triiderUser.user && triiderUser.user.id);

export const isAuth = (state) => {
  const { triiderUser } = state.userArea.get('userArea').toJS();
  return isUserAuth(triiderUser);
};

export const getToken = (state) => {
  const { triiderUser } = state.userArea.get('userArea').toJS();
  return triiderUser.token;
};

export const getTriiderUser = (state) => {
  const { triiderUser } = state.userArea.get('userArea').toJS();
  return triiderUser;
};

export const getCompany = (state) => {
  const triiderUser = getTriiderUser(state) || {};

  return triiderUser.company_info;
};

export const getCoupouns = (state) => {
  const { coupons } = state.userArea.get('userArea').toJS();

  return coupons;
};

export const profileIncomplete = async (asked_info_at) => {
  if (!asked_info_at) return true;

  const { parameter } = await getParameter('USER-EXTRA-INTERVAL');

  if (!parameter) return true;

  // eslint-disable-next-line radix
  return differenceInDays(new Date(), new Date(asked_info_at)) > parseInt(parameter.value);
};

export const needValidatePin = async () => {
  const { parameter } = await getParameter('PHONE-VALIDATE-PIN') || {};

  if (!parameter) return false;

  const { value } = parameter;

  return JSON.parse(value);
};

export const askPhoneValidation = async (user) => {
  const { phone, flg_phone_validated } = user;
  const validatePinActive = await needValidatePin();

  return validatePinActive ? (!flg_phone_validated || !phone) : !phone;
};

export default reducer;
