import styled from 'styled-components';
import { colors } from 'style';

const size = 12;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: ${props => props.count * 2 * size}px;
`;

export const Dot = styled.a`
  cursor: pointer;
  width: ${size}px;
  height: ${size}px;
  border: 1px solid ${colors.greyLight};
  background: ${props => (props.active && colors.greyMedium) || colors.white};
  border-radius: 50%;
`;
