import api from './index';

export const getParameter = async (parameter) => {
  try {
    const { data } = await api.get(`/parameter/${parameter}`);
    return data;
  } catch ({ response }) {
    return response;
  }
};
