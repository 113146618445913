import api from './index';

export const apiValidateCouponByCode = async (couponCode) => {
  const { data } = await api.get(`/coupons/code/${couponCode}/validate`);
  if (!data.success) {
    throw new Error();
  }
  return data;
};

export const apiRedeemCouponToUser = async (userId, couponId) => {
  const { data } = await api.post(`/users/${userId}/coupons/${couponId}`);
  if (!data.success) {
    throw new Error();
  }
  return data;
};
