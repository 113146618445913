import { keyframes, css } from 'styled-components';

export const slides = {
  slideInLeft: keyframes`
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`,
  slideOutLeft: keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
`,

  slideInRight: keyframes`
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`,

  slideOutRight: keyframes`
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
`,
};

export const Animations = {
  slideInLeft: css`
    animation: ${slides.slideInLeft} 0.5s forwards;
  `,
  slideOutLeft: css`
    animation: ${slides.slideOutLeft} 0.5s forwards;
  `,
  slideInRight: css`
    animation: ${slides.slideInRight} 0.5s forwards;
  `,
  slideOutRight: css`
    animation: ${slides.slideOutRight} 0.5s forwards;
  `,
};
