import React from 'react';
import PropTypes from 'prop-types';

import ContainerContent from './Content';
import ContainerHeader from './Header';

import {
  ContainerStyle,
  FlexContainerStyled,
} from './styles';

const Container = ({ children, ...props }) => (
  <ContainerStyle {...props}>{children}</ContainerStyle>
);

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isFullMobile: PropTypes.bool,
};

export default Container;

export const FlexContainer = ({
  children,
  alignment,
  alignmentMobile,
  axis = 'row',
  padding,
  margin,
  size,
  hasHeader,
}) => (
  <FlexContainerStyled
    alignment={alignment}
    alignmentMobile={alignmentMobile}
    size={size}
    axis={axis}
    margin={margin}
    padding={padding}
    hasHeader={hasHeader}
  >
    {children}
  </FlexContainerStyled>
);

FlexContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  alignment: PropTypes.string,
  alignmentMobile: PropTypes.string,
  axis: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.string,
  hasHeader: PropTypes.bool,
};

export { ContainerContent, ContainerHeader };
