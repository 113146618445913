import api from './index';

/**
 * Returns the settings of rewards
 * @returns {object} { settings }
 */
export async function apiGetRewardsSettings() {
  const { data } = await api.get('/rewards');
  if (!data.success) {
    throw new Error(data.error);
  }
  return data;
}

/**
 * Returns the credits of user
 * @param {string} user_id
 * @returns {object} { credits }
 */
export async function apiGetUserCredits(user_id) {
  const { data } = await api.get(`/users/${user_id}/credits`);
  if (!data.success) {
    throw new Error(data.error);
  }
  return data;
}

/**
 * Returns rewards overview
 * @param {string} user_id
 * @returns {object} { overview }
 */
export async function apiGetRewardsOverview(user_id) {
  const { data } = await api.get(`/users/${user_id}/rewards`);
  if (!data.success) {
    throw new Error(data.error);
  }
  return data;
}

/**
 * Returns a list with credits of user
 * @param {string} user_id
 * @returns {object} { data }
 */
export async function apiListUserCredits(user_id) {
  const { data } = await api.get(`/get-rewards/user/${user_id}`);
  if (!data.success) {
    throw new Error(data.error);
  }
  return data;
}

/**
 * Returns a validation of code
 * @param {string} mgm_code
 * @returns {object}
 */
export async function apiCheckRewards(mgm_code, df) {
  const options = { headers: { df } };
  const { data } = await api.get(`/check-rewards/${mgm_code}`, options);
  if (!data.success) {
    throw new Error(data.error);
  }
  return data;
}
