import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from './Tooltip';

import {
  Wrapper, Content, Message, TextButton, Icon,
} from './styles';

const Popover = ({
  icon,
  children,
  textButton,
  active,
  onClick,
  onlyMobile,
  width,
  height,
  top,
  right,
  rightAfter,
  component,
  color,
  background,
}) => (
  <Wrapper onlyMobile={onlyMobile}>
    {!!icon && (
      <Icon onClick={onClick}>
        <img src={icon} alt="Mais informações" />
      </Icon>
    )}
    {component}
    <Content top={top} right={right} rightAfter={rightAfter} width={width} height={height} active={active} color={color} background={background}>
      <Message>{children}</Message>
      {!!textButton && <TextButton onClick={onClick}>{textButton}</TextButton>}
    </Content>
  </Wrapper>
);

Popover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  active: PropTypes.bool,
  onlyMobile: PropTypes.bool,
  textButton: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  right: PropTypes.number,
};

export { Tooltip };
export default Popover;
