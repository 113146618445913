import { fromJS } from 'immutable';
import axios from 'axios';

// Constants for the operations that can change the store.
const PREFIX = 'unsubscribe';
const SET_PROPERTY = `${PREFIX}/SET_PROPERT`;
const SET_DEFAULT = `${PREFIX}/SET_DEFAULT`;

const DEFAULT_STATE = fromJS({
  unsubscribe: {
    loading: false,
    showConfirmation: false,
  },
});

/** It must interpret the action and return a new state. */
const reducer = (state = DEFAULT_STATE, action = {}) => {
  const fns = {
    [SET_PROPERTY]: () =>
      state.setIn([PREFIX, action.property], fromJS(action.data)),

    [SET_DEFAULT]: () =>
      state.setIn([PREFIX], fromJS(DEFAULT_STATE)),
  };

  return (action.type in fns) ? fns[action.type]() : state;
};

/**
 * ACTION CREATORS
 */
export const fetchProperty = (property, data) => dispatch =>
  dispatch({ type: SET_PROPERTY, property, data });

export const setDefault = () => dispatch =>
  dispatch({ type: SET_DEFAULT });

export const unsubscribe = triiderUser => (dispatch) => {
  dispatch({ type: SET_PROPERTY, property: 'loading', data: true });

  const url = `${process.env.API_URL}/user/${triiderUser.user.id}/email-unsubscribe`;
  const options = { headers: { 'api-token': triiderUser.token } };

  axios.get(url, options)
    .then((response) => {
      if (response.data.success) {
        dispatch({ type: SET_PROPERTY, property: 'showConfirmation', data: true });
        dispatch({ type: SET_PROPERTY, property: 'loading', data: false });
      }
    })
    .catch(() => dispatch({ type: SET_PROPERTY, property: 'loading', data: false }));
};

export default reducer;
