import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  & span {
    opacity: 0.3;
  }

  & div {
    margin-left: 10px;
  }
`;

export const TooltipStyled = styled.div`
  display: flex;
  flex: 0;
  justify-content: center;
`;
