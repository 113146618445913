import React, { Component } from 'react';
import { SingleDatePicker, toMomentObject } from 'react-dates';
import format from 'helpers/dates';
import PropTypes from 'prop-types';

import { Container, CalendarHeader } from './styles';
import Select from '../Select';

export default class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFocused: false,
    };

    this.setDate = this.setDate.bind(this);
    this.returnYears = this.returnYears.bind(this);
    this.renderMonthElement = this.renderMonthElement.bind(this);
  }

  setDate(date) {
    const { onChange } = this.props;
    const parseDate = format(new Date(date), 'yyyy-MM-dd');

    if (onChange) {
      const target = { name: 'birthday', value: parseDate };
      onChange({ target });
    }
  }

  returnYears() {
    const years = [];
    const { minYear, maxYear } = this.props;

    for (let i = minYear; i >= maxYear; i--) {
      years.push({ value: i, label: i });
    }

    return [{ value: 0, label: 'Ano' }, ...years];
  }

  renderMonthElement({ month, onMonthSelect, onYearSelect }) {
    const months = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

    return (
      <CalendarHeader>
        <Select
          value={month.year()}
          onChange={e => onYearSelect(month, e.target.value)}
          options={this.returnYears()}
          size="small"
        />
        <Select
          value={month.month()}
          onChange={e => onMonthSelect(month, e.target.value)}
          options={months}
          size="small"
          id="select-month"
        />
      </CalendarHeader>
    );
  }

  render() {
    const { dateFocused } = this.state;
    const { dateStart, value } = this.props;

    return (
      <Container>
        <SingleDatePicker
          date={value ? toMomentObject(value) : null}
          onDateChange={this.setDate}
          focused={dateFocused}
          onFocusChange={({ focused }) => this.setState({ dateFocused: focused })}
          showDefaultInputIcon
          inputIconPosition="after"
          numberOfMonths={1}
          withPortal // Modal Style
          readOnly
          disableScroll
          placeholder=""
          id="datePicker"
          isOutsideRange={day => day.isAfter(dateStart)}
          renderMonthElement={this.renderMonthElement}
          initialVisibleMonth={() => toMomentObject(dateStart)}
          hideKeyboardShortcutsPanel
        />
      </Container>
    );
  }
}

DatePicker.propTypes = {
  dateStart: PropTypes.string,
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.string,
};
