import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import {
  SET_EMAIL,
  SET_TOKEN_VALID,
  SET_EMAIL_TOKEN_VALID,
  SET_NEW_PASSWORD,
  SET_CHANGE_PASSWORD,
  SET_USER_EDIT,
  SET_USER_PHOTO,
  SET_USER_SOCIAL_MEDIA,
  RESET_INITIAL_STATE,
} from './actions';

const initialState = fromJS({
  forgotPassword: {
    email: '',
    isTokenValid: false,
    newPassword: false,
  },
  emailValidation: {
    isTokenValid: false,
  },
  photo: '',
  userSocialMedia: {},
});

const reducer = handleActions(
  {
    [SET_EMAIL]: (state, action) => state.setIn(['forgotPassword', 'email'], action.payload),

    [SET_TOKEN_VALID]: (state, action) => state.setIn(['forgotPassword', 'isTokenValid'], action.payload),

    [SET_EMAIL_TOKEN_VALID]: (state, action) => state.setIn(['emailValidation', 'isTokenValid'], action.payload),

    [SET_NEW_PASSWORD]: (state, action) => state.setIn(['forgotPassword', 'newPassword'], action.payload),

    [SET_USER_SOCIAL_MEDIA]: (state, action) => state.set('userSocialMedia', fromJS(action.payload)),

    [RESET_INITIAL_STATE]: state => state
      .set('userSocialMedia', initialState.get('userSocialMedia'))
      .setIn('forgotPassword', 'isTokenValid', fromJS(false))
      .setIn('forgotPassword', 'email', fromJS('')),

    [SET_CHANGE_PASSWORD]: (state, action) => state.setIn(['changePassword', 'password'], fromJS(action.payload)),

    [SET_USER_EDIT]: (state, action) => state.setIn(['userEdit', 'user'], fromJS(action.payload)),

    [SET_USER_PHOTO]: (state, action) => state.setIn(['photo'], fromJS(action.payload))
  },
  initialState,
);

export default reducer;
