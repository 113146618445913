import api from './index';

export const getUserCoupons = async (user) => {
  const url = `/users/${user.id}/coupons`;

  try {
    const { data } = await api.get(url);

    if (!data.success) {
      throw new Error();
    }

    return data;
  } catch (err) {
    throw err;
  }
};

export const addUserCoupon = async (code) => {
  const url = '/coupons/redeem';

  try {
    const { data } = await api.post(url, { code });

    if (!data.success) {
      throw new Error();
    }

    return data;
  } catch (err) {
    throw err;
  }
};
