export const colors = {
  primary: '#FF7700',
  secondary: '#4983ef',

  action: '#EF7E31',

  danger: '#D83430',
  success: '#59BC5C',
  info: '#3E81F7',

  blueDark: '#3D578E',

  white: '#FFFFFF',
  transparent: 'transparent',

  greyLighter: '#EEEEEE',
  greyLight: '#CBCBCB',
  greyMedium: '#A1A1A1',
  greyDark: '#565656',
  grey: '#6D6D6D',

  opaque: '#FFCB9E',
  salmon: '#F57F7C',
};
