import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { triiderRegisterAccount } from 'reducers/auth/actions';
import { validateUserFields } from 'helpers/userValidations';

import InputText from 'components/InputText';
import Button from 'components/Button';
import StyledLink from 'components/StyledLink';
import Title, { Subtitle } from 'components/Title';
import Dots from 'components/Dots';
import SpotlightImage from 'components/SpotlightImage';
import Popover from 'components/Popover';

import imgMail from 'static/img/icons/mail-spotlight.svg';
import iconQuestion from 'static/img/icons/question.svg';

import iconMessage from 'static/img/icons/message.svg';
import iconUser from 'static/img/icons/user.svg';
import StepWrapper from '../StepWrapper';

import { HeaderWrapper, ContentWrapper, FooterWrapper } from './styles';

class Account extends React.Component {
  state = {
    user: {},
    errors: {},
    popoverVisible: false,
    isSocialMedia: false,
  };

  componentDidMount() {
    const { userSocialMedia } = this.props;
    const { email } = userSocialMedia;
    const isSocialMedia = !!email;

    const user = isSocialMedia
      ? { email }
      : {
        email: '',
        name: '',
        password: '',
      };

    this.setState({ isSocialMedia, user });
  }

  togglePopover = () => {
    const { popoverVisible } = this.state;

    this.setState({ popoverVisible: !popoverVisible });
  };

  setFields = ({ target }) => {
    const { name, value } = target;
    const { user } = this.state;

    this.setState({ user: { ...user, [name]: value } });
  };

  validateField = ({ target }) => {
    const { name, value } = target;

    const message = validateUserFields(name, value);

    const { errors: oldErrors } = this.state;

    const errors = {
      ...oldErrors,
      [name]: message,
    };

    this.setState({ errors, inputFocus: false });
  };

  nextStep = (event) => {
    event.preventDefault();

    const { user, errors: oldErrors, isSocialMedia } = this.state;

    const validation = {};
    Object.entries(user).forEach(([key, value]) => {
      Object.assign(validation, { [key]: validateUserFields(key, value) });
    });

    const errors = Object.assign(oldErrors, validation);

    if (!Object.values(errors).some(e => !!e)) {
      const { _triiderRegisterAccount, userSocialMedia } = this.props;
      if (isSocialMedia) {
        Object.assign(userSocialMedia, user);
        _triiderRegisterAccount(userSocialMedia);
      } else _triiderRegisterAccount(user);
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { isLoading } = this.props;
    const {
      user: { name, email, password },
      errors,
      popoverVisible,
      isSocialMedia,
    } = this.state;
    const buttonDisabled = Object.values(errors).some(e => !!e);
    const dots = [{ active: true }, { active: false }, { active: false }];

    const msgPopover = 'Enviaremos e-mails te avisando sobre tudo que acontece com o seu pedido: orçamentos recebidos, mensagens e atualizações em geral. É importante manter essa informação atualizada.';

    return (
      <StepWrapper onSubmit={this.nextStep}>
        <ContentWrapper isSocialMedia={isSocialMedia}>
          <HeaderWrapper isSocialMedia={isSocialMedia}>
            <Dots dots={dots} />
            {isSocialMedia ? (
              <Fragment>
                <SpotlightImage src={imgMail} alt="Confirme seu e-mail" />
                <Title>
                  Confirmar e-mail
                  {' '}
                  <Popover
                    onlyMobile
                    width={300}
                    height={200}
                    right={-100}
                    onClick={this.togglePopover}
                    active={popoverVisible}
                    icon={iconQuestion}
                    textButton="FECHAR"
                  >
                    {msgPopover}
                  </Popover>
                </Title>
                <Subtitle onlyDesktop>{msgPopover}</Subtitle>
              </Fragment>
            ) : (
              <Title>Crie a sua conta:</Title>
            )}
          </HeaderWrapper>
          <InputText
            placeholder={(
              <Fragment>
                Para qual
                {' '}
                <b>e-mail</b>
                {' '}
devemos
                {' '}
                <b>enviar os orçamentos e atualizações</b>
                {' '}
do
                pedido?
              </Fragment>
)}
            type="email"
            name="email"
            value={email}
            error={errors.email}
            scrollView={false}
            onChange={this.setFields}
            onBlur={this.validateField}
            iconRight={iconMessage}
            autoComplete="false"
          />

          {!isSocialMedia && (
            <Fragment>
              <InputText
                placeholder="Qual é o seu nome?"
                type="text"
                name="name"
                value={name}
                error={errors.name}
                scrollView={false}
                onChange={this.setFields}
                onBlur={this.validateField}
                iconRight={iconUser}
                autoComplete="off"
              />

              <InputText
                placeholder="Crie uma senha:"
                type="password"
                name="password"
                value={password}
                error={errors.password}
                scrollView={false}
                onChange={(e) => {
                  this.setFields(e);
                  this.validateField(e);
                }}
                onBlur={this.validateField}
                autoComplete="off"
                isPasswordRegister
              />

              <p>
                Ao criar uma conta no Triider você concorda com os nossos
                {' '}
                <StyledLink href="/termos-de-uso/" target="_blank" active>
                  termos de uso
                </StyledLink>
                {/* {' '}
            e nosso
            {' '}
            <StyledLink active>código de conduta</StyledLink> */}
                .
              </p>
            </Fragment>
          )}
        </ContentWrapper>

        <FooterWrapper>
          <Button isLoading={isLoading} disabled={buttonDisabled} type="submit" size="block">
            {isSocialMedia ? 'Próxima' : 'Criar usuário'}
          </Button>
        </FooterWrapper>
      </StepWrapper>
    );
  }
}

Account.propTypes = {
  isLoading: PropTypes.bool,
  userSocialMedia: PropTypes.instanceOf(Object),
  _triiderRegisterAccount: PropTypes.func,
};

const mapStateToProps = state => ({
  isLoading: state.ui.loaders.includes('GLOBAL'),
  userSocialMedia: state.auth.get('userSocialMedia').toJS(),
});

const mapDispatchToProps = {
  _triiderRegisterAccount: triiderRegisterAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Account);
