const INITIAL_STATE = {
  hiring: {
    quote: {},
    task: {},
    creditCards: [],
    installments: 1,
    coupon: {},
    coupons: [],
    businessTask: {},
  },
  taskDetail: {},
  tasks: [],
  reportProblems: [],
  task_detail: {},
};

/**
 * Types
 */
export const Types = {
  GET_TASKS_BY_USER_REQUEST: 'task/GET_TASKS_BY_USER_REQUEST',
  GET_TASKS_BY_USER_SUCCESS: 'task/GET_TASKS_BY_USER_SUCCESS',
  GET_HIRING_DATA_REQUEST: 'task/GET_HIRING_DATA_REQUEST',
  GET_HIRING_DATA_SUCCESS: 'task/GET_HIRING_DATA_SUCCESS',
  GET_PAYMENT_DATA_REQUEST: 'task/GET_PAYMENT_DATA_REQUEST',
  SAVE_CREDIT_CARD_REQUEST: 'task/SAVE_CREDIT_CARD_REQUEST',
  SAVE_CREDIT_CARD_SUCCESS: 'task/SAVE_CREDIT_CARD_SUCCESS',
  SET_INSTALLMENTS_REQUEST: 'task/SET_INSTALLMENTS_REQUEST',
  SET_INSTALLMENTS_SUCCESS: 'task/SET_INSTALLMENTS_SUCCESS',
  SET_CREDITS_REQUEST: 'task/SET_CREDITS_REQUEST',
  SET_COUNPON_REQUEST: 'task/SET_COUNPON_REQUEST',
  SET_QUOTE: 'task/SET_QUOTE',
  UPDATE_USER_FIELDS_REQUEST: 'task/UPDATE_USER_FIELDS_REQUEST',
  FINISH_HIRING_REQUEST: 'task/FINISH_HIRING_REQUEST',
  GET_PAYMENT_PROBLEMS_REQUEST: 'task/GET_PAYMENT_PROBLEMS_REQUEST',
  GET_PAYMENT_PROBLEMS_SUCCESS: 'task/GET_PAYMENT_PROBLEMS_SUCCESS',
  REPORT_PROBLEM_REQUEST: 'task/REPORT_PROBLEM_REQUEST',
  SET_TASK_PAID_REQUEST: 'task/SET_TASK_PAID_REQUEST',
  GET_TASK_BY_ID_REQUEST: 'task/GET_TASK_BY_ID_REQUEST',
  GET_TASK_BY_ID_SUCCESS: 'task/GET_TASK_BY_ID_SUCCESS',
};

/**
 * Reducer
 */
export default function task(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_TASKS_BY_USER_SUCCESS: {
      return { ...state, tasks: action.payload };
    }
    case Types.GET_HIRING_DATA_SUCCESS: {
      const hiring = Object.assign(state.hiring, action.payload);
      return { ...state, hiring };
    }
    case Types.SAVE_CREDIT_CARD_SUCCESS: {
      const creditCards = state.hiring.creditCards.map(c => ({ ...c, selected: false }));
      creditCards.push(action.payload);
      return { ...state, hiring: { ...state.hiring, creditCards } };
    }
    case Types.SET_INSTALLMENTS_SUCCESS: {
      return {
        ...state,
        hiring: { ...state.hiring, task: { ...state.hiring.task, installments: action.payload } },
      };
    }
    case Types.SET_QUOTE: {
      return {
        ...state,
        hiring: { ...state.hiring, quote: action.payload },
      };
    }
    case Types.GET_PAYMENT_PROBLEMS_SUCCESS: {
      return {
        ...state,
        reportProblems: action.payload,
      };
    }
    case Types.GET_TASK_BY_ID_SUCCESS: {
      return {
        ...state,
        task_detail: action.payload,
      };
    }
    default:
      return state;
  }
}

/**
 * Actions Creators
 */
export const Creators = {
  getTasksByUser: payload => ({
    type: Types.GET_TASKS_BY_USER_REQUEST,
    payload,
  }),
  getTasksByUserSuccess: payload => ({
    type: Types.GET_TASKS_BY_USER_SUCCESS,
    payload,
  }),
  getHiringDataRequest: payload => ({
    type: Types.GET_HIRING_DATA_REQUEST,
    payload,
  }),
  getHiringDataSuccess: payload => ({
    type: Types.GET_HIRING_DATA_SUCCESS,
    payload,
  }),
  getPaymentDataRequest: payload => ({
    type: Types.GET_PAYMENT_DATA_REQUEST,
    payload,
  }),
  saveCreditCardRequest: payload => ({
    type: Types.SAVE_CREDIT_CARD_REQUEST,
    payload,
  }),
  saveCreditCardSuccess: payload => ({
    type: Types.SAVE_CREDIT_CARD_SUCCESS,
    payload,
  }),
  setInstallmentsRequest: payload => ({
    type: Types.SET_INSTALLMENTS_REQUEST,
    payload,
  }),
  setInstallmentsSuccess: payload => ({
    type: Types.SET_INSTALLMENTS_SUCCESS,
    payload,
  }),
  setCreditsRequest: payload => ({
    type: Types.SET_CREDITS_REQUEST,
    payload,
  }),
  setCouponRequest: payload => ({
    type: Types.SET_COUNPON_REQUEST,
    payload,
  }),
  setQuote: payload => ({
    type: Types.SET_QUOTE,
    payload,
  }),
  updateUserFieldsRequest: payload => ({
    type: Types.UPDATE_USER_FIELDS_REQUEST,
    payload,
  }),
  finishHiringRequest: payload => ({
    type: Types.FINISH_HIRING_REQUEST,
    payload,
  }),
  getPaymentProblemsRequest: payload => ({
    type: Types.GET_PAYMENT_PROBLEMS_REQUEST,
    payload,
  }),
  getPaymentProblemsSuccess: payload => ({
    type: Types.GET_PAYMENT_PROBLEMS_SUCCESS,
    payload,
  }),
  reportProblemRequest: payload => ({
    type: Types.REPORT_PROBLEM_REQUEST,
    payload,
  }),
  setTaskPaidRequest: payload => ({
    type: Types.SET_TASK_PAID_REQUEST,
    payload,
  }),
  getTaskByIdRequest: payload => ({ type: Types.GET_TASK_BY_ID_REQUEST, payload }),
  getTaskByIdSuccess: payload => ({ type: Types.GET_TASK_BY_ID_SUCCESS, payload }),
};
