import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Spinner = ({ size, color }) => (
  <Container size={size} color={color}>
    <div />
    <div />
    <div />
    <div />
  </Container>
);

Spinner.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default Spinner;
