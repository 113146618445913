import styled, { css } from 'styled-components';
import { colors, device } from 'style';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;

  @media ${device.fromTablet} {
    ${props => props.onlyMobile
      && css`
        display: none !important;
      `}
  }
`;

export const Icon = styled.div`
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

export const Content = styled.div`
  ${props => (props.active
    ? css`
          z-index: 10;
          opacity: 1;
          visibility: visible;
          transform: translate(0, -20px);
          transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
        `
    : css`
          z-index: -1;
          opacity: 0;
          visibility: none;
          transform: translate(0, 20px);
          transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
        `)}

  color: ${({ color }) => color || colors.greyDark};
  font-family: 'Montserrat';
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: ${props => (props.right ? `${props.right}px` : 'auto')};
  top: ${props => (props.top ? `${props.top}px` : 'auto')};
  transform: translate(0, 10px);
  background-color: ${({ background }) => background || colors.white};

  font-size: 14px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border: 1px solid ${({ background }) => background || colors.greyLighter};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -8px;
    right: ${({ right, rightAfter }) => rightAfter || (right + 1) * -1}px;
    width: 15px;
    height: 15px;
    background: ${({ background }) => background || colors.white};
    border-top:1px solid ${({ background }) => background || colors.greyLighter};
    border-right:1px solid ${({ background }) => background || colors.greyLighter};
    transform:rotate(-45deg);
  }
  /* &:before {
    position: absolute;
    z-index: -1;
    content: '';
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent ${colors.white} transparent;
    transition-duration: 0.3s;
    transition-property: transform;
  } */
`;

export const Message = styled.p`
  text-align: justify;
  line-height: 24px;
  width: 90%;
  margin: 0;
  padding: 0;
`;

export const TextButton = styled.a`
  color: ${colors.primary} !important;
  width: 90%;
  text-align: right;
  cursor: pointer;
`;
