import styled from 'styled-components';
import { colors } from 'style';

export const Container = styled.div`
  flex: 1;

  /*  DATEPICKER  */
  & .SingleDatePicker * {
    cursor: pointer;
  }

  & .SingleDatePicker {
    width: 100%;
    height: inherit;
    min-height: inherit;
    max-height: inherit;
    & .SingleDatePickerInput {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      height: inherit;
      min-height: inherit;
      max-height: inherit;
      border: none;
      border-radius: none;
      & .DateInput {
        width: 100%;
        display: flex;
        & .DateInput_input {
          flex: 1;
          font-size: 14px !important;
          line-height: 24px;
          font-family: inherit;
          font-weight: normal;
          padding-left: 10px;
        }
      }

      & .SingleDatePickerInput_calendarIcon {
        padding: 5px;
        margin: 5px;
        & svg {
          fill: ${colors.greyMedium};
          height: 20px;
          width: 20px;
        }
      }
    }
  }
`;

export const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
