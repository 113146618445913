import {
  all, takeEvery, takeLatest, takeLeading,
} from 'redux-saga/effects';

/**
 * Ui
 */
import { Types as UiTypes } from '../ducks/ui';
import { growl } from './ui';

/**
 * Service Form
 */
import { Types as FormTypes } from '../ducks/serviceForm';
import { finishForm, processTask, saveLead } from './serviceForm';

/**
 * Chat
 */
import { Types as ChatTypes } from '../ducks/chat';
import {
  initChat,
  sendMessage,
  startTyping,
  stopTyping,
  readMessages,
  uploadImage,
  disconect,
  removeMessages,
} from './chat';

/**
 * Task
 */
import { Types as TaskTypes } from '../ducks/task';
import {
  getTasks,
  getHiringData,
  getPaymentData,
  saveCreditCard,
  setInstallments,
  setTaskCredits,
  setTaskCoupon,
  finishHiring,
  updateUserFields,
  getPaymentProblems,
  reportProblem,
  setTaskPaid,
  getTaskById,
} from './task';

/**
 * Rewards
 */
import { Types as RewardsTypes } from '../ducks/rewards';
import {
  getRewardsSettings,
  getUserCredits,
  getRewardsOverview,
  getListUserCredits,
  checkRewards,
} from './rewards';

/**
 * Settings
 */
import { Types as SettingsTypes } from '../ducks/settings';
import { getBanners } from './settings';

/**
 * Coupons
 */
import { Types as CouponsTypes } from '../ducks/coupons';
import { validateCoupons, redeemCouponToUser } from './coupons';

/**
 * Rating
 */
 import { Types as RatingTypes } from '../ducks/rating';
 import { getLabels, setRating } from './rating';

export default function* rootSaga() {
  yield all([
    /**
     * Ui
     */
    takeEvery(UiTypes.GROWL_ADDED, growl),

    /**
     * Service Form
     */
    takeLatest(FormTypes.FINISH_FORM_REQUEST, finishForm),
    takeLatest(FormTypes.PROCESS_TASK, processTask),
    takeLatest(FormTypes.SAVE_LEAD, saveLead),

    /**
     * Chat
     */
    takeEvery(ChatTypes.INIT_CHAT_REQUEST, initChat),
    takeEvery(ChatTypes.SEND_MESSAGE, sendMessage),
    takeEvery(ChatTypes.REMOVE_MESSAGE_REQUEST, removeMessages),
    takeEvery(ChatTypes.START_TYPING, startTyping),
    takeEvery(ChatTypes.STOP_TYPING, stopTyping),
    takeLeading(ChatTypes.READ_MESSAGES, readMessages),
    takeLatest(ChatTypes.UPLOAD_IMAGE_REQUEST, uploadImage),
    takeEvery(ChatTypes.DISCONECT_CHAT_REQUEST, disconect),

    /**
     * Task
     */
    takeLeading(TaskTypes.GET_TASKS_BY_USER_REQUEST, getTasks),
    takeLeading(TaskTypes.GET_HIRING_DATA_REQUEST, getHiringData),
    takeLeading(TaskTypes.GET_PAYMENT_DATA_REQUEST, getPaymentData),
    takeLeading(TaskTypes.SAVE_CREDIT_CARD_REQUEST, saveCreditCard),
    takeLeading(TaskTypes.SET_INSTALLMENTS_REQUEST, setInstallments),
    takeLeading(TaskTypes.SET_CREDITS_REQUEST, setTaskCredits),
    takeLeading(TaskTypes.SET_COUNPON_REQUEST, setTaskCoupon),
    takeLeading(TaskTypes.UPDATE_USER_FIELDS_REQUEST, updateUserFields),
    takeLeading(TaskTypes.FINISH_HIRING_REQUEST, finishHiring),
    takeLeading(TaskTypes.GET_PAYMENT_PROBLEMS_REQUEST, getPaymentProblems),
    takeLeading(TaskTypes.REPORT_PROBLEM_REQUEST, reportProblem),
    takeLeading(TaskTypes.SET_TASK_PAID_REQUEST, setTaskPaid),
    takeLeading(TaskTypes.GET_TASK_BY_ID_REQUEST, getTaskById),

    /**
     * Rewards
     */
    takeLeading(RewardsTypes.GET_SETTINGS_REQUEST, getRewardsSettings),
    takeLeading(RewardsTypes.GET_USER_CREDITS_REQUEST, getUserCredits),
    takeLeading(RewardsTypes.GET_REWARDS_OVERVIEW_REQUEST, getRewardsOverview),
    takeLeading(RewardsTypes.GET_LIST_USER_CREDITS_REQUEST, getListUserCredits),
    takeLeading(RewardsTypes.CHECK_REWARDS_REQUEST, checkRewards),

    /**
     * Settings
     */
    takeLeading(SettingsTypes.GET_BANNERS_REQUEST, getBanners),

    /**
     * Cupons
     */
    takeLeading(CouponsTypes.VALIDATE_COUPONS_REQUEST, validateCoupons),
    takeLeading(CouponsTypes.REDEEM_COUPONS_REQUEST, redeemCouponToUser),
    
    /**
     * Rating Labels
    */
    takeLatest(RatingTypes.GET_RATING_LABEL_REQUEST, getLabels),
    takeLatest(RatingTypes.SET_RATING_REQUEST, setRating),

  ]);
}
