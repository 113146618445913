import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { connect } from 'react-redux';
import { Modal } from 'reactstrap';

import { Creators as UiCreators } from 'store/ducks/ui';

import { ButtonClose } from './styles';
import './modal.less';

class ModalGlobal extends Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    const { _hidemodal } = this.props;
    _hidemodal();
  }

  render() {
    const {
      isCentered,
      isFullMobile,
      children,
      modalVisible,
      modalClassName = '',
      ...otherProps
    } = this.props;

    return (
      <Modal
        modalClassName={cn(
          isCentered && 'modal-centered',
          isFullMobile && 'modal-full-mobile',
          modalClassName,
        )}
        isOpen={modalVisible}
        {...otherProps}
      >
        <ButtonClose onClick={this.closeModal}><span aria-hidden="true">×</span></ButtonClose>
        {children}
      </Modal>
    );
  }
}

ModalGlobal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  modalVisible: PropTypes.bool,
  modalClassName: PropTypes.string,
  isCentered: PropTypes.bool,
  isFullMobile: PropTypes.bool,
  _hidemodal: PropTypes.func,
};

const mapStateToProps = state => ({ modalVisible: state.ui.modalVisible });
const mapDispatchToProps = { _hidemodal: UiCreators.hideModal };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalGlobal);
