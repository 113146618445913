import styled, { css } from 'styled-components';
import { colors, device } from 'style';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Quicksand';
  min-height: 40px;

  @media ${device.untilTablet} {
    flex: 1;
    max-height: 80px;
  }

  ${props => props.headerDesktop
    && css`
      display: none;
      @media ${device.fromTablet} {
        display: flex;
      }
    `}

  ${props => props.centered
    && css`
      justify-content: center;

      & h1,
      & h2 {
        text-align: center;
      }
    `}
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  margin-left: .5rem;

  & h1 {
    color: ${colors.greyDark};
    font-weight: 500;
    font-size: 1rem;
    font-style: normal;
    line-height: normal;
    margin-bottom: .2rem;

    @media ${device.fromTablet} {
      font-size: 1.2rem;
    }
  }

  & h2 {
    color: ${colors.greyDark};
    font-weight: normal;
    font-size: 0.8rem;
    font-style: normal;
    line-height: 1.1rem;
    margin-bottom: .9rem;

    @media ${device.fromTablet} {
      font-size: .9rem;
    }
  }
`;
