export const errMsg = (msg) => {
  if (msg === 'Coupon not found') {
    return 'Cupom não encontrado';
  }
  if (msg === 'Invalid coupon') {
    return 'Cupom inválido';
  }
  if (msg === 'Coupon expired') {
    return 'Cupom expirado';
  }
  if (msg === 'has selected quotes') {
    return 'Este cupom é valido apenas para o primeiro pedido feito no Triider.';
  }
  if (msg === 'first use already registered') {
    return 'Este cupom é valido apenas para o primeiro pedido feito no Triider.';
  }

  return 'Ops! Ocorreu um erro';
};
