import { genderOptions } from 'helpers/userValidations';

export const fields = {
    perfil: [
        {
            placeholder: 'Nome:',
            name: 'name'
        },
        {
            placeholder: 'Email:',
            name: 'email'
        },
        {
            placeholder: 'Telefone:',
            name: 'phone',
            isPhone: true
        },
        {
            placeholder: 'Cidade:',
            name: 'city_name',
            value: 'city',
            isCity: true
        },
        {
            placeholder: 'Aniversário:',
            name: 'birthday',
            isDate: true
        },
        {
            placeholder: 'Gênero:',
            name: 'gender',
            options: genderOptions,
            isGender: true
        },
    ],
    // helper: [
    //     'bussiness_bank_account'
    // ]
}
