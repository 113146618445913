import React from 'react';
import PropTypes from 'prop-types';

import { SelectStyled, Label } from './styles';

const Select = ({
  options, placeholder, value, size = 'default', isFormField, perfil, ...props
}) => {
  const hasValue = !!value;

  return (
    <SelectStyled perfil={perfil} size={size} hasValue={hasValue} isFormField={isFormField}>
      {!!placeholder && <Label>{placeholder}</Label>}
      <select value={value} {...props}>
        {options.map((o, index) => {
          const val = typeof o === 'object' ? o.value : index;
          const label = typeof o === 'object' ? o.label : o;
          return (
            <option key={`${val}-option-${index}`} value={val}>
              {label}
            </option>
          );
        })}
      </select>
      {/* {hasError && !focus) && (
          <InputStatus success={isPassword && !(value.length < 6)} hasError={hasError}>
            {message}
          </InputStatus>
        )} */}
    </SelectStyled>
  );
};

Select.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  ]),
  placeholder: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  size: PropTypes.string,
  isFormField: PropTypes.bool,
};

export default Select;
