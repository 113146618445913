const INITIAL_STATE = {
  chats: [],
  socket: null,
  isTyping: false,
  files: [],
};

/**
 * Types
 */
export const Types = {
  SEND_MESSAGE: 'chat/SEND_MESSAGE',
  INCLUDE_MESSAGE: 'chat/INCLUDE_MESSAGE',
  SWITCH_MESSAGE: 'chat/SWITCH_MESSAGE',
  REMOVE_MESSAGE_REQUEST: 'chat/REMOVE_MESSAGE_REQUEST',
  REMOVE_MESSAGE_SUCCESS: 'chat/REMOVE_MESSAGE_SUCCESS',
  MESSAGE_RECEIVED: 'chat/MESSAGE_RECEIVED',
  MESSAGE_READ: 'chat/MESSAGE_READ',
  READ_MESSAGES: 'chat/READ_MESSAGES',
  START_TYPING: 'chat/START_TYPING',
  SELF_START_TYPING: 'chat/SELF_START_TYPING',
  USER_START_TYPING: 'chat/USER_START_TYPING',
  STOP_TYPING: 'chat/STOP_TYPING',
  SELF_STOP_TYPING: 'chat/SELF_STOP_TYPING',
  USER_STOP_TYPING: 'chat/USER_STOP_TYPING',
  INIT_CHAT_REQUEST: 'chat/INIT_CHAT_REQUEST',
  INIT_CHAT_SUCCESS: 'chat/INIT_CHAT_SUCCESS',
  DISCONECT_CHAT_REQUEST: 'chat/DISCONECT_CHAT_REQUEST',
  DISCONECT_CHAT: 'chat/DISCONECT_CHAT',
  REMOVE_CHATS: 'chat/REMOVE_CHATS',
  ADD_FILES: 'chat/ADD_FILES',
  CLEAR_FILES: 'chat/CLEAR_FILES',
  UPLOAD_IMAGE_REQUEST: 'chat/UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'chat/UPLOAD_IMAGE_SUCCESS',
  SET_SOCKET: 'chat/SET_SOCKET',
};

/**
 * Helpers
 */
export const getSocket = (state, chat_id) => {
  const { socket } = state.chat.chats.find(c => c.id === chat_id) || {};
  return socket;
};

/**
 * Reducer
 */
export default function chat(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.INCLUDE_MESSAGE:
    case Types.MESSAGE_RECEIVED: {
      const { payload: message } = action;
      const { chat_id } = message;
      const chats = state.chats.map((c) => {
        if (c.id === chat_id) return { ...c, messages: [...c.messages, message] };
        return c;
      });
      return { ...state, chats };
    }

    case Types.SWITCH_MESSAGE: {
      const {
        payload: { message: newMessage, message_id },
      } = action;
      const { chat_id } = newMessage;
      const chats = state.chats.map(chatValue => (
        chatValue.id === chat_id
          ? {
            ...chatValue,
            messages: chatValue.messages.map(
              msgOld => (msgOld.id === message_id ? newMessage : msgOld),
            ),
          }
          : chatValue
      ));
      return { ...state, chats };
    }

    case Types.REMOVE_MESSAGE_SUCCESS: {
      const {
        payload: { chat_id, message_id },
      } = action;
      const chats = state.chats.map(chatValue => (
        chatValue.id === chat_id
          ? {
            ...chatValue,
            messages: chatValue.messages.filter(msgOld => msgOld.id !== message_id),
          }
          : chatValue
      ));
      return { ...state, chats };
    }

    case Types.MESSAGE_READ: {
      const {
        payload: { messages, chat_id },
      } = action;
      const chats = state.chats.map((c) => {
        if (c.id === chat_id) {
          return {
            ...c,
            messages: c.messages.map((msg) => {
              const message = messages.find(
                m => (m.text === msg.text && m.id === msg.id) || (m.text === msg.text && !m.id),
              );
              if (message) {
                return { ...msg, is_read: true };
              }
              return msg;
            }),
          };
        }
        return c;
      });
      return { ...state, chats };
    }

    case Types.SELF_START_TYPING: {
      return { ...state, isTyping: true };
    }

    case Types.SELF_STOP_TYPING: {
      return { ...state, isTyping: false };
    }

    case Types.USER_START_TYPING: {
      const {
        payload: { chat_id },
      } = action;
      const chats = state.chats.map(c => (c.id === chat_id ? { ...c, userTyping: true } : c));

      return { ...state, chats };
    }

    case Types.USER_STOP_TYPING: {
      const {
        payload: { chat_id },
      } = action;
      const chats = state.chats.map(c => (c.id === chat_id ? { ...c, userTyping: false } : c));

      return { ...state, chats };
    }

    case Types.INIT_CHAT_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        chats: [...payload],
      };
    }

    case Types.DISCONECT_CHAT:
      return { ...state, chats: INITIAL_STATE.chats, socket: INITIAL_STATE.socket };

    case Types.REMOVE_CHATS:
      return { ...state, chats: INITIAL_STATE.chats };

    case Types.ADD_FILES: {
      const { payload } = action;
      return { ...state, files: [...payload] };
    }

    case Types.CLEAR_FILES:
      return { ...state, files: INITIAL_STATE.files };

    case Types.SET_SOCKET: {
      const { payload } = action;
      return { ...state, socket: payload };
    }

    default:
      return state;
  }
}

/**
 * Actions Creators
 */
export const Creators = {
  sendMessage: payload => ({
    type: Types.SEND_MESSAGE,
    payload,
  }),
  includeMessage: payload => ({
    type: Types.INCLUDE_MESSAGE,
    payload,
  }),
  switchMessage: (message, message_id) => ({
    type: Types.SWITCH_MESSAGE,
    payload: {
      message, message_id,
    },
  }),
  removeMessageRequest: payload => ({
    type: Types.REMOVE_MESSAGE_REQUEST,
    payload,
  }),
  removeMessageSuccess: payload => ({
    type: Types.REMOVE_MESSAGE_SUCCESS,
    payload,
  }),
  messageReceived: payload => ({
    type: Types.MESSAGE_RECEIVED,
    payload,
  }),
  messageRead: payload => ({
    type: Types.MESSAGE_READ,
    payload,
  }),
  readMessages: payload => ({
    type: Types.READ_MESSAGES,
    payload,
  }),
  startTyping: payload => ({
    type: Types.START_TYPING,
    payload,
  }),
  stopTyping: payload => ({
    type: Types.STOP_TYPING,
    payload,
  }),
  selfStartTyping: payload => ({
    type: Types.SELF_START_TYPING,
    payload,
  }),
  selfStopTyping: payload => ({
    type: Types.SELF_STOP_TYPING,
    payload,
  }),
  userStartTyping: payload => ({
    type: Types.USER_START_TYPING,
    payload,
  }),
  userStopTyping: payload => ({
    type: Types.USER_STOP_TYPING,
    payload,
  }),
  initChatRequest: task_id => ({
    type: Types.INIT_CHAT_REQUEST,
    payload: { task_id },
  }),
  initChatSuccess: payload => ({
    type: Types.INIT_CHAT_SUCCESS,
    payload,
  }),
  disconectChatRequest: payload => ({
    type: Types.DISCONECT_CHAT_REQUEST,
    payload,
  }),
  disconectChat: payload => ({
    type: Types.DISCONECT_CHAT,
    payload,
  }),
  removeChats: payload => ({
    type: Types.REMOVE_CHATS,
    payload,
  }),
  addFiles: payload => ({
    type: Types.ADD_FILES,
    payload,
  }),
  clearFiles: payload => ({
    type: Types.CLEAR_FILES,
    payload,
  }),
  uploadImageRequest: payload => ({
    type: Types.UPLOAD_IMAGE_REQUEST,
    payload,
  }),
  uploadImageSuccess: payload => ({
    type: Types.UPLOAD_IMAGE_SUCCESS,
    payload,
  }),
  setSocket: payload => ({
    type: Types.SET_SOCKET,
    payload,
  }),
};
