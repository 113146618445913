import {
  call, put, all, select,
} from 'redux-saga/effects';

import { apiSendTask } from 'services/api/task';

import { apiSaveLead } from 'services/api/leads';

import { updateUser } from 'services/api/user';
import {
  apiIncludeAddress,
  apiEditAddress,
  apiIncludeAddressCompany,
} from 'services/api/address';


import { formatAddress } from 'utils/address';
import { getErrorMessage } from 'utils/filters';

import { Creators as FormCreators } from '../ducks/serviceForm';
import { Creators as UiCreators, growlTypes } from '../ducks/ui';

import { isAuth, getTriiderUser } from '../../reducers/userArea';

const fileName = 'sagas/serviceForm';
const SET_TRIIDER_USER_ORIGIN = 'userArea/SET_TRIIDER_USER_ORIGIN';

export function* processTask({ payload = {} }) {
  try {
    const task = yield JSON.parse(localStorage.getItem('task'));

    const parseAddressTask = (address_detail) => {
      Object.assign(task, {
        address: formatAddress(address_detail),
        address_id: address_detail.id,
        address_detail,
      });
    };

    // quando pedido vir da página do parceiro isPartner = true
    const isPartner = yield localStorage.getItem('isPartner');

    yield put(UiCreators.showLoader('SERVICE_FORM'));
    const { triiderUser, isRegister } = payload;

    const state = yield select();

    const hasTaskInProgress = localStorage.getItem('hasTaskInProgress');

    if (hasTaskInProgress) {
      const user = triiderUser ? triiderUser.user : getTriiderUser(state).user;
      try {
        const {
          address_detail, company_id, address_id,
        } = task;

        // id do endereço
        const idOfAddress = address_id || (address_detail && address_detail.id);

        // quando pedido B2B
        if (company_id) {
          if (!idOfAddress) {
            // criar endereço quando não tem id e add user_id
            const { address: adr } = yield call(
              apiIncludeAddressCompany, { ...address_detail, user_id: user.id }, company_id,
            );
            parseAddressTask(adr);
          } else if (
            !address_detail.user_id
              || (address_detail.user_id && address_detail.user_id !== user.id)
          ) {
            // editar endereço quando usuário do endereço for diferente do pedioo
            const { address: adr } = yield call(
              apiEditAddress,
              { id: idOfAddress, user_id: user.id },
              company_id,
            );
            parseAddressTask(adr);
          }
        } else if (!address_id) {
          if (idOfAddress) {
            Object.assign(task, { address_id: idOfAddress });
          } else {
            if (!address_detail.user_id || address_detail.user_id !== user.id) {
              Object.assign(address_detail, { user_id: user.id });
            }
            const { address } = yield call(apiIncludeAddress, address_detail);
            Object.assign(task, { address_id: address.id });
          }
        }

        if (!task.user_id) {
          Object.assign(task, { user_id: user.id });
        }

        yield call(apiSendTask, task);

        yield localStorage.removeItem('hasTaskInProgress');
        yield localStorage.setItem('hasTaskFinished', true);
        if (!isPartner) {
          if (!user.origin_id && task.userOrigin) {
            const { userOrigin } = task;

            console.log('>>> update user origin');

            const fields = {
              origin_comment: userOrigin.name,
              origin_id: userOrigin.id || 6,
            };

            const data = yield call(updateUser, fields, user.id);
            if (data.success) {
              const newUser = { user: { origin_id: userOrigin.id || 6 } };
              yield put({ type: SET_TRIIDER_USER_ORIGIN, data: newUser });
            }
          }
        }

        /* ** MOVIDO PARA TaskSuccess PARA TESTAR O NOVO TRACKING ** */
        // yield localStorage.removeItem('task');
        /* ** MOVIDO PARA TaskSuccess PARA TESTAR O NOVO TRACKING ** */
        yield localStorage.removeItem('isPartner');

        yield all([
          put(FormCreators.finishFormSuccess()),
          put(UiCreators.growl('Seu pedido foi enviado!', growlTypes.GROWL_SUCCESS)),
          put(UiCreators.hideLoader('SERVICE_FORM')),
        ]);

        if (!isRegister) window.location.href = `${process.env.SITE_PATH}/u/pedido-realizado`;
      } catch (error) {
        console.log('processTask 2 >> ', error);
        yield all([
          put(
            UiCreators.growl('Ocorreu um erro ao realizar o pedido', growlTypes.GROWL_ERROR, {
              error,
              method: `${fileName}/processTask`,
            }),
          ),
          put(UiCreators.hideLoader('SERVICE_FORM')),
        ]);
      }
    } else {
      yield put(UiCreators.hideLoader('SERVICE_FORM'));
    }
  } catch (error) {
    console.log('processTask 1 >> ', error);
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/processTask`,
        }),
      ),
      put(UiCreators.hideLoader('SERVICE_FORM')),
    ]);
  }
}

export function* finishForm({ payload: task }) {
  try {
    yield put(UiCreators.showLoader('SERVICE_FORM'));

    yield localStorage.setItem('task', JSON.stringify(task));

    yield localStorage.setItem('hasTaskInProgress', true);
    yield localStorage.setItem('hasTaskExternal', false);

    const state = yield select();

    if (isAuth(state)) {
      const triiderUser = getTriiderUser(state);
      yield call(processTask, triiderUser);
    } else {
      window.location.href = `${process.env.SITE_PATH}/u/cadastro`;
    }
  } catch (error) {
    console.log(error);
    console.log('finishForm >> ', error);
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/finishForm`,
        }),
      ),
      put(UiCreators.hideLoader('SERVICE_FORM')),
    ]);
  }
}

export function* saveLead({ payload }) {
  try {
    yield localStorage.removeItem('task');
    yield localStorage.setItem('hasTaskInProgress', false);
    yield localStorage.setItem('hasTaskExternal', false);

    yield call(apiSaveLead, payload);

    yield put(UiCreators.growl('Pronto! Assim que o Triider chegar a gente te avisa!', growlTypes.GROWL_SUCCESS));
  } catch (error) {
    console.log('saveLead >> ', error);
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/saveLead`,
        }),
      ),
    ]);
  }
}

const matches = (obj, source) => Object.keys(source).every(key => obj.hasOwnProperty(key) && obj[key] === source[key]);
