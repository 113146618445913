import axios from 'axios';
import _padStart from 'lodash/padStart';
import Iugu from 'Iugu'; // eslint-disable-line

export const getIuguCreditCard = (card) => {
  const validDate = card.validThru.split('/');
  const cardNumber = card.display_number.replace(/ /g, '');

  const nameSplited = card.holder_name.split(' ');
  let firstName = '';
  let lastName = '';

  for (let i = 0, l = nameSplited.length; i < l; i += 1) {
    if (i === 0) {
      firstName = nameSplited[i];
    } else {
      lastName += (lastName.length > 0 ? ' ' : '') + nameSplited[i];
    }
  }

  return Iugu.CreditCard(cardNumber, validDate[0], validDate[1], firstName, lastName, card.cvv);
};

// REFACTORING: DONE
export const saveCreditCard = (session, card) => new Promise((resolve, reject) => {
  const url = `${process.env.API_URL}/users/${session.user.id}/cards`;
  const options = { headers: { 'api-token': session.token } };

  const splittedDate = card.validThru.split('/');
  const data = {
    ...card,
    month: splittedDate[0],
    year: `${new Date()
      .getFullYear()
      .toString()
      .substr(0, 2)}${splittedDate[1]}`,
  };

  axios
    .post(url, data, options)
    .then((response) => {
      if (response.data.success) {
        const savedCard = response.data.card;
        savedCard.validThru = `${_padStart(savedCard.month, 2, '0')}/${savedCard.year}`;
        resolve(savedCard);
      }
      reject(response.data);
    })
    .catch(error => reject(
      error.response.data.error
            || 'Ocorreu um erro ao salvar seu cartão. Verifique os dados do cartão.',
    ));
});
export const deleteCreditCard = (session, cardId) => new Promise((resolve, reject) => {
  const url = `${process.env.API_URL}/users/${session.user.id}/cards/${cardId}`;
  const options = { headers: { 'api-token': session.token } };

  axios
    .delete(url, options)
    .then(response => resolve(response))
    .catch(error => reject(error.response.data.error || 'Ocorreu um erro ao deletar o cartão.'));
});

// REFACTORING: DONE
export const getCardById = (user, cardId) => new Promise((resolve, reject) => {
  const url = `${
    process.env.API_URL
  }/user-customer/customer-card?deleted=false&id=${cardId}`;
  const options = { headers: { 'api-token': user.token } };

  axios
    .get(url, options)
    .then((response) => {
      if (response.data.success) resolve(response.data.customerCard);
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

export const setCardCpf = (triiderUser, card) => new Promise((resolve, reject) => {
  const url = `${process.env.API_URL}/users/${triiderUser.user.id}/cards/${card.id}/cpf`;
  const options = { headers: { 'api-token': triiderUser.token } };

  axios
    .put(url, { cpf: card.newCpf }, options)
    .then((response) => {
      if (response.data.success) resolve();
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const getBusinessTask = (user, task) => new Promise((resolve, reject) => {
  const url = `${process.env.API_URL}/businessTask/${task.selected_quote_user_id}/${task.id}`;
  const options = { headers: { 'api-token': user.token } };

  axios
    .get(url, options)
    .then((response) => {
      if (response.data.success) resolve(response.data.businessTask);
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const setBusinessTaskPaid = (user, businessTask) => new Promise((resolve, reject) => {
  const url = `${process.env.API_URL}/businessTask/${businessTask.id}`;
  const options = { headers: { 'api-token': user.token } };

  const bt = businessTask;

  bt.is_paid = true;
  bt.paid_date = new Date();

  axios
    .put(url, { businessTask: bt }, options)
    .then((response) => {
      if (response.data.success) resolve();
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const setTaskPaid = (user, task) => new Promise((resolve, reject) => {
  const url = `${process.env.API_URL}/tasks/${task.id}/payments`;
  const options = { headers: { 'api-token': user.token } };

  axios
    .post(url, { }, options)
    .then((response) => {
      if (response.data.success) {
        resolve();
      }
      reject(response.data.error);
    })
    .catch(error => reject(error));
});

// REFACTORING: DONE
export const notifyTaskPayment = (user, task) => new Promise((resolve, reject) => {
  const url = `${process.env.API_URL}/business-task/notify-payment/${task.id}`;
  const options = { headers: { 'api-token': user.token } };

  axios
    .post(url, {}, options)
    .then((response) => {
      if (response.data.success) resolve();
      reject(response.data.error);
    })
    .catch(error => reject(error));
});
