const INITIAL_STATE = {
  banners: {},
};

/**
 * Types
 */
export const Types = {
  GET_BANNERS_REQUEST: 'settings/GET_BANNERS_REQUEST',
  GET_BANNERS_SUCCESS: 'settings/GET_BANNERS_SUCCESS',
};

/**
 * Reducer
 */
export default function task(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_BANNERS_SUCCESS: {
      return { ...state, banners: action.payload };
    }
    default:
      return state;
  }
}

/**
 * Actions Creators
 */
export const Creators = {
  getBannersRequest: payload => ({
    type: Types.GET_BANNERS_REQUEST,
    payload,
  }),
  getBannersSuccess: payload => ({
    type: Types.GET_BANNERS_SUCCESS,
    payload,
  }),
};
