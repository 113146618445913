import { getFingerprint } from 'utils/fingerprint';
import { initializeGA, gaSendEvent } from 'helpers/analytics';
import api from './index';

export const createUser = async ({ user }) => {
  const url = `${process.env.API_URL}/user`;

  const options = {};

  const { mgm_code } = JSON.parse(localStorage.getItem('userInvitation')) || {};
  if (mgm_code) {
    const df = await getFingerprint();
    Object.assign(options, { headers: { df } });
    Object.assign(user, { mgm_code });
  }

  const partnerSource = JSON.parse(localStorage.getItem('partnerSource'));
  const source = JSON.parse(localStorage.getItem('source'));

  try {
    const { data } = await api.post(
      url,
      {
        user: {
          ...user,
          source: partnerSource || source || 'web_client',
        },
      },
      options,
    );

    localStorage.removeItem('userSource');
    if (JSON.parse(localStorage.getItem('userInvitation'))) {
      initializeGA();
      gaSendEvent('cadastro_indicação', 'novo');
      localStorage.removeItem('userInvitation');
    }
    return data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (password, newPassword) => {
  const url = '/change-password';

  try {
    const { data } = await api.patch(url, { password, newPassword });

    return data;
  } catch (err) {
    throw err.response.data;
  }
};

export const updateUserPhoto = async (photo) => {
  const url = '/image/profile';

  const formData = new FormData();
  formData.append('image', photo, photo.name);

  try {
    const { data } = await api.post(url, formData);

    return data;
  } catch (err) {
    throw err;
  }
};

export const authPartner = async (partner, token) => {
  try {
    const query = token ? `?token=${token}` : '';
    const { data } = await api.get(`/partner/${partner}/landing${query}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const apiValidateEmail = async (email, token) => {
  try {
    const { data } = await api.post('/validate-email', { email, token });
    return data;
  } catch (error) {
    throw error;
  }
};
