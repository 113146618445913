import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import {
  take, call, put, select, all, race,
} from 'redux-saga/effects';

import {
  apiGetChatsList,
  apiSendMessage,
  apiReadMessages,
  apiUploadFile,
  apiRemoveMessage,
} from 'services/api/chat';

import { getErrorMessage } from 'utils/filters';

import { getTriiderUser } from 'reducers/userArea';
import { Creators as UiCreators, growlTypes } from '../ducks/ui';
import { Creators as ChatCreators, Types as ChatTypes } from '../ducks/chat';
// import { Creators as TaskCreators } from '../ducks/task';

const fileName = 'sagas/chat';

const events = {
  CONNECT: 'connect',
  CONNECT_ERROR: 'connect_error',
  SUBSCRIBE: 'subscribe',
  DISCONNECT: 'disconnect',
  NEW_MESSAGE: 'new-message',
  MESSAGE_READ: 'message-read',
  START_TYPING: 'user-start-typing',
  STOP_TYPING: 'user-stopped-typing',
  MESSAGE_DELETED: 'message-deleted',
};

function connect() {
  const options = {
    // rememberUpgrade: true,
    transports: ['websocket', 'polling', 'flashsocket'],
    secure: true,
    // rejectUnauthorized: false,
  };
  const socket = io.connect(process.env.CHAT_URL, options);
  return new Promise((resolve, reject) => {
    socket.on(events.CONNECT, () => {
      resolve(socket);
    });
    // eslint-disable-next-line no-unused-vars
    socket.on(events.CONNECT_ERROR, (err) => {
      reject(Error('Erro de conexão com o chat'));
    });
    // on reconnection, reset the transports option, as the Websocket
    // connection may have failed (caused by proxy, firewall, browser, ...)
    socket.on('reconnect_attempt', () => {
      socket.io.opts.transports = ['websocket', 'polling', 'flashsocket'];
    });
  });
}

function subscribe(socket) {
  return eventChannel((emit) => {
    socket.on(events.NEW_MESSAGE, (message) => {
      const {
        user: { id: user_id, name: user_name },
        id,
        message: text,
        room,
      } = message;
      const data = {
        ...message,
        id: id || `cliold${Math.floor(Math.random() * 1000)}`,
        text,
        user_id,
        user_name,
        chat_id: JSON.parse(room),
        is_read: false,
      };
      emit(ChatCreators.messageReceived(data));
    });
    socket.on(events.MESSAGE_DELETED, (payload) => {
      const { room, message_id } = payload;
      const chat_id = JSON.parse(room);

      emit(ChatCreators.removeMessageSuccess(chat_id, message_id));
    });
    socket.on(events.MESSAGE_READ, (payload) => {
      const { message: text, room } = payload;
      const chat_id = JSON.parse(room);
      emit(ChatCreators.messageRead({ messages: [{ ...payload, text, chat_id }], chat_id }));
    });
    socket.on(events.START_TYPING, (payload) => {
      emit(ChatCreators.userStartTyping(payload));
    });
    socket.on(events.STOP_TYPING, (payload) => {
      emit(ChatCreators.userStopTyping(payload));
    });
    return () => {
      socket.close();
    };
  });
}

function* watch(channel) {
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

export function* sendMessage({ payload }) {
  try {
    const socket = yield select(state => state.chat.socket);
    const triiderUser = yield select(getTriiderUser);

    if (socket) {
      socket.emit(events.SUBSCRIBE, {
        user: triiderUser.user,
        room: payload[0]?.chat_id,
        from: 'task',
      });
    }

    for (let i = 0; i < payload.length; i += 1) {
      const message = {
        user_id: payload[i]?.user?._id,
        user_name: payload[i]?.user?.name,
        message: payload[i]?.text,
        // status: no-send
        ...payload[i],
      };

      const { id } = yield call(apiSendMessage, payload[i].chat_id, message);
      yield put(ChatCreators.includeMessage({
        ...message,
        time: new Date(),
        id,
      }));

      if (socket) {
        socket.emit(events.NEW_MESSAGE, {
          ...message,
          id,
        });
      }
    }
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/send`,
        }),
      ),
    ]);
  }
}

export function* readMessages({ payload }) {
  const { messages, chat_id } = payload;
  const socket = yield select(state => state.chat.socket);
  const triiderUser = yield select(getTriiderUser);

  yield put(ChatCreators.messageRead({ messages, chat_id }));
  const arrMessagesId = [];

  if (socket) {
    socket.emit(events.SUBSCRIBE, {
      user: triiderUser.user,
      room: chat_id,
      from: 'task',
    });

    messages.forEach((msg) => {
      arrMessagesId.push(msg.id);
      socket.emit(events.MESSAGE_READ, {
        ...msg,
        message: msg.text,
        save: !!msg.id,
      });
    });
  }

  yield call(apiReadMessages, arrMessagesId);
}

export function* removeMessages({ payload }) {
  try {
    const { messages } = payload;
    const socket = yield select(state => state.chat.socket);
    const triiderUser = yield select(getTriiderUser);

    if (socket) {
      socket.emit(events.SUBSCRIBE, {
        user: triiderUser.user,
        room: messages[0].chat_id,
        from: 'task',
      });
    }
    for (let i = 0; i < messages.length; i += 1) {
      const { chat_id, _id: message_id } = messages[i];
      const { success } = yield call(apiRemoveMessage, chat_id, { message_id });

      if (success) {
        if (socket) {
          socket.emit(events.MESSAGE_DELETED, {
            room: messages.chat_id,
            message_id,
          });
        }
        yield all([
          put(ChatCreators.removeMessageSuccess({ chat_id, message_id })),
        ]);
      }
    }
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/remove`,
        }),
      ),
    ]);
  }
}

export function* startTyping({ payload: chat_id }) {
  yield put(ChatCreators.selfStartTyping());
  const { user } = yield select(getTriiderUser);
  const socket = yield select(state => state.chat.socket);

  if (socket) {
    socket.emit(events.SUBSCRIBE, {
      user,
      room: chat_id,
      from: 'task',
    });

    socket.emit(events.START_TYPING, {
      chat_id,
      user_name: user.name,
      user_id: user.id,
    });
  }
}

export function* stopTyping({ payload: chat_id }) {
  yield put(ChatCreators.selfStopTyping());
  const { user } = yield select(getTriiderUser);
  const socket = yield select(state => state.chat.socket);

  if (socket) {
    socket.emit(events.SUBSCRIBE, {
      user,
      room: chat_id,
      from: 'task',
    });

    socket.emit(events.STOP_TYPING, {
      chat_id,
      user_name: user.name,
      user_id: user.id,
    });
  }
}

export function* disconect({ payload: chat_id }) {
  const socket = yield select(state => state.chat.socket);
  if (socket && chat_id) {
    socket.disconnect();
  }
}

export function* initChat({ payload: { task_id } }) {
  try {
    yield put(UiCreators.showLoader('CHAT'));
    const triiderUser = yield select(getTriiderUser);

    const chats = yield call(apiGetChatsList, task_id);

    const socketDuck = yield select(state => state.chat.socket);

    let socket;
    if (!socketDuck?.connect) {
      socket = yield call(connect);
      yield put(ChatCreators.setSocket(socket));
    } else {
      socket = socketDuck;
    }

    // connect in all chats
    chats.forEach((chat) => {
      socket.emit(events.SUBSCRIBE, {
        user: triiderUser.user,
        room: chat.id,
        from: 'task',
      });
    });

    yield all([
      put(UiCreators.hideLoader('CHAT')),
      put(ChatCreators.initChatSuccess(chats)),
    ]);

    const channel = yield call(subscribe, socket);

    const { cancel } = yield race({
      task: call(watch, channel),
      cancel: take(ChatTypes.REMOVE_CHATS),
    });

    if (cancel) {
      channel.close();
    }
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(UiCreators.hideLoader('CHAT')),
      UiCreators.growl(message, growlTypes.GROWL_ERROR, {
        error,
        method: `${fileName}/send`,
      }),
    ]);
  }
}

export function* uploadImage({ payload }) {
  try {
    const { messages, files } = payload;
    const triiderUser = yield select(getTriiderUser);

    const socket = yield select(state => state.chat.socket);

    socket.emit(events.SUBSCRIBE, {
      user: triiderUser.user,
      room: messages[0].chat_id,
      from: 'task',
    });

    for (let index = 0; index < messages.length && index < files.length; index += 1) {
      yield put(ChatCreators.includeMessage({
        ...messages[index],
        loadimage: true,
      }));

      const { message: messageReturn } = yield call(apiUploadFile, files[index], messages[index]);
      const messageResp = {
        ...messages[index],
        time: messages[index].createdAt,
        id: messageReturn.id,
        image: messageReturn.image,
        message: messages[index].text,
      };

      yield all([
        put(ChatCreators.switchMessage(messageResp, messages[index].id)),
        put(ChatCreators.clearFiles()),
      ]);

      if (socket) {
        socket.emit(events.NEW_MESSAGE, messageResp);
      }
    }
  } catch (error) {
    const message = getErrorMessage(error);
    yield all([
      put(
        UiCreators.growl(message, growlTypes.GROWL_ERROR, {
          error,
          method: `${fileName}/uploadImage`,
        }),
      ),
    ]);
  }
}
