import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'style';

const sizes = {
  default: 'inherit',
  small: '14px',
  medium: '16px',
  larger: '22px',
};

const RouterStyled = styled(Link)`
  &:hover {
    color: ${colors.primary} !important;
  }
  text-transform: ${props => (props.toupper ? 'uppercase' : 'none')};
  color: ${props => (props.primary || props.active ? colors.primary : colors.greyDark)} !important;
  cursor: pointer;
  font-size: ${props => sizes[props.size] || 'default'};
  font-weight: ${props => (props.semibold && '500') || 'normal'};
`;

const Anchor = RouterStyled.withComponent('a');

const StyledLink = ({
  children,
  isRouter,
  href,
  active = false,
  currentPath,
  toUpper,
  primary,
  semibold,
  ...props
}) => {
  const A = isRouter ? RouterStyled : Anchor;
  const to = href ? href.replace(`${process.env.BASE}`, '') : null;
  const isActive = currentPath === href || currentPath === `${href}/` || active;

  return (
    <A
      href={href}
      to={to}
      active={isActive ? 1 : 0}
      toupper={toUpper ? 1 : 0}
      primary={primary ? 1 : 0}
      semibold={semibold ? 1 : 0}
      {...props}
    >
      {children}
    </A>
  );
};

StyledLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isRouter: PropTypes.bool,
  active: PropTypes.bool,
  toUpper: PropTypes.bool,
  primary: PropTypes.bool,
  semibold: PropTypes.bool,
  href: PropTypes.string,
  currentPath: PropTypes.string,
};

export default StyledLink;
