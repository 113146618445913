import { call, put, select } from 'redux-saga/effects';

import { apiGetRatingLabels, apiPatchRating } from 'services/api/rating';

import { Creators as RatingCreators } from '../ducks/rating';

import { getTriiderUser } from 'reducers/userArea';

export function* getLabels({ payload }) {
      
  try {
    
    const response = yield call(apiGetRatingLabels, payload);
    
    const ratingLabel = {
      rating: payload,
      labels: response.labels
    }

    yield put(RatingCreators.getRatingLabelSuccess(ratingLabel));
  
      
  } catch (error) {
    console.log(error);   
  }
}

export function* setRating( { payload: { valuesForm, task_id } }) {
  try {
    
    const triiderUser = yield select(getTriiderUser);

    yield call(apiPatchRating, triiderUser.token, valuesForm, task_id);

    yield put(RatingCreators.setRatingSuccess(valuesForm));

    window.location.href = `${process.env.SITE_PATH}${process.env.BASE}/avaliar/sucesso/${valuesForm.rating}`;

  } catch (error) {
    console.log(error); 
  }
}
